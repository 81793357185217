<template>
  <b-modal
      :hide-footer="true"
      size="xl"
      ref="modal"
      :title="!(templateId)?'Add New Template':'Update Template'"
      no-close-on-backdrop
      @hidden="resetModal"
  >
    <b-container class="container-fluid">
      <b-row>
        <b-col cols="3">
          <b-form-group
              label="Type of text message"
              description="Select the type of text message template that you’re creating."
          >
            <b-form-select v-model="templateType" :options="templateTypeOptions"
                           @change="changeDefaultPath()"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-row>
            <b-col cols="4">
              <b-form-group
                  label="Type of text flow"
                  description="Select the text flow that you would like to add this template to"
              >
                <b-form-select v-model="templatePurpose" :options="templatePurposeOptions"
                               @change="generateTemplatePath()"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" v-if="!(templateId)">
              <b-form-group
                  label="Version of template"
                  description="Choose which version this text message falls into: Mon-Wednesday = 1, Thurs-Friday = 2, Sat-Sunday = 3"
              >
                <b-form-select v-model="templateVersion" :options="templateVersionOptions"
                               @change="generateTemplatePath()"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" v-if="!(templateId)">
              <b-form-group
                  label="Language"
              >
                <b-form-select v-model="templateLang" :options="templateLangOptions"
                               @change="generateTemplatePath()"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <span v-if="!(templateId)">
        <b-row v-for="field in pathModel[templateType].split('/')" :key="field">
          <b-col cols="3" v-if="(!(field ==='lang_type') && !(field==='day_of_week'))">
            <b-form-group
                :label="tempOptionsTitles[field]"
            >
              <b-form-select v-model="pathChoices[field]"
                             :options="templateFields[templateType][field]"
                             @change="getComboValue($event,field)"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        </span>
      <b-row>
        <b-col>
          <b-form-group
              label="Valid Template Tags"
              description="Don't use any other tags inside templates except these"
          >
            <b-form-tags
                v-model="validTags[templateType]"
                tag-variant="primary"
                tag-pills
                size="lg"
                separator=" "
                disabled
            ></b-form-tags>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-row>
            <b-col>
              <label>Opt-out message</label>
              <textarea v-model="optout" class="input-box"
                        placeholder="Insert the language here to follow our Opt-out process" rows="5"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>Message Template</label>
              <textarea v-model="message" class="input-box"
                        placeholder="Insert the template message/language that you want to update here" rows="10"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <b-button-group vertical>
            <b-button @click="reset" class="btn btn-dark">
              Clear
            </b-button>
            <b-button v-if="!(templateId)" class="btn btn-success" @click="handleSubmit()">
              Add
            </b-button>
            <b-button v-if="(templateId)" class="btn btn-warning" @click="handleSubmit()">
              Update
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>

  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "smstemplate",
  props: ["entry"],
  watch: {

    /** realtime props update */
    $props: {

      async handler(newVal, oldVal) {
        this.templateType = newVal.entry.templateType;
        this.templateId = newVal.entry.templateId;
        this.message = newVal.entry.template;
        this.templateLang = newVal.entry.templateLang;
        this.templateVersion = newVal.entry.templateVersion;
        this.optout = newVal.entry.optout;
        this.templatePurpose = newVal.entry.templatePurpose;

        const pathDetails = newVal.entry.path.split('/')
        const pathChoices = {};
        for (let i = 0; i < pathDetails.length; i++) {
          pathChoices[this.pathModel[this.templateType].split('/')[i]] = pathDetails[i]
        }
        this.pathChoices = pathChoices;
      },
      deep: true,
      immediate: true
    }
  },

  methods: {

    changeDefaultPath() {
      this.defaultPathFormat = this.pathModel[this.templateType];
      this.templateLang = null;
      this.templatePurpose = null;
      this.templateVersion = null;
    },

    generateTemplatePath() {

      this.templateMultiPath = [];

      if (this.templateLang == 1) {

        const CAEN = this.defaultPathFormat.replace('lang_type', 'CA-EN')
        const ENUS = this.defaultPathFormat.replace('lang_type', 'EN-US')

        switch (this.templateVersion) {

          case "1":
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'mon'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'mon'))
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'tue'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'tue'))
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'wed'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'wed'))
            break;

          case "2":
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'thu'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'thu'))
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'fri'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'fri'))
            break;

          case "3":
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'sat'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'sat'))
            this.templateMultiPath.push(CAEN.replace('day_of_week', 'sun'))
            this.templateMultiPath.push(ENUS.replace('day_of_week', 'sun'))
            break;
        }

      } else {

        const CAFR = this.defaultPathFormat.replace('lang_type', 'CA-FR')

        switch (this.templateVersion) {

          case "1":
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'mon'))
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'tue'))
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'wed'))
            break;

          case "2":
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'thu'))
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'fri'))
            break;

          case "3":
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'sat'))
            this.templateMultiPath.push(CAFR.replace('day_of_week', 'sun'))
            break;
        }
      }
    },

    validateTemplate() {

      let flag = true;
      let message = this.message.concat('\n\n', this.optout);
      for (const tag of this.validTags[this.templateType]) {
        message = message.replace(tag, 'XX')
      }

      const regex = /[{{[^\]}}]/g;
      if (message.match(regex)) {
        flag = false;
      }

      return flag;
    },

    getComboValue(event, name) {

      if (!this.defaultPathFormat) {
        this.defaultPathFormat = this.pathModel[this.templateType];
      }
      this.defaultPathFormat = this.defaultPathFormat.replace(name, event);
      this.generateTemplatePath();
    },

    reset() {
      this.message = "";
      this.optout = "";
    },

    async handleSubmit() {

      let response;
      if (this.validateTemplate()) {
        if (!this.templateId) {
          response = await axios.post(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/template/save`, {
            path: this.defaultPathFormat,
            optout: this.optout,
            templatePurpose: this.templatePurpose,
            template: this.message,
            templateType: this.templateType,
            isBulkSave: true,
            multiplePath: this.templateMultiPath
          });
        } else {
          response = await
              axios.put(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/template/${this.templateId}/update`, {
                template: this.message,
                templatePurpose: this.templatePurpose,
                optout: this.optout,
                templateType: this.templateType,
                path: ""
              });
        }

        this.$refs.modal.hide();
        this.$emit("refreshPage");
        this.displayMessage("success", response.data['success']);
      } else {
        this.displayMessage("warning", "Invalid Template! Please enter valid tags.");
      }
    },

    resetModal() {
      this.pathChoices = {}
    },

    close(success, error) {
      this.$refs.modal.hide();
      this.templateId = null
      this.$emit('close', success, error)
    },

    async show() {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.$refs.modal.show();
      // this.$refs.mainInput.setup();
    },

    async loadModelSetting() {

      const response = await axios.get(
          `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/templatetype/all`
      );

      const pathTags = {};
      const templateTags = {"": []};

      for (const templateType of response.data) {
        this.templateTypeOptions.push({value: templateType.type, text: templateType.typeName});
        pathTags[templateType.type] = templateType.pathTags;
        templateTags[templateType.type] = (templateType.templateTags) ? templateType.templateTags : [];
      }


      this.validTags = templateTags;
      this.templateFields = pathTags;
      this.templatePurpose = null;
      this.templateVersion = null;
      this.templateLang = null;
    },

    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    }
  },
  async created() {
    await this.loadModelSetting();
  },

  data() {
    return {
      tempOptionsTitles: {
        "location_type": "Type of delivery", "lang_type": "Language", "temp_type": "Temperature", "eta_type":
            "ETA Tag Included in the message template?", "days_of_week": "Days Of Week", "driver_type": "Driver Tag",
        "consent": "Have they received our text communication about this order yet?"
      },
      templateType: "ETA",
      templateTypeOptions: [],
      defaultPathFormat: 'lang_type/consent/location_type/temp_type/eta_type/day_of_week',
      templatePurposeOptions: [{value: null, text: 'Choose...'}, {value: 'Regular', text: 'Regular'},
        {value: 'VDay', text: 'VDay'},
        {value: 'MDay', text: 'MDay'},
        {value: 'Holiday', text: 'Holiday'}
      ],
      templateVersionOptions: [{value: null, text: 'Choose...'}, {value: '1', text: 'Monday-Wednesday'},
        {value: '2', text: 'Thursday-Friday'},
        {value: '3', text: 'Saturday-Sunday'},
      ],
      templateVersion: "",
      templateLangOptions: [{value: null, text: 'Choose...'}, {value: '1', text: 'English'},
        {value: '2', text: 'French'},
      ],
      templateLang: null,
      templateId: null,
      templateMultiPath: [],
      templateFields: {},
      templatePurpose: null,
      optout: "",
      message: "",
      validTags: [],
      pathChoices: {"lang_type": []},
      pathModel: {
        "DayBefore": "lang_type/driver_type/location_type/temp_type/day_of_week",
        "OTW": "lang_type/location_type/eta_type/day_of_week",
        "ETA": "lang_type/consent/location_type/temp_type/eta_type/day_of_week",
        "FUT3Days": "lang_type/day_of_week",
        "FUT7Days": "lang_type/day_of_week",
        "CareTips": "lang_type/day_of_week",
        "DelAlert": "lang_type/temp_type/day_of_week"
      }
    };
  }
};
</script>
