<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <b-col lg="6" class="my-1">
      <b-button class="actionbutton" @click="addItem">New Rule</b-button>
      <b-form-group
        label="Filter"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-icon icon="search" class="icon"></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-table
      hover
      :items="items"
      :fields="fields"
      :filter-included-fields="filterOn"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      small
    >
      <template #cell(actions)="row">
        <div class="btnContainer">
          <b-button size="sm" @click="editItem(row.item)" class="actionbutton">
            Update
          </b-button>

          <b-button
            size="sm"
            @click="enableItem(row.item)"
            v-if="row.item.status == 'DISABLED'"
            class="actionbutton"
          >
            Enable
          </b-button>
          <b-button
            size="sm"
            @click="disableItem(row.item)"
            v-if="row.item.status == 'ENABLED'"
            class="actionbutton mr-1"
          >
            Disable
          </b-button>
        </div>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "ruleName",
          sortable: true,
          label: "Rule Name",
        },
        {
          key: "status",
          sortable: true,
          label: "Status",
        },
        {
          key: "description",
          sortable: true,
          label: "Description",
        },
        {
          key: "schedule",
          sortable: true,
          label: "Event schedule",
        },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc",
    };
  },
  async created() {
    this.load();
  },
  methods: {
    async load() {
      this.processing = true;
      const response = await this.axios.get(
        process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/rules",
        {
          headers: {
            Authorization: "Bearer " + this.$store.getters.idToken,
          }
        }
      );
      for (const data of response.data) {
        if (data.ruleName.toUpperCase().startsWith("EXP_")) {
          this.items.push(data);
        }
      }
      this.processing = false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    editItem(item) {
      this.$router.push({ name: "RulesConfig", params: { editedItem: item } });
    },

    addItem() {
      this.$router.push({
        name: "RulesConfig",
        params: {}
      });
    },

    enableItem(item) {
      if (confirm("Are you sure you want to enable this item?")) {
        this.processing = true;
        const params = {
          type: "enable",
          ruleName: item.ruleName,
        };
        this.axios
          .put(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/rules",
            params,
            {
              headers: {
                Authorization:
                  "Bearer " +
                  this.$store.getters.idToken
              }
            }
          )
          .then((response) => {
            this.items = [];
            this.load();
            this.processing = false;
          });
      }
    },

    disableItem(item) {
      if (confirm("Are you sure you want to disable this item?")) {
        this.processing = true;
        const params = {
          type: "disable",
          ruleName: item.ruleName,
        };
        this.axios
          .put(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/rules",
            params,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.idToken,
              }
            }
          )
          .then((response) => {
            this.items = [];
            this.load();
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/order-rules-grid.scss";
</style>
