<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <b-col lg="6" class="my-1">
      <b-form-group
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="phone"
            type="text"
            placeholder="Phone"
            required
          ></b-form-input>
          <b-form-select v-model="type" :options="typeOptions"></b-form-select>
          <b-button
            type="button"
            @click="onSubmit"
            variant="primary"
            class="action-btn form-submit-button"
            >Search</b-button
          >
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-table hover :items="items" :fields="fields" show-empty small> </b-table>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  data() {
    return {
      processing: false,
      processingMessage: "Please wait...",
      fields: [
        {
          key: "body",
          sortable: true,
          label: "SMS Body",
        },
        {
          key: "from",
          sortable: true,
          label: "from",
        },
        {
          key: "to",
          sortable: true,
          label: "To",
        },
        {
          key: "dateCreated",
          sortable: true,
          label: "Date",
        },
        {
          key: "status",
          sortable: true,
          label: "Status",
        },
      ],
      items: [],
      phone: null,
      type: "to",
      typeOptions: [
        { value: 'to', text: 'To' },
        { value: 'from', text: 'From' },
      ],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc",
    };
  },
  methods: {
    async onSubmit() {
      this.query();
    },
    async query() {
      this.processing = true;
      let tempPhone = this.phone.replace(/\D/g, "");
      if (tempPhone.length === 10) {
        tempPhone = "1" + tempPhone;
      }
      const response = await this.axios.get(
        process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/sms-history?phone=" + tempPhone + "&type=" + this.type,
        {
          headers: {
            Authorization: "Bearer " + this.$store.getters.idToken,
          },
        }
      );
      const data = response.data;
      data.forEach((m) => {
        let date = m.dateCreated;
        date = dayjs(date).tz("America/Winnipeg").format();
        m.dateCreated = date;
      });
      this.items = data;
      this.processing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/order-pulls-config-grid.scss";
</style>
