<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
              v-model="filterOn"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="templateType">Template Type</b-form-checkbox>
            <b-form-checkbox value="template"
            >Template
            </b-form-checkbox
            >
            <b-form-checkbox value="path">Path</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light"
          >Leave all unchecked to filter on all data
          </b-badge
          >
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col offset-lg="10" offset-md="9" md="3" lg="2" sm="12">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="7"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-checkbox
              v-model="tableAllRowSelect"
              value="select_all"
              unchecked-value="deselect_all"
              @change="chkBoxEvent"
          >
            Select All
          </b-form-checkbox>
          <b-table
              hover
              responsive
              :sticky-header="'58vh'"
              small
              id="delivery-notification-table"
              :items="items"
              :fields="fields"
              :filter-included-fields="filterOn"
              :filter="filter"
              @filtered="onFiltered"
              head-variant="dark"
              :current-page="currentPage"
              :per-page="perPage"
              :select-mode="selectMode"
              selectable
              @row-selected="onRowSelected"
              ref="deliveryNotificationTable"
          >
            <template #cell(status)="row">
              <p v-if="row.item.status === 1" style="color: #79D459;">
                Active
              </p>
              <p v-else style="color: #F07272;">
                Disabled
              </p>
            </template>
            <template #cell(action)="row">
              <b-button-group >


                <b-button class="btn btn-warning" @click="updateTemplate(row.item)"> <edit-icon
                    size="1x"

                ></edit-icon></b-button>
                <b-button class="btn btn-danger" @click="deleteTemplate(row.item)">
                  <trash-icon
                      size="1x"
                  ></trash-icon>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            offset-lg="9"
            offset-md="8"
            offset-sm="4"
            md="4"
            lg="3"
            sm="8"
            class="text-right"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import {EditIcon, TrashIcon} from "vue-feather-icons";

export default {

  props: ["items", "processing", "totalRows"],
  name: "delivery-notification-grid",
  components: {
    EditIcon,
    TrashIcon
  },
  data() {
    return {
      filteredItems:[],
      tableAllRowSelect: "deselect_all",
      processingMessage: "Please wait...",
      selectMode: 'multi',
      selected: [],
      filterSelectedRow: [],
      fields: [
        {
          key: "templateType",
          sortable: true,
          label: "Type",
          thClass: "text-center",
          tdClass: "text-left"
        },

        {
          key: "template",
          sortable: true,
          label: "Template",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "optout",
          sortable: true,
          label: "OptOut",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "path",
          sortable: true,
          label: "Path",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          thClass: "position-relative text-center",
          tdClass: "text-center"
        },
        {
          key: "templatePurpose",
          sortable: true,
          label: "Purpose",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "action",
          sortable: false,
          label: "Actions",
          thClass: "position-relative text-center",
          tdClass: "text-center"
        }
      ],
      filter: null,
      filterOn: [],
      sortDirection: "asc",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, {value: 10000, text: "Show a lot"}]
    };
  },
  methods: {
    chkBoxEvent(){
      if(this.tableAllRowSelect == 'select_all'){
        const selectedRows = (this.filteredItems.length > 0)?this.filteredItems:this.items
        for (const [index, item] of selectedRows.entries()) {
          if (item.status === 0) {
            this.filterSelectedRow.push(item);
            this.$refs.deliveryNotificationTable.selectRow(index)
          }
        }
      }else{
        this.filterSelectedRow = [];
        this.$refs.deliveryNotificationTable.clearSelected()
      }
      this.$emit("enableTemplates", this.filterSelectedRow);
    },
    onRowSelected(items) {
      this.selected = items;
      this.filterSelectedRow = [];

      for (const item of items) {
        if (item.status === 0) {
          this.filterSelectedRow.push(item);
        }
      }
      for (const selectedRow of items) {
        if (selectedRow.status === 1) {
          for (const [index, rowItem] of this.items.entries()) {
            if (selectedRow.templateId === rowItem.templateId) {
              this.$refs.deliveryNotificationTable.unselectRow(index);
            }
          }
        }
      }
      this.$emit("enableTemplates", this.filterSelectedRow);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateTemplate(row) {
      this.$emit("showUpdateModal", row);
    },
    deleteTemplate(row) {
      this.$emit("deleteSMSTemplate", row)
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/tablecommon.scss";
</style>