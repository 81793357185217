<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Add New" subTopic="Template" />
          <CLButton
              format="primary-btn"
              v-on:func="showAddModal"
          >
            <plus-icon
                size="1.5x"
                class="text-white"
            ></plus-icon>
            Add New
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon
                size="1.5x"
                class="text-dark"
            ></refresh-cw-icon>
          </b-button>
          <add-template-popup
              ref="AddTemplateModal"
              @success="refreshGrid"
              @error="errorCreateTemplate"
          />
          <edit-template-popup
            ref="EditTemplateModal"
            :update-array="updateArray"
            @success="refreshGrid"
            @error="errorUpdateTemplate"
          />
        </div>
      </div>
      <div class="row mb-0">
        <alert-template-grid
            :pgCurrentPage="pgCurrentPage"
            :pgTotalRows="pgTotalRows"
            :pgPerPage="pgPerPage"
            :processing="processing"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @deleteTemplate="deleteTemplate"
            @gridPaginationEvent="gridPaginationEvent"
            @gridPaginationPerPageChange="updatePgPerPageChanges"
            @changePaginationPageEvent="changePgCurrentPage"
            @showEditModal="showEditTemplateModal($event)"
        ></alert-template-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {PlusIcon, RefreshCwIcon} from "vue-feather-icons";
import TemplateGrid from "@/components/grids/giftset-alert-template-grid";
import {CLHeader, CLButton} from "callia-ui-box";
import AddTemplateModal from "@/components/giftsetalerttemplate/add-template-popup";
import EditTemplatePopup from "@/components/giftsetalerttemplate/edit-template-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "alert-template-grid": TemplateGrid,
    "add-template-popup": AddTemplateModal,
    "edit-template-popup": EditTemplatePopup,
    CLHeader,
    CLButton
  },
  data() {
    return {
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      rowData: [],
      processing: false,
      columnDefs: [],
      updateArray: []
    };
  },
  async created() {
    this.processing = true;
    await this.getAllTemplatesByPagination(this.pgCurrentPage);
    this.processing = false;
  },
  methods: {
    showEditTemplateModal(editInfo) {
      this.updateArray = editInfo;
      this.$refs.EditTemplateModal.show();
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage;
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(perPage);
      this.getAllTemplatesByPagination(this.pgCurrentPage);
      this.processing = false;
    },
    gridPaginationEvent(currentPage) {
      this.getAllTemplatesByPagination(currentPage);
    },
    async refreshPage() {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(this.pgPerPage);
      await this.getAllTemplatesByPagination(this.pgCurrentPage);
      this.processing = false;
    },
    async deleteTemplate(event) {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete this Template: ${event.name}.`, {
          title: 'Delete Template',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert-template?title=${event.name}`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              },
              data: {
                'name': event.name
              }
            });
            await this.refreshGrid(response.data.message);
          }
        })
      } catch (errorResponse) {
        this.errorDeleteTemplate();
        console.error("Error: ", errorResponse);
      }
    },
    async getAllTemplatesByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert-template?title`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      const result = response.data;
      this.$store.commit("setGiftsetAlertTemplate", result.slice(offset, this.pgPerPage * currentPage));
      this.rowData = this.$store.getters.giftsetAlertTemplate;
      this.pgTotalRows = parseInt(response.data.length);
    },
    showAddModal() {
      this.$refs.AddTemplateModal.show();
    },
    async refreshGrid(message) {
      await this.getAllTemplatesByPagination(this.pgCurrentPage);
      this.displayMessage("success", message);
    },
    errorCreateTemplate() {
      this.displayMessage("warning", "Template saving process failed.");
    },
    errorUpdateTemplate() {
      this.displayMessage("warning", "Template update process failed.");
    },
    errorDeleteTemplate() {
      this.displayMessage("warning", "Template delete process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    }
  }
};
</script>
