<template>
  <b-modal
      id="modal-add-giftsetsalert"
      ref="modal"
      title="Add New Gift Set "
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Title"
          label-for="name"
          invalid-feedback=""
          valid-feedback=""
      >
        <b-form-input
            name="name"
            v-model="name"
            :state="validationName"
            @keyup="keyupValidationName"
            required
        ></b-form-input>
      </b-form-group>

    </form>
  </b-modal>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  name: "add-giftsetsalert-popup",
  data() {
    return {

      name: "",
      shortCode: "",
      validationName: null,
      validationShortCode: null,
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    keyupValidationName() {
      if (this.name !== "" || this.name !== " " || this.name !== "  ") {
        this.validationName = true;
      } else {
        this.validationName = false;
      }
    },


    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();
      if (this.$refs.form.name.checkValidity()) {
        this.validationName = true;
      } else {
        this.validationName = false;
      }
      //validate short_code
      if (this.$refs.form.shortCode.checkValidity()) {
        this.validationShortCode = true;
      } else {
        this.validationShortCode = false;
      }


      return fullFormValidity;
    },
    resetModal() {
      this.name = "";
      this.validationName = null;

    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {

        const giftsetsalertPut = {
          id: this.name,

        };

        const response = await this.axios({
          method: "PUT",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: giftsetsalertPut
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-giftsetsalert");
        });
        this.$emit("success", response.data.message);
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>