<template>
  <div class="container-fluid p-0">
    <b-alert
        :show="notification.showErrorCountDown"
        dismissible
        fade
        :variant="notification.type"
    >
      {{ notificationMessage }}
    </b-alert>
    <h3><strong>Manual</strong> fulfillment</h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner
                  type="grow"
                  variant="info"
                  label="Loading..."
              ></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>

          <div class="row">
            <div class="col-auto">
              <p class="mt-3 mb-0">All fields are required</p>
            </div>
          </div>

          <div class="form-card mt-3 mb-0 form">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <div class="mt-4">
                <p class="report-label mb-2">Province</p>
                <div class="row">
                  <div class="col-sm">
                    <b-form-input
                        list="province-list"
                        id="input-with-list"
                        v-model="form.province"
                        class="input-height"
                    ></b-form-input>
                    <b-form-datalist
                        id="province-list"
                        :options="provinces"
                    ></b-form-datalist>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group">
                  <div class="row mt-1">
                    <div class="col-sm">
                      <label class="mt-3 report-label">Delivery date</label>
                      <b-form-input
                          id="input-1"
                          v-model="form.deliveryDate"
                          type="date"
                          placeholder="Delivery Time"
                          class="input-height"
                          required
                      ></b-form-input>
                    </div>
                    <div class="col-sm">
                      <label class="mt-3 report-label">Time <span>(12:30 PM)</span></label>
                      <b-form-input
                          id="input-1"
                          v-model="form.deliveryTime"
                          type="time"
                          placeholder="Delivery Time"
                          class="input-height"
                          required
                      ></b-form-input>
                    </div>
                    <div class="col-sm">
                      <label class="mt-3 report-label">Driver initials</label>
                      <b-form-input
                          id="input-1"
                          v-model="form.driverInitials"
                          type="text"
                          placeholder="Driver Initials"
                          class="input-height"
                          required
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="mt-0 report-label">Order id (',' for separate multiple orders)</label>
                  <b-form-input
                      id="input-1"
                      v-model="form.orderId"
                      type="text"
                      placeholder="Order Id"
                      class="input-height"
                      required
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <label class="mt-2 report-label">Fulfillment note</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.note"
                      placeholder="Fulfillment Note..."
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                </div>

                <!-- Image Uploader-->
                <div class="form-group">
                  <label class="mt-2 report-label">Upload delivery image</label>
                  <b-form-file v-model="form.file" accept=".jpg, .jpeg, .png" @change="handleFileChange"></b-form-file>
                </div>
                <!-- end of image uploader-->

                <b-button type="submit" variant="primary" v-if="enableButton"
                          class="form-submit-button submit-button adj-btn"
                >Submit
                </b-button>
                <b-button type="button" class="disabled-button adj-btn" disabled v-else>
                  Submit
                </b-button>
                <b-button type="reset" variant="outline-primary" class="reset-button adj-btn">Reset</b-button>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import axios from "axios";

dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  data() {
    return {
      user: this.$store.getters.currentUser,
      initials: "",
      form: {
        file: null,
        province: "",
        deliveryDate: "",
        deliveryTime: "",
        driverInitials: this.initials,
        orderId: "",
        note: "Delivered"
      },
      provinces: [
        "Manitoba",
        "Alberta",
        "British Columbia",
        "Ontario",
        "Saskatchewan",
        "Nova Scotia",
        "Prince Edward Island",
        "New Brunswick",
        "Quebec",
        "Minnesota",
        "Ohio"
      ],
      show: true,
      processing: false,
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: ""
    };
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    console.log("user:", this.user);
    if (this.user) {
      this.initials = this.user.name.split(" ").map((n) => n[0]).join("");
      this.form.driverInitials = this.initials;
      console.log("initials:", this.initials);
    }
    await this.axios
        .get(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/places/province/all/timezone",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("CALLIA_ID_TOKEN")
              }
            }
        )
        .then(response => {
          this.provinces = response.data.map(province => province.name);
        });
  },
  methods: {
    async imageUploader() {

      try {

        if (!this.form.file) return false;

        const reader = new FileReader();

        const readFileAsUint8Array = async (file) => {

          return new Promise((resolve, reject) => {
            reader.onload = function (e) {
              const uint8Array = reader.result;
              resolve(uint8Array);
            };

            reader.onerror = function (error) {
              reject(error);
            };

            reader.readAsBinaryString(file);
          });
        }

        const fileBuffer = await readFileAsUint8Array(this.form.file);

        const formData = {
          orderId:this.form.orderId,
          file: fileBuffer,
          fileName:this.form.file.name
        }

        const result = await this.axios.post(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/order/fulfilltask/imageupload",
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`,
              }
            }
        );

        return result.data.url;
      } catch (error) {
        console.error('Error on imageUploader :: ', error)
        return false;
      }
    },
    handleFileChange(event) {

      if (!event.target.files.length > 0) return;
      this.form.file = event.target.files[0];
    },
    async onSubmit(event) {

      event.preventDefault();
      this.processing = true;

      const deliveryTimeEpoc = dayjs.tz(this.form.deliveryDate + " " + this.form.deliveryTime, "America/Winnipeg").valueOf();

      try {

        const fileUpload = await this.imageUploader();
        const url = (fileUpload) ? fileUpload : null;

        console.log("deliveryTimeEpoc:", deliveryTimeEpoc);
        console.log("this.form:", JSON.stringify(this.form));
        await this.axios.post(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/order/fulfilltask/manual",
            {
              delivery: deliveryTimeEpoc,
              pod: this.form.note,
              order: this.form.orderId,
              url: url,
              time: this.form.deliveryTime,
              date: this.form.deliveryDate,
              driverInitials: this.form.driverInitials,
              province: this.form.province.replace(/\s/g, "").toLowerCase()
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              }
            }
        );

        this.processing = false;
        this.notificationMessage =
            "Autofulfillment success for order " + this.form.orderId;
        this.notification = {
          showErrorCountDown: 3,
          type: "success"
        };
        this.onReset();
      } catch (error) {
        this.processing = false;
        console.log(error);
        this.notificationMessage = "Oopps, Something is not right !!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }

      this.form.province = null;
      this.form.deliveryDate = "";
      this.form.deliveryTime = "";
      this.form.driverInitials = "";
      this.form.orderId = "";
      this.form.note = "Delivered";
      this.form.driverInitials = this.initials;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  computed: {
    enableButton() {
      if (this.form.province && this.form.deliveryDate &&
          this.form.deliveryTime && this.form.driverInitials &&
          this.form.orderId && this.form.note) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.adj-btn {
  margin-top: 30px;
}

.btn-outline-primary {
  background-color: #fff !important;
  color: $callia-green !important;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>