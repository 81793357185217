<template>
    <b-col  align="right" class="text-wrap">
        <!--          <b-badge variant="info">Double click on relevant content and make change the content<br> Then press the-->
        <!--            "Update All-->
        <!--            Changes" button-->
        <!--          </b-badge>-->
        <b-icon icon="question-circle" id="tooltip-target-1" variant="default"></b-icon>
        <b-tooltip show target="tooltip-target-1" triggers="['focus']" variant="warning" >
            Double click on relevant content and make change the content<br> Then press the<br>
            <label style="color: #627780; font-weight: bold;">"Update All Changes" </label> button
        </b-tooltip>
    </b-col>
</template>

<script>
    export default {
        name: "TooltipModal"
    }
</script>

<style scoped>
    .b-tooltip {
        z-index: 0;
    }
</style>
