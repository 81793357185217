<template>

  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">

    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="2" lg="8" sm="12">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
              v-model="filterOn"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="displayName">Driver</b-form-checkbox>
            <b-form-checkbox value="phone">Phone</b-form-checkbox>

          </b-form-checkbox-group>
          <b-badge variant="light"
          >Leave all unchecked to filter on all data
          </b-badge
          >
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col lg="8">
          <b-button variant="primary" size="sm" class="mr-1" @click="selectAllRows">Select all</b-button>
          <b-button variant="dark" size="sm" @click="clearSelected">Clear Selected</b-button>
        </b-col>
        <b-col lg="4" sm="12">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="7"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-table
              hover
              responsive
              :sticky-header="'58vh'"
              small
              id="exporter-process-table"
              class="table-height"
              :items="items"
              :fields="fields"
              :filter-included-fields="filterOn"
              :filter="filter"
              @filtered="onFiltered"
              head-variant="dark"
              :current-page="currentPage"
              :per-page="perPage"


              :select-mode="tableSelectMode"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
              show-empty
          >
            <template #empty>
              <h5 class="text-center bg-light font-weight-bold small p-3"> Drivers Not Available </h5>
            </template>

            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            offset-lg="9"
            offset-md="8"
            offset-sm="4"
            md="4"
            lg="3"
            sm="8"
            class="text-right"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>

export default {
  props: ["items", "processing", "totalRows"],
  name: "onfleet-driver-task-grid",
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "selected",
          sortable: true,
          label: "✔",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          key: "displayName",
          sortable: true,
          label: "Driver",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          key: "phone",
          sortable: true,
          label: "Phone",
          thClass: "text-center",
          tdClass: "text-center"
        }
      ],
      tableSelectMode: "multi",
      selected: [],
      filter: null,
      filterOn: [],
      sortDirection: "asc",
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 150, {value: 1000, text: "Show a lot"}]
    };
  },
  created() {
    this.$root.$refs.TodayOnfleetDriverTaskGrid = this;
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
      this.$emit("selectedDrivers", this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
      this.$emit("selectedDrivers", this.selected);
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.$emit("selectedDrivers", this.selected);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
<style>
.input-group {
  margin-left: 0 !important;
  text-align: center;
}

/*checkbox filter*/
.custom-filter-bg {
  background: radial-gradient(
      circle,
      #e9f1f966 44%,
      rgba(255, 255, 255, 1) 100%
  );
  border-radius: 20px;
}

/*filter search*/
input#filter-input {
  border-color: #343a405c;
  padding: 10px 20px;
  border-radius: 28px;
}

/*filter icon*/
.icon {
  right: 10px !important;
  top: 6px !important;
  margin-left: -23px;
  margin-top: 7px;
  z-index: 99;
}

/*custom pagination*/
.page-item.disabled .page-link {
  color: #bcc1c6;
  background-color: #222e3d;
  border-color: #ffffff;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #0a9ea5;
  border-color: #0a9ea5;
}




.page-link {
  font-weight: 600;
  color: #bbbfc5;
  background-color: #222e3d;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 0px;
}

.page-link:hover {
  background-color: #edf0f3;
  color: black;
  font-weight: 600;
}
</style>