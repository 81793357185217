<template>
  <div class="container-fluid p-0">
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />
    <GreetAddition @displayAlert="showAlert($event)"></GreetAddition>
    <CreateBulkOrder @displayAlert="showAlert($event)"></CreateBulkOrder>
    <br /><br />
    <UpdateBulkOrder @displayAlert="showAlert($event)"></UpdateBulkOrder>
  </div>
</template>

<script>
import CreateBulkOrder from "./CreateBulkOrder.vue";
import UpdateBulkOrder from "./UpdateBulkOrder.vue";
import GreetAddition from "./GreetAddition.vue";
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";

export default {
  data() {
    return {
      alert: {
        message: "",
        error: false,
        success: false
      }
    };
  },
  methods: {
    async showAlert(errorObj) {
      console.log(errorObj);
      this.alert.message = errorObj.message;
      if (errorObj.isError) {
        this.alert.error = true;
        this.alert.success = false;
      } else {
        this.alert.error = false;
        this.alert.success = true;
      }
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    }
  },
  components: {
    GreetAddition,
    CreateBulkOrder,
    UpdateBulkOrder,
    SuccessAlert,
    ErrorAlert
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
</style>
