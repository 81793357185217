<template>
  <div class="container-fluid p-0 h-100">
    <h3><strong>SMS</strong> history</h3>
    <sms-grid></sms-grid>
  </div>
</template>

<script>
import SMSGrid from "@/components/grids/sms-grid.vue";
export default {
  components: {
    "sms-grid": SMSGrid
  }
};
</script>

<style></style>
