<template>
  <b-modal
      id="modal-edit-template"
      ref="modal"
      :title="heading"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-variant="primary primary-btn"
      ok-title="Add"
      cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Name"
          label-for="name"
          valid-feedback="Looks Good"
          invalid-feedback="Name is required"
      >
        <b-form-input
            id="code"
            v-model="templateName"
            :state="validateTemplateName"
            @keyup="keyupValidationTemplateName"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label="Alert Header"
          label-for="alertHeader"
          valid-feedback="Looks Good"
          invalid-feedback="Header Required"
      >
        <b-form-textarea
            id="alertHeader"
            v-model="templateHeader"
            :state="validateHeader"
            @keyup="keyupValidationTemplateHeader"
            :clearable="true"
            required
            rows="3"
            max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group
          label="Alert Body"
          label-for="alertBody"
          valid-feedback="Looks Good"
          invalid-feedback="Body Required"
      >
        <b-form-textarea
            id="alertBody"
            v-model="templateBody"
            :state="validateBody"
            @keyup="keyupValidationTemplateBody"
            :clearable="true"
            required
            rows="3"
            max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>

export default {
  props: ["updateArray"],
  name: "edit-template-popup",
  data() {
    return {
      heading: "",
      templateName: "",
      validateTemplateName: null,
      validateHeader: null,
      templateHeader: "",
      validateBody: null,
      templateBody: "",
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  watch: {
    /** realtime props update */
    $props: {
      async handler(newVal) {
        this.heading = `Update Template: ${newVal.updateArray.name}`;
        this.templateName = newVal.updateArray.name;
        this.templateHeader = newVal.updateArray.alertHeader;
        this.templateBody = newVal.updateArray.alertBody;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    keyupValidationTemplateName() {
      if (this.alertName !== "") {
        this.validateTemplateName = true;
      } else {
        this.validateTemplateName = false;
      }
    },
    keyupValidationTemplateHeader() {
      if (this.alertHeader !== "") {
        this.validateHeader = true;
      } else {
        this.validateHeader = false;
      }
    },
    keyupValidationTemplateBody() {
      if (this.alertBody !== "") {
        this.validateBody = true;
      } else {
        this.validateBody = false;
      }
    },
    show() {
      this.$refs.modal.show();
    },
    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      //validate code
      if (this.$refs.form.code.checkValidity()) {
        this.validateTemplateName = true;
        this.validateBody = true;
        this.validateHeader = true;
      } else {
        this.validateTemplateName = false;
        this.validateBody = false;
        this.validateHeader = false;
      }
      return fullFormValidity;
    },
    resetModal() {
      this.validateHeader = "";
      this.validateTemplateName = "";
      this.alertName = "";
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert-template`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          params: {
            title: this.templateName,
            alertHeader: this.templateHeader,
            alertBody: this.templateBody
          }
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-edit-template");
        });
        this.$emit("success", response.data.message);
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>
