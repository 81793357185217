<template>
  <router-view></router-view>
</template>
<script>
export default {
};
</script>
<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/main.scss";

.modal-backdrop {
  background-color: #0a9ea5;
  opacity: 0.96;
}

body {
  margin: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #f7f7fc;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.content {
  padding: $content-padding-mobile;
  flex: 1;
  width: 100vw;
  max-width: 100vw;
  direction: ltr;

  @include media-breakpoint-up(md) {
    width: auto;
    max-width: auto;
  }

  @include media-breakpoint-up(lg) {
    padding: $content-padding-desktop;
  }
}
</style>
