<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="" subTopic="Zone Delivery Rate"/>
          <CLButton format="primary-btn" v-on:func="showAddModal">
            <plus-icon size="1.5x" class="text-white"></plus-icon>
            Add New
          </CLButton>
          <CLButton
              class="ml-1"
              format="secondary-btn"
              :disabled="isUpdateDisabled"
              v-on:func="updateZoneDeliveryRate"
          >
            Update All Changes
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon size="1.5x" class="text-dark"></refresh-cw-icon>
          </b-button>
          <add-ZoneDeliveryRate-popup
              ref="AddZoneDeliveryRateModal"
              :zoneOptions="zoneOptions"
              :provinceOptions="provinceOptions"
              :regionOptions="regionOptions"
              :deliveryRateOptions="deliveryRateOptions"
              @success="refreshGrid($event)"
              @error="errorCreateZoneDeliveryRate"
              @changeProvince="loadRegionCMBByProvinceID($event)"
              @changeRegion="loadZoneCMBByRegionID($event)"
          />
        </div>
      </div>
      <div class="row mb-0">
        <ZoneDeliveryRate-grid
            :putRequestArray="putRequestArray"
            :pgCurrentPage="pgCurrentPage"
            :pgTotalRows="pgTotalRows"
            :pgPerPage="pgPerPage"
            :processing="processing"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @updateRowArray="setUpdateRowArray($event)"
            @deleteZoneDeliveryRate="deleteZoneDeliveryRate($event)"
            @gridPaginationEvent="gridPaginationEvent($event)"
            @gridPaginationPerPageChange="updatePgPerPageChanges($event)"
            @changePaginationPageEvent="changePgCurrentPage($event)"
            @filterDataTableEvent="filterZoneDeliveryRateData($event)"
        ></ZoneDeliveryRate-grid>
      </div>
    </div>
  </div>
</template>
<script>
import {PlusIcon, RefreshCwIcon} from "vue-feather-icons";
import ZoneDeliveryRateGrid from "@/components/grids/zone-delivery-rate-grid";
import {CLHeader, CLButton} from "callia-ui-box";
import AddZoneDeliveryRateModal from "@/components/zone-delivery-rate/add-zone-delivery-rate-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "ZoneDeliveryRate-grid": ZoneDeliveryRateGrid,
    "add-ZoneDeliveryRate-popup": AddZoneDeliveryRateModal,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      totalRowData: [],
      totalRowDataNotFilter: [],
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      putRequestArray: [],
      rowData: [],
      processing: false,
      zoneOptions: [],
      deliveryRateOptions: [],
      columnDefs: [],
      provinceOptions: [],
      regionOptions: [],
      tblDeliveryRateOptions: [],
      tblZoneOptions: [],
      isUpdateDisabled: true,
      applicableDateOptions: [
        {value: 0, label: "0"},
        {value: 1, label: "1"},
      ],

      isSameDayOnlyOptions: [
        {value: true, label: "true"},
        {value: false, label: "false"},
      ],
      tblApplicableDateOptions: [
        {value: 0, text: "0"},
        {value: 1, text: "1"},
      ],

      tblIsSameDayOnlyOptions: [
        {value: true, text: "true"},
        {value: false, text: "false"},
      ],
    };
  },
  async created() {
    this.processing = true;
    await this.getAllProvince();
    await this.loadProvinceCMB();
    await this.getAllRegion();
    await this.loadRegionCMB();
    await this.getAllZone();
    await this.loadZoneCMB();
    await this.getAllDeliveryRate();
    await this.loadDeliveryRateCMB();
    await this.getAllZoneDeliveryRates();
    await this.initializeEditableTable();
    this.processing = false;
    this.putRequestArray = [];
  },
  methods: {
    async filterZoneDeliveryRateData(filterData) {
      //check filter data array filterOn empty stage
      this.processing = true;
      this.totalRowData = this.totalRowDataNotFilter.filter((item) => {
        if (
            (filterData.filterOn.includes("name") && item.name.includes(filterData.searchString.toLowerCase())) ||
            (filterData.filterOn.includes("zone") && item.zone.toLowerCase().includes(filterData.searchString.toLowerCase())) ||
            (filterData.filterOn.includes("deliveryRate") && item.deliveryRate.toLowerCase().includes(filterData.searchString.toLowerCase()))
        ) {
          return true;
        } else {
          return false;
        }
      });
      this.rowData = this.totalRowData.slice(0, this.pgPerPage);
      this.loopRowdata();

      this.pgCurrentPage = 1;
      this.processing = false;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage;
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(perPage);
      this.getAllZoneDeliveryRates();
      this.processing = false;
    },
    gridPaginationEvent(currentPage) {
      this.getAllZoneDeliveryRatesByPagination(currentPage);
    },
    async refreshPage() {
      this.isUpdateDisabled = true;
      this.processing = true;
      await this.getAllProvince();
      await this.loadProvinceCMB();
      await this.getAllRegion();
      await this.loadRegionCMB();
      await this.getAllZone();
      await this.loadZoneCMB();
      await this.getAllDeliveryRate();
      await this.loadDeliveryRateCMB();
      this.putRequestArray = [];
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(this.pgPerPage);
      await this.getAllZoneDeliveryRates();
      this.processing = false;
    },
    loadRegionCMBByProvinceID(provinceID) {
      try {
        this.regionOptions = [];
        this.zoneOptions = [];
        const regionArray = this.$store.getters.regions;
        regionArray.map((qData) => {
          if (qData.province_id === provinceID) {
            this.regionOptions.push({
              value: qData.region_id,
              label: qData.region_name,
            });
          }
        });

      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    loadZoneCMBByRegionID(regionID) {
      try {
        this.zoneOptions = [];
        const zoneArray = this.$store.getters.zone;
        zoneArray.map((qData) => {
          if (qData.region_id === regionID) {
            this.zoneOptions.push({
              value: qData.zone_id,
              label: qData.zone_name,
            });
          }
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadProvinceCMB() {
      try {
        const provinceArray = this.$store.getters.province;
        provinceArray.map((qData) => {
          this.provinceOptions.push({value: qData.id, label: qData.name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadZoneCMB() {
      try {
        const zoneArray = this.$store.getters.zone;
        zoneArray.map((qData) => {
          this.zoneOptions.push({value: qData.id, label: qData.name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadDeliveryRateCMB() {
      try {
        const deliveryRatesArray = this.$store.getters.deliveryRates;
        deliveryRatesArray.map((qData) => {
          this.deliveryRateOptions.push({value: qData.id, label: qData.name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadRegionCMB() {
      try {
        const regionArray = this.$store.getters.regions;
        regionArray.map((qData) => {
          this.regionOptions.push({
            value: qData.region_id,
            label: qData.region_name,
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = (event) => {
          return "Delete";
        };
        this.tblZoneOptions = [];
        this.$store.getters.zone.map((qData) => {
          this.tblZoneOptions.push({
            value: qData.zone_name,
            text: qData.zone_name,
          });
        });
        this.tblDeliveryRateOptions = [];

        this.$store.getters.deliveryRates.map((qData) => {
          this.tblDeliveryRateOptions.push({
            value: qData.name,
            text: qData.name,
          });
        });


        this.columnDefs.push(
            {
              sortable: true,
              filter: true,
              field: "name",
              headerName: "Name",
              editable: false,
            },
            {
              sortable: true,
              filter: true,
              field: "applicableStartTime",
              headerName: "Applicable Start Time",
              editable: true,
              type: "datetime",
              format: "HH:mm:ss",
            },
            {
              sortable: true,
              filter: true,
              field: "applicableEndTime",
              headerName: "Applicable End Time",
              editable: true,
              type: "datetime",
              format: "HH:mm:ss",
            },
            {
              sortable: true,
              filter: true,
              field: "applicableDate",
              headerName: "Applicable Date",
              editable: true,
              type: "select",
              selectOptions: this.tblApplicableDateOptions,
            },
            {
              sortable: true,
              filter: true,
              field: "isSameDayOnly",
              headerName: "Is Same Day Only",
              editable: true,
              type: "select",
              selectOptions: this.tblIsSameDayOnlyOptions,
            },
            {
              sortable: true,
              filter: true,
              field: "deliveryRate",
              headerName: "Delivery Rate",
              editable: false,
              type: "select",
              selectOptions: this.tblDeliveryRateOptions,
              className: 'b-table-header'
            },
            {
              sortable: true,
              filter: true,
              field: "zone",
              headerName: "Zone",
              editable: false,
              type: "select",
              selectOptions: this.tblZoneOptions,
            },
            {
              sortable: false,
              filter: false,
              field: "action",
              headerName: "Action",
              editable: false,
              type: "link",
              formatter: displayActionButton,
              className: "bg-secondary",
            }
        );
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async deleteZoneDeliveryRate(event) {
      try {
        this.$bvModal
            .msgBoxConfirm(
                `Please confirm that you want to delete this Zone Delivery Rate ${event.name}.`,
                {
                  title: "Delete Zone Delivery Rate",
                  okVariant: "danger",
                  okTitle: "Yes",
                  cancelTitle: "No",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                }
            )
            .then(async (status) => {
              if (status) {
                const response = await this.axios({
                  method: "DELETE",
                  url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/zone/rate/${event.id}`,
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.idToken}`,
                  },
                  data: {
                    name: event.name,
                  },
                });
                this.displayMessage("success", response.data["success"]);
                await this.getAllZoneDeliveryRates();
              }
            });
      } catch (errorResponse) {
        this.errorDeleteZoneDeliveryRate();
        console.error("Error: ", errorResponse);
      }
    },
    async updateZoneDeliveryRate() {
      const totalUpdateRecords = this.putRequestArray.length;
      if (totalUpdateRecords == 0) {
        this.displayMessage(
            "warning",
            "Alert! There is no available changes for update"
        );
      } else {
        let updateCodesString = "";
        this.putRequestArray.map(async (qData) => {
          updateCodesString += `${qData.name}, `;
        });
        this.$bvModal
            .msgBoxConfirm(
                `Please confirm that you want to update all of the changes that you have already done on Zone Delivery Rates: ${updateCodesString}`,
                {
                  title: "Update Zone Delivery Rate Records",
                  okVariant: "primary primary-btn",
                  okTitle: "Yes",
                  cancelTitle: "No",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                }
            )
            .then(async (status) => {
              if (status) {
                try {
                  if (totalUpdateRecords > 1) {
                    let response;

                    this.putRequestArray.map(async (qData) => {

                      const config = {
                        method: "PUT",
                        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/zone/rate/${qData.id}`,
                        headers: {
                          Authorization: `Bearer ${this.$store.getters.idToken}`,
                        },
                        data: qData,
                      }
                      response = await this.axios(config);
                      this.displayMessage("success", response.data["success"]);
                      this.isUpdateDisabled = true;
                    });
                  } else {
                    const response = await this.axios({
                      method: "PUT",
                      url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/zone/rate/${this.putRequestArray[0]["id"]}`,
                      headers: {
                        Authorization: `Bearer ${this.$store.getters.idToken}`,
                      },
                      data: this.putRequestArray[0],
                    });
                    this.displayMessage("success", response.data["success"]);
                    this.isUpdateDisabled = true;
                  }

                  this.putRequestArray = [];
                  await this.getAllZoneDeliveryRates();

                } catch (errorResponse) {
                  this.errorUpdateZoneDeliveryRate();
                  console.error("Error: ", errorResponse);
                }
              }
            });
      }
    },
    async getAllZone() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.$store.commit("setZone", response.data.result);
    },
    async getAllDeliveryRate() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.$store.commit("setDeliveryRates", response.data);
    },
    async getAllProvince() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/province`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.$store.commit("setProvince", response.data);
    },
    async getAllRegion() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.$store.commit("setRegions", response.data);
    },
    async getAllZoneDeliveryRatesByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      this.rowData = this.totalRowData.slice(offset, offset + this.pgPerPage);
      this.loopRowdata();
    },
    async getAllZoneDeliveryRates() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`,
        },
      });
      this.pgTotalRows = parseInt(response.data.length);
      /**convert applicableStartTime and applicableEndTime to datatime */
      const today = this.getNowFormatDate();
      for (const data of response.data) {
        if (data.applicableStartTime) {
          data.applicableStartTime = today + "T" + data.applicableStartTime;
        }
        if (data.applicableEndTime) {
          data.applicableEndTime = today + "T" + data.applicableEndTime;
        }
      }
      this.totalRowDataNotFilter = response.data;
      this.totalRowData = response.data;
      this.rowData = this.totalRowData.slice(0, this.pgPerPage);
      this.loopRowdata();
    },
    getNowFormatDate() {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    showAddModal() {
      this.$refs.AddZoneDeliveryRateModal.show();
    },
    setUpdateRowArray(event) {

      this.isUpdateDisabled = false;
      this.putRequestArray = [];
      event.map((qData) => {
        this.putRequestArray.push({
          id: qData.id,
          name: qData.name,
          applicableStartTime: qData.applicableStartTime,
          applicableEndTime: qData.applicableEndTime,
          applicableDate: parseInt(qData.applicableDate),
          isSameDayOnly: qData.isSameDayOnly === "true",
          deliveryRateId: qData.deliveryRateId,
          deliveryRate: qData.deliveryRate,
          zoneId: qData.zoneId,
          zone: qData.zone,
        });
      });
    },
    async refreshGrid(message) {
      await this.getAllZoneDeliveryRates();
      this.displayMessage("success", message);
    },
    errorCreateZoneDeliveryRate() {
      this.displayMessage(
          "warning",
          "Zone Delivery Rate saving process failed."
      );
    },
    errorUpdateZoneDeliveryRate() {
      this.displayMessage(
          "warning",
          "Zone Delivery Rate update process failed."
      );
    },
    errorDeleteZoneDeliveryRate() {
      this.displayMessage(
          "warning",
          "Zone Delivery Rate delete process failed."
      );
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    loopRowdata() {
      this.rowData.forEach(d => {
        d['$cellStyle'] = {
          'applicableStartTime': {backgroundColor: '#e7eef2'},
          'applicableEndTime': {backgroundColor: '#e7eef2'},
          'applicableDate': {backgroundColor: '#e7eef2'},
          'isSameDayOnly': {backgroundColor: '#e7eef2'},
          'deliveryRate': {backgroundColor: '#e7eef2'},
          'action': {justifyContent: 'center'}
        }
      })
    }
  },
};
</script>
