<template>
  <b-modal
    scrollable
    centered
    ref="modal"
    hide-footer
    id="overrideModal"
    :title="'Dates Closed for ' + datesCloseResult.name"
    hide-backdrop
  >
    <div>
      <b-card class="text-center">
        <b-form @submit="saveDatesClose">
          <div class="row mt-3">
            <div class="col-sm">
              <b-form-tags
                v-model="closeDates"
                type="text"
                placeholder="Enter New Date"
                required
              ></b-form-tags>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm">
              <b-button
                type="submit"
                class="subBtn"
                variant="primary"
                :disabled="loading"
                >Submit
              </b-button>
            </div>
          </div>
        </b-form>

        <div v-if="loading">
          <b-spinner class="loading" type="grow" label="Loading..."></b-spinner>
        </div>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["selectedOverride"],
  data() {
    return {
      closeDates: [],
      datesCloseResult: {},
      loading: false,
    };
  },

  watch: {
    selectedOverride: async function (val) {
      this.modalDataReset();
      this.loading = true;
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            `/region-rules/${val.region_id}/rule`
        );
        const { datesClosed } = res.data;
        this.datesCloseResult = datesClosed || {};
        if (!this.datesCloseResult.rule_props) {
          this.datesCloseResult.name = val.region;
          this.datesCloseResult.id = val.region_id;
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.datesCloseResult.rule_name = "dates_closed";
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.datesCloseResult.rule_props = "";
        }

        this.closeDates = this.datesCloseResult.rule_props.split(",");
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
  methods: {
    async saveDatesClose(e) {
      e.preventDefault();
      try {
        const result = await this.axios.put(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            `/region-rules/${this.datesCloseResult.id}/rule`,
          {
            type: "datesClosed",
            closeDates: this.closeDates.join(",").toString(),
          }
        );

        result && result.status == 200
          ? this.displayMessage(
              "success",
              `${this.datesCloseResult.name} :: ${result.data}`
            )
          : result.data
          ? this.displayMessage(
              "warning",
              `${this.datesCloseResult.name} :: ${result.data}`
            )
          : this.displayMessage(
              "warning",
              `${this.datesCloseResult.name} :: Error updating cutoff time.`
            );
      } catch (err) {
        console.error(err);
      }
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
    modalDataReset() {
      this.closeDates = [];
      this.datesCloseResult.name = "";
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 10000,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/view-overrides-modal.scss";
</style
>