<template>
  <b-modal
      id="modal-add-town"
      ref="modal"
      title="Add New Town"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-variant="primary primary-btn"
      ok-title="Add"
      cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Town"
          label-for="Town"
          invalid-feedback="Town is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="town_name"
            v-model="townName"
            :state="validateTownName"
            @keyup="keyupValidationTownName"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label="Zone"
          label-for="zone"
          invalid-feedback="Choose a Zone"
      >
        <v-select
            id="zone_id"
            :options="zoneOptions"
            placeholder="choose..."
            v-model="zoneModel"
            :clearable="true"
            :class="validateZone"
            @input="zoneSelectedValue"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!zoneModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  props: ["zoneOptions"],
  name: "add-town-popup",
  data() {
    return {
      townName: "",
      validateTownName: null,
      validateZone: "",
      zoneModel: "",
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  methods: {
    clearZoneCMB() {
      this.validateZone = "";
      this.zoneModel = "";
    },
    show() {
      this.$refs.modal.show();
    },
    zoneSelectedValue(event) {
      if (event !== null) {
        this.validateZone = "";
      } else {
        this.validateZone = "is-invalid";
      }
      this.$emit('changeZone', event.value)
    },
    validateZoneCMB() {
      if (this.zoneModel !== null) {
        if (this.zoneModel.value == null) {
          this.validateZone = "is-invalid";
        } else {
          this.validateZone = "";
        }
      } else {
        this.validateZone = "is-invalid";
      }
    },
    keyupValidationTownName() {
      if (this.townName !== "") {
        this.validateTownName = true;
      } else {
        this.validateTownName = false;
      }
    },
    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      //validate code
      if (this.$refs.form.town_name.checkValidity()) {
        this.validateTownName = true;
      } else {
        this.validateTownName = false;
      }
      this.validateZoneCMB();
      return fullFormValidity;
    },
    resetModal() {
      this.validateZone = "";
      this.validateTownName = "";
      this.townName = "";
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const townArray = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          town_name: this.townName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          zone_id: this.zoneModel.value
        };

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: townArray
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-town");
        });
        this.$emit("success", response.data['success']);
      } catch (errorResponse) {
        console.log(errorResponse)
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>