<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <cl-header mainTopic="Onfleet Regional Drivers Mass SMS Hub"/>
      </b-col>
    </b-row>
    <b-overlay :show="smsProgress" rounded="sm" class="overlay-display w-100">
      <b-row class="mt-4 mb-0">

        <b-col sm="12" lg="6" class="pr-3">
          <b-row>
            <b-col>
              <b-form-group
                  label="Choose Region for fetch drivers which tasks available for today"
                  label-for="regions"
                  invalid-feedback="Choose a region"
              >
                <v-select
                    id="regions"
                    :options="regionOptions"
                    :v-model="regionSelectBox"
                    placeholder="choose..."
                    :clearable="true"
                    @input="regionSelectedValue($event)"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>

              <onfleet-driver-task-grid
                  :items="gridItems"
                  :processing="processing"
                  :totalRows="totalRows"
                  @selectedDrivers="getSelectedDrivers"
              ></onfleet-driver-task-grid>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" lg="6">
          <b-row>
            <b-col>
              <p>Choose Template</p>
              <onfleet-driver-sms-template-grid
                  :items="gridTemplateItems"
                  :processing="templateProcessing"
                  :totalRows="totalTemplateRows"
                  @selectedTemplate="getSelectedTemplate"
              ></onfleet-driver-sms-template-grid>
            </b-col>
          </b-row>
          <b-row cols-lg="mt-2">
            <b-col>
              <b-form-group
                  v-show="situationGroupDisplay"
                  label="Situation"
                  label-for="situation"
              >
                <b-form-input
                    id="situation"
                    v-model="situation"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  v-show="timeGroupDisplay"
                  label="Time"
                  label-for="time"
              >
                <b-form-input
                    id="shortCode"
                    v-model="time"
                ></b-form-input>
              </b-form-group>
              <hr>
              <b-form-group align="center">
                <b-button size="lg" variant="dark" @click="executeSMSProcess">
                  <message-square-icon size="1.5x" class="text-success"></message-square-icon>
                  Execute SMS Process
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import {MessageSquareIcon} from "vue-feather-icons";
import TodayOnfleetDriverTaskGrid from "@/components/grids/today-onfleet-driver-task-grid";
import OnfleetDriverSMSTemplate from "@/components/grids/onfleet-driver-sms-template-grid";
import {CLHeader} from "callia-ui-box";

export default {
  components: {
    MessageSquareIcon,
    "onfleet-driver-task-grid": TodayOnfleetDriverTaskGrid,
    "onfleet-driver-sms-template-grid": OnfleetDriverSMSTemplate,
    "cl-header": CLHeader,
  },
  data() {
    return {
      title: "",
      smsProgress: false,
      regionSelectBox: null,
      regionTimezone: null,
      selectedDrivers: [],
      selectedTemplate: [],
      regionOptions: [],
      situation: "#",
      situationGroupDisplay: false,
      time: "#",
      timeGroupDisplay: false,
      gridItems: this.$store.getters.todayOnfleetDrivers,
      gridTemplateItems: this.$store.getters.onfleetDriverSMSTemplate,
      processing: false,
      templateProcessing: false,
      totalRows: 0,
      totalTemplateRows: 0
    };
  },
  async mounted() {
    await this.getOnfleetDriverRegions();
    await this.getAllOnfleetDriverSMSTemplate();
  },
  methods: {
    async executeSMSProcess() {

      try {

        this.$bvModal.msgBoxConfirm(`Are you sure want to proceed ?`, {
          title: 'Mass SMS Hub: Alert!',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {

          if (status) {

            const timezone = this.regionTimezone;

            const drivers = []
            for (const worker of this.selectedDrivers) {
              drivers.push(worker.workerId)
            }

            if (this.regionSelectBox && timezone) {

              if (this.selectedTemplate[0] && this.selectedTemplate[0].templateId) {

                if (drivers.length > 0) {

                  const postData = {
                    templateId: (this.selectedTemplate[0].templateId) ? this.selectedTemplate[0].templateId : "",
                    drivers: drivers,
                    region: this.regionSelectBox,
                    timezone: timezone,
                    situation: (this.situation && this.situation !== "") ? this.situation : "",
                    time: (this.time && this.time !== "") ? this.time : "",
                  }

                  this.smsProgress = true;

                  const response = await this.axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/driver/task/sms/send`,
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.idToken}`
                    },
                    data: postData
                  });

                  this.displayMessage("success", response.data.success);

                  this.smsProgress = false;
                } else {
                  this.displayMessage("warning", "SMS Sending Failed! Choose correct variation.");
                }
              } else {
                this.displayMessage("warning", "SMS Sending Failed! Please Select SMS template to Send SMS.");
              }
            } else {
              this.displayMessage("warning", "SMS Sending Failed! Please Choose Region and Drivers Before Send SMS.");
            }
          }
        })
      } catch (errorResponse) {
        console.log(errorResponse)
        this.displayMessage("warning", "SMS Sending Failed!.");
      }
    },
    getSelectedDrivers(event) {
      this.selectedDrivers = event
    },
    getSelectedTemplate(event) {

      console.log(event[0].template)

      if (event[0].template.match('{{situation}}')) {
        this.situationGroupDisplay = true;
      } else {
        this.situationGroupDisplay = false;
      }

      if (event[0].template.match('{{time}}')) {
        this.timeGroupDisplay = true;
      } else {
        this.timeGroupDisplay = false;
      }

      this.selectedTemplate = event
    },
    regionSelectedValue(event) {
      this.regionSelectBox = event.label;
      this.getAllOnfleetDriversForToday(event.label);
      this.processing = true;
    },

    async getOnfleetDriverRegions() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/driver/region/all`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const regionArray = response.data;
        regionArray.forEach(qData => {
          this.regionOptions.push({
            value: qData.regionId,
            label: qData['regionName']
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    async getAllOnfleetDriversForToday(region) {
      try {
        this.items = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/driver/task/region/${region}/all`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const driverArray = [];
        this.regionTimezone = response.data.timezone;
        for (const driver of response.data.drivers) {
          driverArray.push({
            workerId: driver.workerId,
            displayName: (driver.displayName && driver.displayName !== "") ? driver.displayName : driver['driverFullName'],
            phone: driver.phone,
            timezone: driver.timezone
          });
        }
        this.$store.commit("setTodayOnfleetDrivers", driverArray);
        this.gridItems = this.$store.getters.todayOnfleetDrivers;
        this.totalRows = this.gridItems.length;
        this.processing = false;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error on Fetching Drivers!.");
        console.log("Error: ", errorResponse);
      }
    },
    async getAllOnfleetDriverSMSTemplate(region) {
      try {
        this.gridTemplateItems = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/driver/task/sms/template/all`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        this.templateProcessing = true;
        const templateArray = [];
        for (const template of response.data) {
          templateArray.push({
            templateId: template.templateId,
            template: `${template.templateName} :: 💬 :: ${template.template}`
          })
        }

        this.$store.commit("setOnfleetDriverSMSTemplate", templateArray);
        this.gridTemplateItems = this.$store.getters.onfleetDriverSMSTemplate;
        this.totalTemplateRows = this.gridTemplateItems.length;
        this.templateProcessing = false;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error on Fetching Onfleet Driver SMS Template!.");
        console.log("Error: ", errorResponse);
      }
    }
  }
};
</script>
