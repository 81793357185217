<template>
  <b-modal
    ref="modal"
    id="modal-edit-opslink"
    hide-footer
    title="Edit Ops Link"
  >
    <h3>Name: {{ this.selectedlink.name }}</h3>
    <div class="d-block">
      <b-form @submit="onSubmit">
        <b-form-group id="newLinkGroup" label="Link:" label-for="link-input">
          <b-form-input
            id="link-input"
            v-model="this.selectedlink.link"
            placeholder="Enter link"
            required
          ></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button
            type="submit"
            class="form-submit-button submit-button adj-btn"
            variant="primary"
            >Submit</b-button
          >
          <b-button
            @click="hideModal"
            class="reset-button adj-btn"
            variant="primary"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["selectedlink"],
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      try {
        await this.axios.put(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            `/ops/link/${this.selectedlink.name}`,
          {
            name: this.selectedlink.name,
            link: this.selectedlink.link
          }
        );
        this.hideModal();
      } catch (err) {
        console.error(err);
      }
    },
    showModal() {
      this.$refs.modal.show("modal-edit-opslink");
    },
    hideModal() {
      this.$refs.modal.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
</style>
