<template>
  <b-modal
    id="modal-update-dates-closed"
    ref="modal"
    :title="heading"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="DatesClosed"
      >
        <b-form-tags
          v-model="datesClosed"
          type="text"
          placeholder="Enter New Date"
          required
        ></b-form-tags>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>

export default {
  /** initialize props */
  props: ["name", "updateArray"],
  name: "edit-dates-closed-popup",
  data() {
    return {
      datesClosed: [],
      datesCloseResult: "",
      heading: "",
      regionId: "",
      regionName: "",
    };
  },
  watch: {
    /** realtime props update */
    $props: {
      async handler(newVal, oldVal) {
        if (newVal?.updateArray) {
          console.log(`${JSON.stringify(newVal, null, 2)}`);
          this.regionName = newVal.updateArray.name;
          this.heading = `Update Region: ${newVal.updateArray.name}`;
          this.regionId = newVal.updateArray.region_id;
          console.log(newVal.updateArray.rule_props);
          this.datesClosed = newVal.updateArray.rule_props ? newVal.updateArray.rule_props.split(",") : [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async show() {
      this.$refs.modal.show();
    },

    resetModal() {
      console.log();
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 10000,
        solid: true,
      });
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      try {
        const result = await this.axios.put(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            `/region-rules/${this.regionId}/rule`,
          {
            type: "datesClosed",
            closeDates: this.datesClosed.join(","),
          }
        );

        if (result && result.status == 200) {
          this.$emit("success", `${this.regionName} :: ${result.data}`);
        } else if (result.data) {
          this.displayMessage(
            "warning",
            `${this.regionName} :: ${result.data}`
          );
        } else { this.displayMessage(
            "warning",
            `${this.regionName} :: Error updating closed dates.`
          );
        }
      } catch (err) {
        console.error(err);
      }
      this.$refs.modal.hide();
    }
  }
};
</script>
