<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0" id="order-reports">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Order Replacement Automation</strong></h3>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-12 col-xl-12 col-12">
          <div class="form-card">
            <b-form id="order-report-form">
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <b-form-input
                      id="input-1"
                      v-model="order.orderId"
                      type="text"
                      maxlength="10"
                      placeholder="Please input Order Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-button
                    type="button"
                    variant="primary"
                    class="submit-button"
                    @click="query"
                  >
                    Query Order
                  </b-button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Province: ({{ order.province }})</label>
                    <b-form-select
                      v-model="form.province"
                      :options="provincesOpt"
                      @change="selectProvince"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">City: ({{ order.city }})</label>
                    <v-select :options="citiesOpt"
                      v-model="form.city"
                      @input="selectCity"
                      class="input-height mt-1"></v-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Region: ({{ order.region }})</label>
                    <b-form-input
                      v-model="form.region"
                      type="text"
                      readonly
                      placeholder="Region"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery date: ({{ order.deliveryDate }})</label>
                    <b-form-datepicker
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="form.deliveryDateNew"
                      :date-disabled-fn="dateDisabled"
                      @input="queryProducts"
                      class="mb-2"
                      required
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Postal Code: ({{ order.postalCode }})</label>
                    <b-form-input
                      v-model="form.postalCode"
                      :state="postalCodeState"
                      @blur="queryDeliveryWindow"
                      maxlength="7"
                      type="text"
                      placeholder="Please input Postal Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Lang: ({{ order.lang }})</label>
                    <b-form-select
                      v-model="form.lang"
                      :options="langOpt"
                      @change="queryDeliveryWindow"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">First Name: ({{ order.firstName }})</label>
                    <b-form-input
                      v-model="form.firstName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input First Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Last Name: ({{ order.lastName }})</label>
                    <b-form-input
                      v-model="form.lastName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Last Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Contact Name: ({{ order.cname }})</label>
                    <b-form-input
                      v-model="form.cName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Contact Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Relationship: ({{ order.relationship }})</label>
                    <b-form-select
                      v-model="form.relationship"
                      :options="relationshipsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Occasion: ({{ order.occasion }})</label>
                    <b-form-select
                      v-model="form.occasion"
                      :options="occasionsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address Type: ({{ order.addressType }})</label>
                    <b-form-select
                      v-model="form.addressType"
                      :options="addressTypesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Phone Number: ({{ order.phone }})</label>
                    <b-form-input
                      v-model="form.phoneNumber"
                      type="text"
                      maxlength="14"
                      :state="isPhoneNumberValid(form.phoneNumber)"
                      @blur="form.phoneNumber = formatPhoneNumber(form.phoneNumber)"
                      placeholder="Please input Phone Number"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address: ({{ order.address }})</label>
                    <b-form-input
                      v-model="form.address"
                      type="text"
                      maxlength="150"
                      placeholder="Please input Address"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Apartment Number: ({{ order.apartmentNumber }})</label>
                    <b-form-input
                      v-model="form.apartmentNumber"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Apartment Number"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Buzzer Code: ({{ order.buzzerCode }})</label>
                    <b-form-input
                      v-model="form.buzzerCode"
                      type="text"
                      maxlength="20"
                      placeholder="Please input Buzzer Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery Window: ({{ order.deliveryRate }})</label>
                    <b-form-select
                      v-model="form.deliveryRate"
                      :options="shippingMethodsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Consent: ({{ order.consent }})</label>
                    <b-form-select
                      v-model="form.consent"
                      :options="consentOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Bouquet Name: ({{ order.bouquetName }})</label>
                    <b-form-select
                      v-model="form.bouquetName"
                      :options="bouquetOpt"
                      @change="selectBouquet"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Bouquet Size: ({{ order.bouquetSize }})</label>
                    <b-form-select
                      v-model="form.bouquetSize"
                      :options="bouquetSizesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Vase: ({{ order.vase }})</label>
                    <b-form-select
                      v-model="form.vase"
                      :options="vaseOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Addons Inventory:</label>
                    <b-form-select
                      v-model="addons"
                      :options="addonsInventoryOpt"
                      :select-size="6"
                      @dblclick.native="selectAddon"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="w-10">
                  <b-button-group vertical>
                    <label class="mt-1 report-label">Options</label>
                    <br />
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="selectAddon"
                    >
                      &gt;&gt;
                    </b-button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="unselectAddon"
                    >
                      &lt;&lt;
                    </b-button>
                  </b-button-group>
                </div>
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Order Addons: ({{ order.addons }})</label>
                    <b-form-select
                      v-model="form.addons"
                      :options="addonsOpt"
                      :select-size="6"
                      @dblclick.native="unselectAddon"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery Notes: ({{ order.deliveryNotes }})</label>
                    <b-form-input
                      v-model="form.deliveryNotes"
                      type="text"
                      maxlength="200"
                      placeholder="Please input Delivery Notes"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Card Type: ({{ order.cardType }})</label>
                    <b-form-select
                      v-model="form.cardType"
                      :options="cardOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Card Message (Maximum 800 characters)</label
                    >
                    <b-form-textarea
                      v-model="form.message"
                      rows="3"
                      max-rows="8"
                      :state="form.message && form.message.length <= 800"
                      placeholder="Please input Message"
                    ></b-form-textarea>

                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Order Notes:</label>
                    <b-form-input
                      v-model="form.note"
                      type="text"
                      placeholder="Please input Order Notes"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-input-group class="mt-1" prepend="Subtotal $">
                    <b-form-input
                      v-model="subtotal"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <b-button
                      type="button"
                      variant="primary"
                      class="submit-button"
                      @click="createOrder"
                    >
                      Create new order
                    </b-button>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Result</label
                    >
                    <b-form-textarea
                      v-model="result"
                      rows="8"
                      type="text"
                      readonly
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </b-overlay>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";
import { getAllCities, getCutoffs } from "@/utils/queryUtil";

import FormatMixin from "@/mixin/format-mixin";

export default {
  mixins: [FormatMixin],
  components: {
    ErrorAlert,
    SuccessAlert,
    "v-select": vSelect,
  },
  computed: {
    postalCodeState() {
      return this.form.postalCode && this.form.postalCode.length >= 6 ? true : false;
    },
    subtotal() {
      let price = 0;
      try {
        if (this.form.deliveryRate && this.shippingMethodsOpt) {
          console.log("deliveryRate:", this.form.deliveryRate);
          const deliveryRateObj = this.shippingMethodsOpt.find(item => item.value === this.form.deliveryRate);
          if (deliveryRateObj && deliveryRateObj.price) {
            price += parseFloat(deliveryRateObj.price.replace("$", ""));
          } else {
            console.log("Could not find deliveryRate:", this.form.deliveryRate);
          }
        }
        if (this.form.vase && this.vaseOpt) {
          const vaseObj = this.vaseOpt.find(item => item.value === this.form.vase);
          if (vaseObj && vaseObj.price) {
            price += parseFloat(vaseObj.price);
          }
        }
        if (this.form.cardType && this.cardOpt) {
          const cardObj = this.cardOpt.find(item => item.value === this.form.cardType);
          if (cardObj && cardObj.price) {
            price += parseFloat(cardObj.price);
          }
        }
        if (this.addonsOpt && this.addonsOpt.length > 0) {
          for (const addon of this.addonsOpt) {
            if (addon && addon.value && addon.value.price) {
              price += parseFloat(addon.value.price) * addon.value.quantity;
            }
          }
        }
        if (this.form.bouquetSize && this.bouquetSizesOpt) {
          const bouquetObj = this.bouquetSizesOpt.find(item => item.value === this.form.bouquetSize);
          if (bouquetObj && bouquetObj.price) {
            price += parseFloat(bouquetObj.price);
          }
        }
      } catch(e) {
        console.error("subtotal error:", e);
      }
      return `${price}`;
    }
  },
  data() {
    return {
      processing: false,
      processingMessage: "Please wait...",
      productsQueryURL: "https://shop.callia.com/products/query",
      result: "",
      order: {
        orderId: "",
        deliveryDate: "",
        region: "",
        tags: "",
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        deliveryRate: "",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        addons: [],
        vase: null,
        regionCode: "",
      },
      form: {
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        deliveryDate: "",
        deliveryDateNew: "",
        deliveryRate: "",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        giftSetName: "",
        addons: "",
        vase: "",
        consent: "No",
        phoneNumber: "",
        cName: "",
        note: "",
        lang: "en-ca",
        regionCode: "",
      },
      addons: "",

      alert: {
        error: false,
        message: "",
      },

      shippingMethodsOpt: [
        { text: "Select an option", value: null },
      ],

      addressTypesOpt: [
        { text: "Select an option", value: null },
        { text: "House", value: "House" },
        { text: "Apartment/Complex", value: "Apartment/Complex" },
        { text: "Business", value: "Business" },
        { text: "Hospital", value: "Hospital" },
      ],

      relationshipsOpt: [
        { text: "Select an option", value: null },
        { text: "Partner", value: "Partner" },
        { text: "Friend", value: "Friend" },
        { text: "Mom", value: "Mom" },
        { text: "Grandparent", value: "Grandparent" },
        { text: "Family member", value: "Family member" },
        { text: "Colleague", value: "Colleague" },
        { text: "Client", value: "Client" },
        { text: "Myself", value: "Myself" },
        { text: "Other", value: "Other" },
      ],

      occasionsOpt: [
        { text: "Select an option", value: null },
        { text: "Birthday", value: "Birthday" },
        { text: "Anniversary", value: "Anniversary" },
        { text: "Celebration", value: "Celebration" },
        { text: "Engagement", value: "Engagement" },
        { text: "Just because", value: "Just because" },
        { text: "Sympathy", value: "Sympathy" },
        { text: "Thinking of you", value: "Thinking of you" },
        { text: "Self-care", value: "Self-care" },
        { text: "Other", value: "Other" },
      ],

      bouquetSizesOpt: [
        { text: "Select an option", value: null },
      ],

      bouquetOpt: [
      ],
      bouquetFlatOpt: [
      ],
      addonsInventoryOpt: [
      ],
      addonsOpt: [
      ],
      vaseOpt: [
      ],
      cardOpt: [
      ],
      bouquetSizeObj: {},
      consentOpt: {
        "Yes": "Yes",
        "No": "No",
      },
      langOpt: [
        { text: "CA-EN", value: "en-ca" },
        { text: "CA-FR", value: "fr-ca" },
      ],
      text2EmailLangObj: {
        "ca-en": "en-ca",
        "ca-fr": "fr-ca",
      },
      email2TextLangObj: {
        "en-ca": "ca-en",
        "fr-ca": "ca-fr",
      },
      provincesOpt: [],
      citiesOpt: [],
      provinceMap: {
        "Manitoba": "MB",
        "Saskatchewan": "SK",
        "Alberta": "AB",
        "British Columbia": "BC",
        "Ontario": "ON",
        "Maritimes": "HX",
        "Québec": "QC",
        "Quebec": "QC",
        "Nova Scotia": "NB",
        "Prince Edward Island": "PE",
        "New Brunswick": "NB",
      },
      provinceCityObj: {},
      cityRegionObj: {},
      allCitiesObj: {},
      closedDays: [],
      unavailableDates: [],
      availableDates: [],
      cutoffTime: [],
    };
  },
  async mounted() {
    this.queryCities();
  },
  methods: {
    async query() {
      if (!this.order.orderId) {
        this.displayMessage("warning", `Order Name can't be empty`);
        return false;
      }

      this.processing = true;
      this.displayMessage("Success", "Getting order data...");

      try {
        this.form.bouquetName = null;
        const orderName = this.order.orderId.replace('#', '').trim();
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/order/" + orderName;
        const response = await this.axios.get(url).then(
          res => {
            console.log("response:", res);
            if (res && res.data) {
              return res.data;
            } else {
              this.displayMessage("warning", `Query Exception: Please try again or re-login.`);
            }
          }
        ).catch(err => {
          this.displayMessage("warning", `Query Exception: ${err}`);
        });
        if (!response) {
          this.processing = false;
          return;
        }
        this.order = response;

        if (this.order.province) {
          this.form.province = this.order.province.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          this.selectProvince();
          if (this.order.city) {
            this.form.city = this.order.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            await this.selectCity();
          }
        }

        this.form.postalCode = this.order.postalCode;
        this.form.lang = this.text2EmailLangObj[this.order.lang?.toLowerCase()];
        const deliveryDate = dayjs.tz(this.order.deliveryDate,  'America/Winnipeg');
        const currentTimeStamp = dayjs().tz('America/Winnipeg');
        const today = currentTimeStamp.startOf('day');
        if (!deliveryDate.isBefore(today)) {
          this.form.deliveryDateNew = deliveryDate.format("YYYY-MM-DD");
          await this.queryProducts();
        } else {
          this.displayMessage("warning", `Please select delivery date`);
        }

        if (this.order && this.order.bouquetName) {
          console.log("bouquet name:", this.order.bouquetName);
          if (this.order.bouquetName.includes("-")) {
            this.order.bouquetSize = this.order.bouquetName.split('-')[1].trim();
            this.order.bouquetName = this.order.bouquetName.split('-')[0].trim();
            this.form.bouquetName = this.order.bouquetName;
            this.selectBouquet();
            this.form.bouquetSize = this.order.bouquetSize;
          }
        } else {
          console.log("bouquet name empty", this.order);
        }
        this.addonsOpt.length = 0;
        if (this.order.addons) {
          const addons = this.order.addons.split(',');
          for (const addon of addons) {
            let addonStr = addon;
            let addonQuantity = 1;
            if (addon.includes("(")) {
              addonStr = addon.split("(")[0].trim();
              addonQuantity = parseInt(addon.split("(")[1].trim().replace(/\s|[)]|x/g, ""));
            }
            const orderAddonsObj = this.addonsInventoryOpt.find(item => item.value.value === addonStr);
            if (orderAddonsObj) {
              this.addonsOpt.push({ text: `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price} (x${addonQuantity})`,
                                    value: {value: orderAddonsObj.value.value, id: orderAddonsObj.value.id,
                                            price: orderAddonsObj.value.price, quantity: addonQuantity,
                                            quantityAvailable: orderAddonsObj.value.quantityAvailable,
                                            } });
            }
          }
        }
        this.form.vase = "";
        if (this.order.vase) {
          if (this.vaseOpt.find(item => item.value === this.order.vase)) {
            this.form.vase = this.order.vase;
          }
        }

        this.form.deliveryRate = this.order.deliveryRate;
        this.form.addressType = this.order.addressType;
        this.form.address = this.order.address;
        this.form.buzzerCode = this.order.buzzerCode;
        this.form.apartmentNumber = this.order.apartmentNumber;
        this.form.consent = this.order.consent;
        this.form.phoneNumber = this.order.phone;
        this.form.firstName = this.order.firstName;
        this.form.lastName = this.order.lastName;
        this.form.cName = this.order.cname || this.order.cName;
        this.form.relationship = this.order.relationship;
        this.form.occasion = this.order.occasion;
        this.form.cardType = this.order.cardType;
        this.form.deliveryNotes = this.order.deliveryNotes;
        this.form.message = this.order.message;
      } catch(e) {
        console.error("query error:", e);
      }

      this.processing = false;
    },

    async queryCities() {
      // this.displayMessage("Success", "Getting cities...");
      const response = await getAllCities();
      if (response) {
        this.allCitiesObj = response;
        for (const key of Object.keys(response)) {
          const provinceName = key;
          const cities = [];
          const province = { text: provinceName, value: provinceName };
          for (const val of response[key]) {
            for (const city of val.cities) {
              cities.push(city.name);
              this.cityRegionObj[city.name] = val.regionTag;
            }
          }
          this.provincesOpt.push(province);
          this.provinceCityObj[provinceName] = cities.sort((a, b) =>
            a > b ? 1 : -1
          );
        }
        this.provincesOpt = this.provincesOpt.sort((a, b) =>
          a.value > b.value ? 1 : -1
        );
        // this.displayMessage("Success", "Query Cities: Done...");
        return true;
      } else {
        this.displayMessage("warning", `queryCities Exception`);
      }
    },
    selectProvince() {
      this.form.city = "";
      this.citiesOpt = this.provinceCityObj[this.form.province];
    },
    async selectCity() {
      this.form.region = "";
      if (this.form.city) {
        this.form.region = this.cityRegionObj[this.form.city];
        await this.queryCutoffs();
        this.form.deliveryDateNew = "";
      }
    },

    async queryCutoffs() {
      // this.displayMessage("Success", "Getting cutoffs...");
      const region = this.form.region;
      if (!region) {
        this.displayMessage("warning", `Please select region`);
        return;
      }
      const cutoffs = await getCutoffs(region);
      if (cutoffs) {
        if (cutoffs.closedDates) {
          this.closedDays = cutoffs.closedDates.map((closeD) =>
            parseInt(closeD)
          );
        }
        this.form.regionCode = cutoffs.region;
        this.unavailableDates = cutoffs.un_available;
        this.availableDates = cutoffs.available;
        this.cutoffTime = cutoffs.cutoff_time;
        // this.displayMessage("Success", "Cutoffs done...");
        return true;
      } else {
        this.displayMessage("warning", `queryCutoffs Exception`);
      }
    },

    async queryProducts() {
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Please select delivery date`);
        return;
      }
      const deliveryDate = dayjs(this.form.deliveryDateNew);
      const weekNum = deliveryDate.format("WWYY");
      const warehouseCode = this.form.regionCode;

      this.bouquetOpt.length = 0;
      this.bouquetFlatOpt.length = 0;
      this.bouquetOpt.push({ text: "Select an option", value: "" });
      this.bouquetSizeObj = {};
      // this.displayMessage("Success", "Getting bouquets...");
      await this.queryBouquets(warehouseCode, weekNum, "Flowers");
      // this.displayMessage("Success", "Getting Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Bundle");
      // this.displayMessage("Success", "Getting Curated_Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Curated_Bundle");
      // this.displayMessage("Success", "Getting vases...");
      await this.queryVases(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting addons...");
      await this.queryAddons(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting cards...");
      await this.queryCard(warehouseCode, weekNum);
      if (this.form.postalCode && this.form.postalCode.length >= 6) {
        await this.queryDeliveryWindow();
      }
      // this.displayMessage("Success", "Query Products Done...");
    },

    async queryDeliveryWindow() {
      if (!this.form.postalCode) {
        this.displayMessage("warning", `Please input postal code`);
        return;
      }
      if (this.form.postalCode.length < 6) {
        this.displayMessage("warning", `Postal code is incorrect`);
        return;
      }
      if (!this.form.lang) {
        this.displayMessage("warning", `Please select Lang`);
        return;
      }
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Please select delivery date`);
        return;
      }

      // this.displayMessage("Success", "Getting Delivery window...");
      this.form.postalCode = this.form.postalCode.toUpperCase();
      await this.queryDeliveryRates(this.form.postalCode, this.form.deliveryDateNew, this.form.lang);
      // this.displayMessage("Success", "Query Delivery window: Done...");
    },
    async queryBouquets(warehouseCode, weekNum, productType) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const data = {"queryId": "products_filter", "productType": productType, "region": warehouseCode, "dateCode": weekNum};
      const bouquetList = await this.axios.post(inventoryUrl, data).then(
        res => {
          console.log("Inventory response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryBouquets Exception: ${err}`);
      });
      if (bouquetList) {
        const bouquetOpt = [];
        for (const bouquet of bouquetList) {
          if (bouquet.node.availableForSale) {
            bouquetOpt.push({ text: bouquet.node.title, value: bouquet.node.title });
            this.bouquetFlatOpt.push({ text: bouquet.node.title, value: bouquet.node.title });
            const variantOpt = [];
            variantOpt.push({ text: "Select an option", value: "" });
            console.log("bouquet.node.variants.edges:", bouquet.node.variants.edges);
            for (const variant of bouquet.node.variants.edges) {
              variantOpt.push({ text: `${variant.node.title} - $${variant.node.price.amount} (${variant.node.quantityAvailable})`,
                                value: variant.node.title, id: variant.node.id, sku: variant.node.sku,
                                price: variant.node.price.amount });
            }
            this.bouquetSizeObj[bouquet.node.title] = variantOpt;
            console.log("variantOpt:", variantOpt, bouquet.node.title);
          }
        }
        this.bouquetOpt.push({
          label: productType,
          options: bouquetOpt
        });
      } else {
        console.log("bouquetList is null");
      }
    },
    async queryVases(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const vaseData = {"queryId": "products_with_product_type_filter", "productType": "Vase", "region": warehouseCode, "dateCode": weekNum};
      const vaseList = await this.axios.post(inventoryUrl, vaseData).then(
        res => {
          console.log("query vase response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (vaseList) {
        this.vaseOpt.length = 0;
        this.vaseOpt.push({ text: "Select an option", value: "" });
        for (const vase of vaseList) {
          console.log("vase:", vase);
          if (vase.node.availableForSale) {
            const variant = vase.node.variants.edges[0].node;
            this.vaseOpt.push({ text: `${vase.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: vase.node.title, id: variant.id, price: variant.price.amount });
          } else {
            console.log("vase is not availableForSale:", vase.node.availableForSale);
          }
        }
      } else {
        console.error("vaseList is empty:", vaseList);
      }
    },
    async queryAddons(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const addonsData = {"queryId": "products_filter", "productType": "Gift-adds", "region": warehouseCode, "dateCode": weekNum};
      const addonsList = await this.axios.post(inventoryUrl, addonsData).then(
        res => {
          console.log("query addons response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (addonsList) {
        this.addonsOpt.length = 0;
        this.addonsInventoryOpt.length = 0;
        for (const addons of addonsList) {
          const variant = addons.node.variants?.edges[0]?.node;
          if (addons.node.availableForSale && variant.quantityAvailable > 0) {
            this.addonsInventoryOpt.push({ text: `${addons.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                          value: {value: addons.node.title, id: variant.id, price: variant.price.amount,
                                                  quantityAvailable: variant.quantityAvailable} });
          }
        }
      }
    },
    async queryCard(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const cardData = {"queryId": "products_with_product_type_filter", "productType": "Card", "region": warehouseCode, "dateCode": weekNum};
      const cardList = await this.axios.post(inventoryUrl, cardData).then(
        res => {
          console.log("query card response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (cardList) {
        this.cardOpt.length = 0;
        console.log("cardOpt:", this.cardOpt);
        this.cardOpt.push({ text: "Select an option", value: "" });
        for (const card of cardList) {
          const tags = card.node.tags;
          if (card.node.availableForSale &&
              (tags.includes(weekNum) || tags.includes("card-ongoing")) &&
              !tags.includes("not-" + weekNum)) {
            const variant = card.node.variants.edges[0].node;
            this.cardOpt.push({ text: `${card.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: card.node.title, id: variant.id, price: variant.price.amount, quantityAvailable: variant.quantityAvailable });
          }
        }
      }
    },
    async queryDeliveryRates(postal, deliveryDate, lang) {
      const postalNew = postal.replace(/\s+/g, "");
      const rateUrl = `https://shop.callia.com/rates/delivery/postalcode/${postalNew}/date/${deliveryDate}?lang=${lang}`;
      const rateList = await this.axios.get(rateUrl).then(
        res => {
          console.log("queryDeliveryRates response:", res.data);
          return JSON.parse(JSON.parse(res.data.body)).sort(
            (a, b) => {
              return Number(a.zone_rate.replace("$", "")) - Number(b.zone_rate.replace("$", ""))
            }
          );
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryDeliveryRates Exception: ${err}`);
      });
      if (rateList) {
        this.shippingMethodsOpt.length = 0;
        this.shippingMethodsOpt.push({ text: "Select an option", value: "" });
        for (const rate of rateList) {
          if (rate.status === true) {
            this.shippingMethodsOpt.push({ text: `${rate.display_text} - ${rate.zone_rate}`, value: rate.display_text, price: rate.zone_rate });
          }
        }
      }
    },
    selectBouquet() {
      this.form.bouquetSize = "";
      this.bouquetSizesOpt = this.bouquetSizeObj[this.form.bouquetName];
    },
    selectAddon() {
      console.log("addons:", this.addons);
      if (this.addons) {
        const orderAddonsObj = this.addonsOpt.find(item => item.value.value === this.addons.value);
        if (orderAddonsObj) {
          if (orderAddonsObj.value.quantity < this.addons.quantityAvailable) {
            orderAddonsObj.value.quantity++;
            orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                  (x${orderAddonsObj.value.quantity})`;
          }
        } else {
          this.addonsOpt.push({ text: `${this.addons.value} - $${this.addons.price} (x1)`,
                                value: {value: this.addons.value, id: this.addons.id,
                                        price: this.addons.price, quantity: 1,
                                        quantityAvailable: this.addons.quantityAvailable,
                                        } });
        }
      } else {
        this.displayMessage("warning", `Please select Addons to add`);
      }
    },
    unselectAddon() {
      console.log("this.form.addons:", this.form.addons);
      if (this.form.addons) {
        const orderAddonsObj = this.addonsOpt.find(item => item.value.value === this.form.addons.value);
        if (this.form.addons.quantity > 1) {
          this.form.addons.quantity--;
          orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                (x${orderAddonsObj.value.quantity})`;
        } else {
          this.addonsOpt = this.addonsOpt.filter(item => item.value.value !== this.form.addons.value);
        }
      } else {
        this.displayMessage("warning", `Please select Addons to remove`);
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      const dateSelected = dayjs.tz(ymd,  'America/Winnipeg');
      const currentTimeStamp = dayjs().tz('America/Winnipeg');
      const today = currentTimeStamp.startOf('day');
      const formatedDate = dateSelected.format("YYYY-MM-DD");
      if (dateSelected.isBefore(today)) {
        return true;
      }
      if (this.availableDates && this.availableDates.length > 0) {
        for (const availableDates of this.availableDates) {
          if (availableDates.date.includes(formatedDate)) {
            const cutOffTimeStamp = dateSelected
              .subtract(parseInt(availableDates.cutoff_effect), "d")
              .startOf("d")
              .add(availableDates.cutoff_time.split(/:|\./)[0], "h")
              .add(availableDates.cutoff_time.split(/:|\./)[1], "m");

            if (cutOffTimeStamp.isAfter(currentTimeStamp)) {
              return false;
            }
          }
        }
      }
      if (this.unavailableDates.includes(formatedDate)) {
        return true;
      }
      if (this.closedDays.includes(weekday)) {
        return true;
      }

      const cutoff = this.cutoffTime
        .find((df) => +df.day === dateSelected.day());
      //Calculate the cutoff time (d - cutoff_effect)
      if (cutoff) {
        const cutOffTimeStamp = dateSelected
          .subtract(parseInt(cutoff.cutoff_effect), "d")
          .startOf("d")
          .add(cutoff.cutoff_time.split(/:|\./)[0], "h")
          .add(cutoff.cutoff_time.split(/:|\./)[1], "m");

        return cutOffTimeStamp.isBefore(currentTimeStamp);
      } else {
        console.log("no cutoff", dateSelected.day());
        return true;
      }
    },
    async createOrder() {
      this.result = "";
      if (!this.checkItems()) {
        return;
      }
      const deliveryDate = dayjs.tz(this.form.deliveryDateNew,  'America/Winnipeg');
      this.form.deliveryDate = deliveryDate.format("MM/DD/YYYY");
      const weekNum = deliveryDate.format("WWYY");
      const warehouseCode = this.form.regionCode;
      const params = {};
      params.firstName = this.form.firstName;
      params.lastName = this.form.lastName;
      params.deliveryAddress = this.form.address;
      params.addressType = this.form.addressType;
      params.apartmentNumber = this.form.apartmentNumber;
      params.city = this.form.city;
      params.provinceCode = this.provinceMap[this.form.province];
      params.postalCode = this.form.postalCode;
      params.cardMessage = this.form.message;
      params.deliveryDate = this.form.deliveryDate;
      params.deliveryNotes = this.form.deliveryNotes;
      params.contactNumber = this.form.phoneNumber;
      params.contactName = this.form.cName;
      params.alertsEmail = this.form.alertsEmail;
      params.locale = this.form.lang;
      params.discountCode = this.form.discountCode;
      params.deliveryRate = this.form.deliveryRate;
      params.sender = this.order.sender;
      params.sender.email = this.order.email;
      const products = [];

      const productBouquet = {};
      productBouquet.productTitle = this.form.bouquetName;
      productBouquet.variantTitle = this.form.bouquetSize;
      productBouquet.productType = "Flowers";
      productBouquet.weekCode = weekNum;
      productBouquet.warehouse = warehouseCode;
      productBouquet.quantity = 1;
      products.push(productBouquet);

      const productCard = {};
      productCard.productTitle = this.form.cardType;
      productCard.variantTitle = "Default Title";
      productCard.productType = "Card";
      productCard.weekCode = weekNum;
      productCard.warehouse = warehouseCode;
      productCard.quantity = 1;
      products.push(productCard);

      if (this.form.vase && this.vaseOpt.find(item => item.value === this.form.vase)) {
        const product = {};
        product.productTitle = this.form.vase;
        product.variantTitle = "Default Title";
        product.productType = "Vase";
        product.weekCode = weekNum;
        product.warehouse = warehouseCode;
        product.quantity = 1;
        products.push(product);
      }

      if (this.addonsOpt && this.addonsOpt.length > 0) {
        for (const addon of this.addonsOpt) {
          if (addon && addon.value) {
            const product = {};
            product.productTitle = addon.value.value;
            product.variantTitle = "Default Title";
            product.productType = "Gift-adds";
            product.weekCode = weekNum;
            product.warehouse = warehouseCode;
            product.quantity = addon.value.quantity;
            products.push(product);
          }
        }
      }

      params.products = products;
      params.tags = [ 'REPLACEMENT_AUTOMATION', `ORIGINAL_ORDER_${this.order.orderId}` ];
      params.note = this.form.note;
      params.noteAttributes = [
        {
          name: "originalOrder",
          value: this.order.orderId,
        }
      ];
      console.log(this.form.note);
      const user = this.$store.getters.currentUser;
      params.alertsEmail = user.email;

      this.result = JSON.stringify(params, null, 2);
      this.displayMessage("Success", "Replacing order...");
      const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/orders/replace";
      await this.axios.post(url, params).then(
        res => {
          console.log("Order Replacement response:", res.data);
          this.displayMessage("Success", "Order Replacement: Done...");
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Order Replacement Exception: ${err}`);
      });
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    checkItems() {
      if (!this.order.orderId) {
        this.displayMessage("warning", `Order Name can't be empty`);
        return false;
      }
      if (!this.order.email) {
        this.displayMessage("warning", `Please input Order Name and Query Order`);
        return false;
      }
      if (!this.form.province) {
        this.displayMessage("warning", `Province can't be empty`);
        return false;
      }
      if (!this.form.city) {
        this.displayMessage("warning", `City can't be empty`);
        return false;
      }
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Delivery Date can't be empty`);
        return false;
      }
      if (!this.form.postalCode) {
        this.displayMessage("warning", `Postal Code can't be empty`);
        return false;
      }
      if (!this.form.lang) {
        this.displayMessage("warning", `Lang can't be empty`);
        return false;
      }
      if (!this.form.firstName) {
        this.displayMessage("warning", `First Name can't be empty`);
        return false;
      }
      if (!this.form.lastName) {
        this.displayMessage("warning", `Last Name can't be empty`);
        return false;
      }
      if (!this.form.cName) {
        this.displayMessage("warning", `Contact Name can't be empty`);
        return false;
      }
      if (!this.form.phoneNumber) {
        this.displayMessage("warning", `Phone Number can't be empty`);
        return false;
      }
      if (!this.form.address) {
        this.displayMessage("warning", `Address can't be empty`);
        return false;
      }
      if (!this.form.addressType) {
        this.displayMessage("warning", `Address Type can't be empty`);
        return false;
      }
      if (!this.addressTypesOpt.find(item => item.value === this.form.addressType)) {
        this.displayMessage("warning", `Address Type can't be empty`);
        return false;
      }
      if (!this.form.deliveryRate) {
        this.displayMessage("warning", `Delivery Window can't be empty`);
        return false;
      }
      if (!this.shippingMethodsOpt.find(item => item.value === this.form.deliveryRate)) {
        this.displayMessage("warning", `Delivery Window can't be empty`);
        return false;
      }
      if (!this.form.bouquetName) {
        this.displayMessage("warning", `Bouquet Name can't be empty`);
        return false;
      }
      if (!this.bouquetFlatOpt.find(item => item.value === this.form.bouquetName)) {
        this.displayMessage("warning", `Bouquet Name can't be empty`);
        return false;
      }
      if (!this.form.bouquetSize) {
        this.displayMessage("warning", `Bouquet Size can't be empty`);
        return false;
      }
      if (!this.bouquetSizesOpt.find(item => item.value === this.form.bouquetSize)) {
        this.displayMessage("warning", `Bouquet Size can't be empty`);
        return false;
      }
      if (!this.form.occasion) {
        this.displayMessage("warning", `Occasion can't be empty`);
        return false;
      }
      if (!this.form.relationship) {
        this.displayMessage("warning", `Relationship can't be empty`);
        return false;
      }
      if (!this.form.cardType) {
        this.displayMessage("warning", `Card Type can't be empty`);
        return false;
      }
      if (!this.form.region) {
        this.displayMessage("warning", `Region can't be empty`);
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.upper {
  text-transform: case;
}

#checkbox-group,
.custom-select a:hover,
a:active,
a:focus {
  outline: 0;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>
