<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <cl-header mainTopic="Add New" subTopic="Region" />
          <CLButton
          format="primary-btn"
          v-on:func="showAddModal"
          >
          <plus-icon
                  size="1.5x"
                  class="text-white"
          ></plus-icon>
          Add New
          </CLButton>
<!--          <b-button variant="dark" @click="showAddModal">-->
<!--            <plus-icon size="1.5x" class="text-success"></plus-icon>-->
<!--            Add New-->
<!--          </b-button>-->
          <add-region-popup
            ref="AddRegionModal"
            @success="refreshGrid($event)"
            @error="errorCreateRegion"
          />
          <edit-region-popup
            ref="EditRegionModal"
            :title="title"
            :update-array="updateArray"
            @success="refreshGrid($event)"
            @error="errorUpdateRegion"
          />
        </div>
      </div>
      <div class="row mt-3 mb-0">
        <region-grid
          :items="gridItems"
          :processing="processing"
          :totalRows="totalRows"
          @showEditModal="showEditRegionModal($event)"
        ></region-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import RegionGrid from "@/components/grids/region-grid";
import {CLButton, CLHeader} from "callia-ui-box";
import AddRegionModal from "@/components/region/add-region-popup";
import EditRegionModal from "@/components/region/edit-region-popup";

export default {
  components: {
    PlusIcon,
    "region-grid": RegionGrid,
    "add-region-popup": AddRegionModal,
    "edit-region-popup": EditRegionModal,
    "cl-header": CLHeader,
    CLButton
  },
  data() {
    return {
      title: "",
      updateArray: [],
      gridItems: this.$store.getters.regions,
      processing: false,
      totalRows: 0
    };
  },
  async mounted() {
    await this.getAllRegions();
  },
  methods: {
    showAddModal() {
      this.$refs.AddRegionModal.show();
    },
    showEditRegionModal(editInfo) {
      this.title = editInfo["region_name"];
      this.updateArray = editInfo;
      this.$refs.EditRegionModal.show();
    },
    async refreshGrid(message) {
      await this.getAllRegions();
      this.displayMessage("success", message);
    },
    errorCreateRegion() {
      this.displayMessage("warning", "Region saving process failed.");
    },
    errorUpdateRegion() {
      this.displayMessage("warning", "Region update process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    async getAllRegions() {
      try {
        this.items = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`
        });
        this.processing = true;
        this.$store.commit("setRegions", response.data);
        this.gridItems = this.$store.getters.regions;
        this.totalRows = this.gridItems.length;
        this.processing = false;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error fetching Regions.");
        console.log("Error: ", errorResponse);
      }
    }
  }
};
</script>

<style lang="scss">
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-width: 800px;
  width: 800px;
}
</style>