<template>
  <div class="container-fluid p-0">
    <b-alert
        :show="notification.showErrorCountDown"
        dismissible
        fade
        :variant="notification.type"
    >
      {{ notificationMessage }}
    </b-alert>
    <h3>Upgrade Automation</h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner
                  type="grow"
                  variant="info"
                  label="Loading..."
              ></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>

          <div class="row">
            <div class="col-auto">
              <p class="mt-3 mb-0">Update Auto upgrade sheet: 
                <b-button
                  type="button"
                  variant="primary"
                  class="submit-button"
                  @click="jump"
                >
                  Jump to Sheet Page
                </b-button>
              </p>
            </div>
          </div>

          <div class="form-card mt-3 mb-0 form">
            <b-form @submit="onSubmit">
              <div>

                <b-button type="submit" variant="primary"
                          class="form-submit-button submit-button adj-btn"
                >Update
                </b-button>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      user: this.$store.getters.currentUser,
      form: {
      },
      processing: false,
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: ""
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.processing = true;
      try {
        await this.axios.put(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/order/upgrade-automation",
            {
            }
        );

        this.processing = false;
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
    jump() {
      window.open("https://docs.google.com/spreadsheets/d/1mjPSHAc6jVsNLQ8AbQoQ4K0JFAp-g5P-9q3cTGTvW_Y/edit#gid=0", "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.adj-btn {
  margin-top: 30px;
}

.btn-outline-primary {
  background-color: #fff !important;
  color: $callia-green !important;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>