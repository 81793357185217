<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>

    <b-modal id="newLinkModal" v-model="showNewLinkModal">
      <template #modal-header="{}">
        <h5>Create New Ops Link</h5>
      </template>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="primary"
          type="submit"
          class="form-submit-button submit-button adj-btn"
          @click="createNewLink()"
          :disabled="!(formObject.name && formObject.link)"
        >
          Create
        </b-button>

        <b-button
          class="reset-button adj-btn"
          @click="
            formObject = {};
            cancel();
          "
        >
          Cancel
        </b-button>
      </template>

      <b-alert
        v-model="newLinkNotification.showErrorCountDown"
        dismissible
        fade
        :variant="newLinkNotification.type"
      >
        {{ notificationMessage }}
      </b-alert>

      <b-form @reset="formObject = {}">
        <b-form-group
          id="newNameGroup"
          label="Link Name:"
          label-for="name-input"
        >
          <b-form-input
            id="name-input"
            v-model="formObject.name"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="newLinkGroup" label="Link:" label-for="link-input">
          <b-form-input
            id="link-input"
            v-model="formObject.link"
            placeholder="Enter link"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-row>
      <b-col>
        <b-button
          variant="primary"
          class="submit-button"
          size="sm"
          justify="end"
          v-on:click="showNewLinkModal = true"
        >
          Create New Ops Link
        </b-button>
      </b-col>
    </b-row>

    <br />
    <div class="cardContainer">
      <b-card class="cardContent" v-for="item in items" :key="item.name">
        <b-icon
          icon="pencil-square"
          font-scale="1"
          class="icon"
          @click="editOps(item)"
        ></b-icon>
        <b-card-title class="custom-class">{{ item.name }}</b-card-title>
        <ul>
          <li>
            <a :href="'//' + item.link" target="_blank">{{ item.link }}</a>
          </li>
        </ul>
      </b-card>
    </div>
    <editOpsLink ref="opsedit" v-bind:selectedlink="selectedlink"/>
  </b-overlay>
</template>
<script>
import editOpsLink from "../opslinksedit/opslinks-edit-modal.vue";

export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Link Name"
        },

        {
          key: "link",
          sortable: true,
          label: "Link"
        }
      ],
      selectedlink: [],
      items: [],
      totalRows: 1,
      formObject: {},
      newLinkNotification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      showNewLinkModal: false,
      notificationMessage: ""
    };
  },
  async created() {
    await this.getAllLinks();
  },
  methods: {
    async getAllLinks() {
      this.processing = true;
      await this.axios
        .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/ops/link/")
        .then(response => {
          this.items = response.data;
          this.processing = false;
        })
        .catch(() => {
          console.log("Could not get links");
          this.displayMessage("Could not retrieve Links");
        });
    },
    createNewLink() {
      if (this.formObject.name && this.formObject.link) {
        try {
          this.axios
            .post(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/ops/link", {
              name: this.formObject.name,
              link: this.formObject.link
            })
            .then(() => {
              this.getAllLinks();
              this.showNewLinkModal = false;
              this.formObject = {};
            })
            .catch(error => {
              console.log(error.response);
              if (error.response.status === 409) {
                //409's are attempted duplicate POSTS
                this.displayMessage("Please use a unique link name", "new   ");
              } else {
                this.displayMessage("Something went Wrong", "new");
              }
            });
        } catch (err) {
          console.log("Error Sending Link");
        }
      } else {
        console.log("Not enough info to create");
        this.displayMessage(
          "Please fill all fields when creating a new link",
          "new"
        );
      }
    },
    displayMessage(message, modal) {
      this.notificationMessage = message;
      if (modal === "new") {
        this.newLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      } else {
        //modal === "update
        this.updateLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      }
    },
    editOps(val) {
      this.$bvModal.show("modal-edit-opslink");
      this.selectedlink = val;
      console.log(val);
    }
  },
  components: {
    editOpsLink
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.cardContent {
  text-align: center;
  background-color: #fdfdfd;
  color: #6b747d;
}

.cardContent ul {
  list-style: none;
  text-align: center;
  padding-right: 10px;
}
.icon {
  position: relative;
  left: 50%;
}

.icon:hover {
  cursor: pointer;
}

.cardContainer a:hover {
  color: #44494a;
}
.custom-class {
  margin: auto 0;
  color: #44494a;
}

@media (min-width: 600px) {
  .cardContainer {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 900px) {
    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
