export enum CustomAttributs {
  CARD_TYPE = "Card Type",
  MESSAGE = "Message",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  CONTACT_NAME = "Contact Name",
  ADDRESS_TYPE = "Address Type",
  ADDRESS = "Address",
  PROVINCE = "Province",
  CITY = "City",
  UNIT_NUMBER = "Apartment Number",
  POSTAL_CODE = "Postal Code",
  _LONGITUDE = "_Longitude",
  _LATITUDE = "_Latitude",
  _DELIVERY_METHOD = "_Delivery-Method",
  SHIPPING_METHOD = "Shipping-Method",
  _SHIPPING_COST = "_Shipping-Cost",
  DELIVERY_NOTES = "Delivery Notes",
  BUZZER_CODE = "Buzzer Code",
  RECIPIENT_PHONE_NUMBER = "Recipient Phone Number",
  _EMAIL_LANGUAGE = "_Email language",
  _CONSENT = "_consent",
  _TEXTLANGUAGE = "_text-language",
  _VASE = "_vase",
  _ADDONS = "_addons",
  _DONATION = "_donation",
  _BOLDVARIANTNAMES = "_boldVariantNames",
  _BOLDVARIANTIDS = "_boldVariantIds",
  _BOLDPRODUCTIDS = "_boldProductIds",
  _BOLDVARIANTPRICES = "_boldVariantPrices",
  _BOLDBUILDERID = "_boldBuilderId",
  DELIVERY_DATE = "Delivery Date",
  _DELIVERY_REGION = "_Delivery Region",
  _REGION = "_Region",
  _REGION_CODE = "_Region Code",
  _DELIVERY_CITY = "_Delivery City",
  _DATADOG_SESSION_ID = "_Datadog session id",
  _DIAGNOSTICS_OS = "_Diagnostics OS",
  _DIAGNOSTICS_BROWSER = "_Diagnostics Browser",
  _DIAGNOSTICS_MOBILE = "_Diagnostics Mobile",
  _DIAGNOSTICS_SCREEN = "_Diagnostics Screen",
  _DIAGNOSTICS_UA = "_Diagnostics UA",
  DELIVERY_TYPE = "Delivery Type",
  DELIVERY_TO = "Delivery to",
  _WEBSITE = "_website",
  _WEBSITE_VERSION = "_website_version",
  SKU = "SKU",
  _ITEM = "_item",
  _TEMPERATURE = "_Temperature",
  _WEATHER = "_Weather",
  _COLLECTIONID = "_collection id",
  _PULL_DATE = "_Pull Date",
  END_DELIVERY_DATE = "End Delivery Date",
  SUBSCRIBE_ADDON = "Subscribe Addon",
  _RELATIONSHIP = "_Relationship",
  _OCCASION = "_Occasion",
  _UTM = "_utm"
}
