/* eslint-disable camelcase */
<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <div id="home" class="home">
      <left-menu-bar></left-menu-bar>
      <div class="main">
        <top-menu-bar></top-menu-bar>
        <main class="content">
          <router-view></router-view>
        </main>
        <Footer></Footer>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { Component, Vue } from "vue-property-decorator";
import LeftMenuBar from "@/components/menus/left-menu.vue";
import TopMenuBar from "@/components/menus/top-menu.vue";
import Footer from "@/components/footer.vue";

export default {
  components: {
    LeftMenuBar,
    TopMenuBar,
    Footer
  },
  data() {
    return {
      showOverlay: true
    };
  },
  async created() {
    this.showOverlay = false;
  }
};
</script>
<style lang="scss">
.home {
  align-items: stretch;
  display: flex;
  width: 100%;
  background: #222e3c;
}
</style>
