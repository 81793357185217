<template>
  <b-alert
    variant="danger"
    v-model="show"
    dismissible
    fade
    @dismissed="changeValue"
  >
    {{ message }}
  </b-alert>
</template>

<script>
  export default {
    name: 'ErrorAlert',
    props: {
      message: {
        type: String,
    	},
    	show: {
        type: Boolean,
    	}
    },
    methods: {
      changeValue() {
        this.$emit('hide-alert');
      }
    }
  }
</script>
