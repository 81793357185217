<template>
  <div class="container-fluid p-0 h-100">
    <h3>OrderPulls <strong>config</strong></h3>
    <order-pulls-config-grid></order-pulls-config-grid>
  </div>
</template>

<script>
import OrderPullsConfig from "@/components/grids/order-pulls-config-grid.vue";
export default {
  components: {
    "order-pulls-config-grid": OrderPullsConfig
  }
};
</script>

<style></style>
