<template>
  <div>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Rules</strong> configuration {{ title }}</h3>
          <p class="mt-4 mb-0">All fields are required</p>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-8 col-xl-8 col-12">
          <div class="form-card">
            <b-form>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 rule-label">Rule name</label>
                    <b-form-input
                      :plaintext="nameReadonly"
                      v-model="form.ruleName"
                      type="text"
                      placeholder=""
                      class="input-height mt-1"
                    ></b-form-input>
                    <div class="input-box">
                      <p v-show="checkRepName" class="input-alert mb-0 mt-0">
                        Special characters are not allowed
                      </p>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 rule-label">Status</label>
                    <b-form-select
                      v-model="form.status"
                      :options="statusOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <b-form-group>
                <label class="mt-0 rule-label">Description</label>
                <b-form-input
                  v-model="form.description"
                  type="text"
                  placeholder="description"
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <aws-cron v-model="form.schedule" />
              <b-form-group>
                <label class="mt-0 rule-label"
                  >Event schedule(sample: cron(0 18 ? * * *))</label
                >
                <b-form-input
                  v-model="form.schedule"
                  type="text"
                  placeholder=""
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-0 rule-label">targets input</label>
                <b-form-select
                  v-model="form.input0"
                  :options="inputList"
                  class="input-height mt-1"
                ></b-form-select>
              </b-form-group>

              <b-button
                type="button"
                variant="primary"
                class="submit-button"
                v-if="enableButton"
                @click="submit"
              >
                Submit rule
              </b-button>
              <b-button type="button" class="disabled-button" disabled v-else>
                Submit rule
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";
import AWSCron from "@/components/report/aws-cron.vue";
import { uuid } from "vue-uuid";

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
    "aws-cron": AWSCron,
  },
  data() {
    return {
      title: "",
      nameReadonly: false,
      inputList: [{ text: "empty", value: "" }],
      form: {
        ruleName: "EXP_",
        status: "ENABLED",
        description: "",
        schedule: "",
        eventBusName: "default",
        id0: "",
        arn0: "",
        input0: "",
      },

      alert: {
        error: false,
        message: "",
      },

      statusOpt: [
        { text: "Enabled", value: "ENABLED" },
        { text: "Disabled", value: "DISABLED" },
      ],
    };
  },
  created() {
    if (this.$route.params.editedItem) {
      this.form = this.$route.params.editedItem;
      this.form.schedule = this.form.schedule
        .replace(/cron[(]/g, "")
        .replace(/[)]/g, "");
      this.nameReadonly = true;
      this.title = "create";
    } else {
      this.title = "update";
    }

    this.axios
      .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/config", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.idToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          for (const element of response.data) {
            const item = {
              text: element.reportName,
              value: '{"uuid":"' + element.uuid + '"}',
            };
            this.inputList.push(item);
          }
        }
      });
  },
  methods: {
    async submit() {
      if (this.form.input0) {
        if (!this.form.id0) {
          this.form.id0 = uuid.v4();
        }
      }
      if (!this.form.ruleName.toUpperCase().startsWith("EXP_")) {
        this.form.ruleName = "EXP_" + this.form.ruleName;
      }
      this.axios
        .post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/rules",
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.idToken,
            },
          }
        )
        .then((response) => {
          this.alert = {
            message: "rule submitted successfully",
            error: false,
            success: true,
          };
        });
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    },
  },
  computed: {
    checkRepName() {
      if (this.form.ruleName && !/^[0-9A-Za-z_ ]+$/.test(this.form.ruleName)) {
        return true;
      }
      return false;
    },
    enableButton() {
      if (this.form.ruleName && /^[0-9A-Za-z_ ]+$/.test(this.form.ruleName)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "form.ruleName": {
      handler: function () {
        // this.form.ruleName = this.form.ruleName.replace(" ", "_");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.card {
  width: 100%;
  border: none;
}

.rule-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.upper {
  text-transform: uppercase;
}

#checkbox-group,
.custom-select a:hover,
a:active,
a:focus {
  outline: 0;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>
