<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>

    <b-modal id="newLinkModal" v-model="showNewLinkModal">
      <template #modal-header="{}">
        <h5>Create New Ops Credential</h5>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          type="submit"
          class="form-submit-button submit-button adj-btn"
          @click="createNewLink()"
          :disabled="
            !(
              formObject.name &&
              formObject.link &&
              formObject.username &&
              formObject.password
            )
          "
        >
          Create
        </b-button>

        <b-button
          class="reset-button adj-btn"
          @click="
            formObject = {};
            cancel();
          "
        >
          Cancel
        </b-button>
      </template>

      <b-alert
        v-model="newLinkNotification.showErrorCountDown"
        dismissible
        fade
        :variant="newLinkNotification.type"
      >
        {{ notificationMessage }}
      </b-alert>

      <b-form @reset="formObject = {}">
        <b-form-group
          id="newNameGroup"
          label="Link Name:"
          label-for="name-input"
        >
          <b-form-input
            id="name-input"
            v-model="formObject.name"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="newLinkGroup" label="Link:" label-for="link-input">
          <b-form-input
            id="link-input"
            v-model="formObject.link"
            placeholder="Enter link"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="newUsernameGroup"
          label="Username:"
          label-for="username-input"
        >
          <b-form-input
            id="username-input"
            v-model="formObject.username"
            placeholder="Enter username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="newPasswordGroup"
          label="Password:"
          label-for="password-input"
        >
          <b-form-input
            id="password-input"
            v-model="formObject.password"
            placeholder="Enter password"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal id="updateLink" v-model="showUpdateModal">
      <template #modal-header="{}">
        <h5>Update Credential: {{ formObject.name }}</h5>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          class="submit-button"
          @click="updateLink(formObject)"
          :disabled="
            !(formObject.link && formObject.username && formObject.password)
          "
        >
          Update
        </b-button>

        <b-button
          class="reset-button"
          @click="
            formObject = {};
            cancel();
          "
        >
          Cancel
        </b-button>
      </template>

      <b-alert
        v-model="updateLinkNotification.showErrorCountDown"
        dismissible
        fade
        :variant="updateLinkNotification.type"
      >
        {{ notificationMessage }}
      </b-alert>

      <b-form @reset="formObject = {}">
        <b-form-group id="updateLinkGroup" label="Link:" label-for="link-input">
          <b-form-input
            id="link-input"
            v-model="formObject.link"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="updateUsernameGroup"
          label="Username:"
          label-for="username-input"
        >
          <b-form-input
            id="username-input"
            v-model="formObject.username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="updatePasswordGroup"
          label="Password:"
          label-for="password-input"
        >
          <b-form-input
            id="password-input"
            v-model="formObject.password"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>

            <b-input-group-append>
              <b-icon
                icon="search"
                :disabled="!filter"
                @click="filter = ''"
                class="icon"
              ></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col align="end">
        <b-button
          variant="primary"
          class="submit-button"
          size="sm"
          justify="end"
          v-on:click="showNewLinkModal = true"
        >
          Create New Ops Credential
        </b-button>
      </b-col>
    </b-row>
    <b-col lg="6" class="my-1">
      <b-form-group
        v-model="sortDirection"
        label="Filter On"
        description="Leave all unchecked to filter on all data"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox-group
          v-model="filterOn"
          :aria-describedby="ariaDescribedby"
          class="mt-1"
        >
          <b-form-checkbox value="name">Link Name</b-form-checkbox>
          <b-form-checkbox value="link">Link</b-form-checkbox>
          <b-form-checkbox value="username">Username</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>

    <b-table
      hover
      :items="items"
      :fields="fields"
      :filter-included-fields="filterOn"
      :filter="filter"
      @filtered="onFiltered"
      small
    >
      <template #cell(link)="data">
        <b-button variant="link" target="_blank" :href="'//' + data.item.link">
          {{ data.item.link }}
        </b-button>
      </template>
      <template #cell(password)="data">
        <b-col class="ml-3 w-100">
          <div class="password" :hidden="data.item.encoded">
            {{ data.item.password }}
          </div>
        </b-col>
        <b-col class="show-button-container">
          <b-button
            variant="primary"
            size="sm"
            class="show-button"
            v-on:click="togglePassword(data.item)"
            >{{ buttonText[data.item.encoded] }}</b-button
          >
        </b-col>
      </template>
      <template #cell(update)="data">
        <b-button
          variant="primary"
          class="update-button"
          size="sm"
          v-on:click="
            formObject = data.item;
            formObject.password = displayPassword(data.item);
          "
          v-b-modal.updateLink
        >
          Update
        </b-button>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
import atob from "atob"; //decode passwords
import btoa from "btoa"; //encode passwords
export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Link Name"
        },

        {
          key: "link",
          sortable: true,
          label: "Link"
        },
        {
          key: "username",
          sortable: true,
          label: "Username"
        },
        {
          key: "password",
          sortable: true,
          label: "Password"
        },
        {
          key: "update",
          sortable: true,
          label: "Update"
        }
      ],
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc",
      formObject: {},
      newLinkNotification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      updateLinkNotification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      showNewLinkModal: false,
      showUpdateModal: false,
      notificationMessage: "",
      buttonText: { true: "Show", false: "Hide" }
    };
  },
  async created() {
    await this.getAllLinks();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    async getAllLinks() {
      this.processing = true;

      await this.axios
        .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/ops/creds/")
        .then(response => {
          this.items = response.data;
          this.processing = false;
        })
        .catch(() => {
          console.log("Could not get links");
          this.displayMessage("Could not retrieve Links");
        });
    },
    encodePassword(password) {
      //turns readable passwords into coded messages
      return btoa(password);
    },
    decodePassword(password) {
      //turns coded passwords into readable text
      return atob(password);
    },
    togglePassword(item) {
      if (item.encoded) {
        item.password = this.decodePassword(item.password);
      } else {
        item.password = this.encodePassword(item.password);
      }
      item.encoded = !item.encoded;
    },
    displayPassword(item) {
      if (item.encoded) {
        item.encoded = !item.encoded;
        return this.decodePassword(item.password);
      } else {
        return item.password;
      }
    },
    createNewLink() {
      if (
        this.formObject.name &&
        this.formObject.link &&
        this.formObject.username &&
        this.formObject.password
      ) {
        try {
          this.axios
            .post(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/ops/creds/", {
              name: this.formObject.name,
              link: this.formObject.link,
              username: this.formObject.username,
              password: this.encodePassword(this.formObject.password)
            })
            .then(() => {
              this.getAllLinks();
              this.showNewLinkModal = false;
              this.formObject = {};
            })
            .catch(error => {
              console.log(error.response);
              if (error.response.status === 409) {
                //409's are attempted duplicate POSTS
                this.displayMessage("Please use a unique link name", "new");
              } else {
                this.displayMessage("Something went Wrong", "new");
              }
            });
        } catch (err) {
          console.log("Error Sending Link");
        }
      } else {
        console.log("Not enough info to create");
        this.displayMessage(
          "Please fill all fields when creating a new link",
          "new"
        );
      }
    },
    updateLink(formObject) {
      if (formObject.link && formObject.username && formObject.password) {
        try {
          this.axios
            .put(
              process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
                "/ops/creds/" +
                this.formObject.name,
              {
                name: this.formObject.name,
                link: this.formObject.link,
                username: this.formObject.username,
                password: this.encodePassword(this.formObject.password),
                id: this.formObject.id
              },
              {
                pathParameters: this.formObject.name
              }
            )
            .then(() => {
              this.getAllLinks();
              this.showUpdateModal = false;
              this.formObject = {};
            });
        } catch (err) {
          console.log("Error Updating Cred");
          this.displayMessage("Something Went Wrong", "update");
        }
      } else {
        console.log("Not enough info to update");
        this.displayMessage(
          "Please fill all fields when updating a link",
          "update"
        );
      }
    },
    displayMessage(message, modal) {
      this.notificationMessage = message;
      if (modal === "new") {
        this.newLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      } else {
        //modal === "update
        this.updateLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
@import "@/assets/scss/ops-creds-grid.scss";
</style>
