<template>
  <div>
    <a
      class="nav-icon dropdown-toggle d-inline-block d-sm-none"
      href="#"
      data-toggle="dropdown"
    >
      <settings-icon size="1.5x" class="align-middle"></settings-icon>
    </a>

    <a
      class="nav-link dropdown-toggle d-none d-sm-inline-block"
      href="#"
      data-toggle="dropdown"
    >
      <span class="text-dark">{{ user.name }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <a class="dropdown-item" href="#">
        <user-icon size="1.0x" class="align-middle mr-1"></user-icon>Profile
      </a>
      <div class="dropdown-divider"></div>
      <!-- <a class="dropdown-item" href="#">
        <settings-icon size="1.0x" class="align-middle mr-1"></settings-icon>
        Settings & Privacy
      </a>
      <a class="dropdown-item" href="#">
        <help-circle-icon
          size="1.0x"
          class="align-middle mr-1"
        ></help-circle-icon>
        Help Center
      </a> -->
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" @click="logout()">Log out</a>
    </div>
  </div>
</template>

<script>
import { SettingsIcon, UserIcon, HelpCircleIcon } from "vue-feather-icons";
export default {
  components: {
    SettingsIcon,
    UserIcon
    // HelpCircleIcon
  },
  data() {
    return {
      user: {}
    };
  },
  async created() {
    await this.$store.dispatch("getCurrentuser");
    this.user = this.$store.getters.currentUser;
  },
  methods: {
    logout: async function() {
      await this.$store.dispatch("logout");
      await this.$auth.signOut();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/navbar.scss";
@import "@/assets/scss/avatar.scss";
@import "@/assets/scss/dropdown.scss";
</style>
