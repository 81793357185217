<template>
  <b-modal
      id="modal-add-zone"
      ref="modal"
      title="Add New Zone"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-variant="primary primary-btn"
      ok-title="Add"
      cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="zone"
          label-for="zone"
          invalid-feedback="Zone is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="code"
            v-model="zoneName"
            :state="validateZoneName"
            @keyup="keyupValidationZoneName"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label="Region"
          label-for="region"
          invalid-feedback="Choose a Region"
      >
        <v-select
            id="region"
            :options="regionOptions"
            placeholder="choose..."
            v-model="regionModel"
            :clearable="true"
            :class="validateRegion"
            @input="regionSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!regionModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  props: ["zoneOptions", "townOptions", "provinceOptions", "regionOptions"],
  name: "add-region-popup",
  data() {
    return {
      zoneName: "",
      validateZoneName: null,
      validateRegion: "",
      regionModel: "",
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  methods: {
    clearRegionCMB() {
      this.validateRegion = "";
      this.regionModel = "";
    },
    show() {
      this.$refs.modal.show();
    },
    regionSelectedValue(event) {
      if (event !== null) {
        this.validateRegion = "";
      } else {
        this.validateRegion = "is-invalid";
      }
      this.$emit('changeRegion', event.value)
    },
    validateRegionCMB() {
      if (this.regionModel !== null) {
        if (this.regionModel.value == null) {
          this.validateRegion = "is-invalid";
        } else {
          this.validateRegion = "";
        }
      } else {
        this.validateRegion = "is-invalid";
      }
    },
    keyupValidationZoneName() {
      if (this.zoneName !== "") {
        this.validateZoneName = true;
      } else {
        this.validateZoneName = false;
      }
    },
    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      //validate code
      if (this.$refs.form.code.checkValidity()) {
        this.validateZoneName = true;
      } else {
        this.validateZoneName = false;
      }
      this.validateRegionCMB();
      return fullFormValidity;
    },
    resetModal() {
      this.validateRegion = "";
      this.validateZoneName = "";
      this.zoneName = "";
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const zoneArray = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          zone_name: this.zoneName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          region_id: this.regionModel.value
        };

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: zoneArray
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-zone");
        });
        this.$emit("success", response.data['success']);
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>