<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Label Processes" subTopic="Today"/>
        </div>
      </div>
      <div class="row mt-3 mb-0">
        <exporter-process-grid></exporter-process-grid>
      </div>
    </div>
  </div>
</template>
<script>
import exporterProcesses from "@/components/grids/exporter-process-grid"
import {CLHeader} from 'callia-ui-box'

export default {
  components: {
    "exporter-process-grid": exporterProcesses,
    CLHeader,
  }
}
</script>