<template>
  <div class="container-fluid p-0 h-100">
    <h3>Rules List</h3>
    <order-rules-grid></order-rules-grid>
  </div>
</template>

<script>
import OrderRulesConfig from "@/components/grids/order-rules-grid.vue";
export default {
  components: {
    "order-rules-grid": OrderRulesConfig,
  },
};
</script>

<style></style>