<template>
  <div>
    <h3><strong>Create Bulk Greet Order</strong></h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner
                type="grow"
                variant="info"
                label="Loading..."
              ></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>
          <div class="card">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label"
                    >Email <strong class="required-star">*</strong></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="email"
                    type="text"
                    placeholder="Email"
                    required
                  ></b-form-input>
                  <label class="form-label pt-3"
                    >Tag <strong class="required-star">*</strong></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="tag"
                    type="text"
                    placeholder="Tag"
                    required
                  ></b-form-input>
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="form-submit-button submit-button"
                  >Submit</b-button
                >
                &nbsp;
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label class="form-label">
            <b>Result: </b><br /><br />
            <p>
              Date of order creation:
              <strong class="required-star text-success">{{
                result.dateCreated
              }}</strong>
            </p>
            <p>
              Player ID:
              <strong class="required-star text-success">{{
                result.playerId
              }}</strong>
            </p>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      tag: "",
      result: {
        dateCreated: "",
        playerId: ""
      },
      show: true,
      processing: false
    };
  },
  methods: {
    async onSubmit(event) {
      this.resetResult();
      this.processing = true;
      event.preventDefault();
      await this.axios
        .post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/video/group/order",
          {
            email: this.email.trim(),
            tag: this.tag.trim()
          },
          {
            headers: {
              Authorization: "Bearer " +this.$store.getters.idToken
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
            this.$emit("displayAlert", {
              message: response.data.message,
              isError: false
            });
            this.result.playerId = response.data.playerId;
            this.result.dateCreated = response.data.creationDate;
          }
        })
        .catch(error => {
          console.log(error.response.data.message);
          this.$emit("displayAlert", {
            message: error.response.data.message,
            isError: true
          });
          this.processing = false;
        });
      this.processing = false;
      this.onReset();
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }
      this.email = "";
      this.tag = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    resetResult() {
      this.result.dateCreated = "";
      this.result.playerId = "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
</style>
