<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Add New" subTopic="Postal Code"/>
          <CLButton
              format="primary-btn"
              v-on:func="showAddModal"
          >
            <plus-icon
                size="1.5x"
                class="text-white"
            ></plus-icon>
            Add New
          </CLButton>
          <CLButton
              class="ml-1"
              format="secondary-btn"
              :disabled="isUpdateDisabled"
              v-on:func="updatePostalCode"
          >
            Update All Changes
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon
                size="1.5x"
                class="text-dark"
            ></refresh-cw-icon>
          </b-button>
          <add-PostalCode-popup
              ref="AddPostalCodeModal"
              :zoneOptions="zoneOptions"
              :townOptions="townOptions"
              :provinceOptions="provinceOptions"
              :regionOptions="regionOptions"
              @success="refreshGrid($event)"
              @error="errorCreatePostalCode"
              @changeZone="loadTownCMBByZoneID($event)"
              @changeProvince="loadRegionCMBByProvinceID($event)"
              @changeRegion="loadZoneCMBByRegionID($event)"
          />
        </div>
      </div>
      <div class="row mb-0">
        <PostalCode-grid
            :putRequestArray="putRequestArray"
            :pgCurrentPage="pgCurrentPage"
            :pgTotalRows="pgTotalRows"
            :pgPerPage="pgPerPage"
            :processing="processing"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @updateRowArray="setUpdateRowArray($event)"
            @deletePostalCodeID="deletePostalCode($event)"
            @updateTblTownOptions="updateTblTownOptions($event)"
            @updateTblZoneOptions="updateTblZoneOptions($event)"
            @gridPaginationEvent="gridPaginationEvent($event)"
            @gridPaginationPerPageChange="updatePgPerPageChanges($event)"
            @changePaginationPageEvent="changePgCurrentPage($event)"
            @filterDataTableEvent="filterPostalCodeData($event)"
        ></PostalCode-grid>
      </div>
    </div>
  </div>
</template>
<script>
import {PlusIcon, RefreshCwIcon} from "vue-feather-icons";
import PostalCodeGrid from "@/components/grids/postalcode-grid";
import {CLHeader, CLButton} from "callia-ui-box";
import AddPostalCodeModal from "@/components/postalcode/add-postalcode-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "PostalCode-grid": PostalCodeGrid,
    "add-PostalCode-popup": AddPostalCodeModal,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      putRequestArray: [],
      rowData: [],
      processing: false,
      zoneOptions: [],
      townOptions: [],
      columnDefs: [],
      provinceOptions: [],
      regionOptions: [],
      tblZoneOptions: [],
      tblTownOptions: [],
      isUpdateDisabled: true
    };
  },
  async created() {
    this.processing = true;
    await this.getAllProvince();
    await this.loadProvinceCMB();
    await this.getAllRegion();
    await this.loadRegionCMB();
    await this.getAllZone();
    await this.loadZoneCMB();
    await this.getAllTown();
    await this.loadTownCMB();
    await this.getAllPostalCodes();
    await this.initializeEditableTable();
    this.processing = false;
    this.putRequestArray = []
  },
  methods: {
    async filterPostalCodeData(filterData) {
      //check filter data array filterOn empty stage
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {filter: filterData}
      });
      this.$store.commit("setPostalCodes", response.data.result);
      this.rowData = this.$store.getters.postalCodes
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
      this.pgCurrentPage = 1
      this.processing = false;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(perPage)
      this.getAllPostalCodes()
      this.processing = false;
    },
    gridPaginationEvent(currentPage) {
      this.getAllPostalCodesByPagination(currentPage)
    },
    async refreshPage() {
      this.isUpdateDisabled = true
      this.processing = true;
      await this.getAllProvince();
      await this.loadProvinceCMB();
      await this.getAllRegion();
      await this.loadRegionCMB();
      await this.getAllZone();
      await this.loadZoneCMB();
      await this.getAllTown();
      await this.loadTownCMB();
      this.putRequestArray = []
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(this.pgPerPage)
      await this.getAllPostalCodes()
      this.processing = false
    },
    loadRegionCMBByProvinceID(provinceID) {
      try {
        this.regionOptions = []
        this.zoneOptions = []
        this.townOptions = []
        const regionArray = this.$store.getters.regions;
        regionArray.map(qData => {
          if (qData.province_id === provinceID) {
            this.regionOptions.push({value: qData.region_id, label: qData.region_name});
          }
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    loadZoneCMBByRegionID(regionID) {
      try {
        this.zoneOptions = []
        this.townOptions = []
        const zoneArray = this.$store.getters.zone;
        zoneArray.map(qData => {
          if (qData.region_id === regionID) {
            this.zoneOptions.push({value: qData.zone_id, label: qData.zone_name});
          }
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    loadTownCMBByZoneID(zoneID) {
      try {
        this.townOptions = []
        const townArray = this.$store.getters.town;
        townArray.map(qData => {
          if (qData.zone_id === zoneID) {
            this.townOptions.push({value: qData.town_id, label: qData.town_name});
          }
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    updateTblTownOptions(tblZoneOptions) {
      this.columnDefs[1].selectOptions = tblZoneOptions
    },
    updateTblZoneOptions(tblTownOptions) {
      this.columnDefs[2].selectOptions = tblTownOptions
    },
    async loadProvinceCMB() {
      try {
        const provinceArray = this.$store.getters.province;
        provinceArray.map(qData => {
          this.provinceOptions.push({value: qData.id, label: qData.name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadTownCMB() {
      try {
        const townArray = this.$store.getters.town;
        townArray.map(qData => {
          this.townOptions.push({value: qData.town_id, label: qData.town_name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadZoneCMB() {
      try {
        const zoneArray = this.$store.getters.zone;
        zoneArray.map(qData => {
          this.zoneOptions.push({value: qData.zone_id, label: qData.zone_name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async loadRegionCMB() {
      try {
        const regionArray = this.$store.getters.regions;
        regionArray.map(qData => {
          this.regionOptions.push({value: qData.region_id, label: qData.region_name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = event => {
          return 'Delete'
        }
        this.tblZoneOptions = []
        this.tblTownOptions = []

        this.$store.getters.zone.map(qData => {
          this.tblZoneOptions.push({value: qData.zone_name, text: qData.zone_name})
        })
        this.$store.getters.town.map(qData => {
          this.tblTownOptions.push({value: qData.name, text: qData.name})
        })

        this.columnDefs.push(
            {sortable: true, filter: true, field: 'postalcode', headerName: 'Postal Code', editable: true},
            {
              sortable: true,
              filter: true,
              field: 'town_name',
              headerName: 'Town',
              editable: true,
              type: 'select',
              selectOptions: this.tblTownOptions
            },
            {
              sortable: true,
              filter: true,
              field: 'zone_name',
              headerName: 'Zone',
              editable: true,
              type: 'select',
              selectOptions: this.tblZoneOptions
            },
            {
              sortable: true,
              filter: true,
              field: 'region_name',
              headerName: 'Region',
              editable: false
            },
            {
              sortable: false,
              filter: false,
              field: 'action',
              headerName: 'Action',
              editable: false,
              type: 'link',
              formatter: displayActionButton,
              className: 'bg-secondary'
            }
        )
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async deletePostalCode(event) {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete this postal code ${event.postalcode}.`, {
          title: 'Delete Postal Code',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes/${event.postalcode_id}`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              },
              data: {
                code: event.postalcode
              }
            });
            this.displayMessage("success", response.data['success']);
            await this.getAllPostalCodes();
          }
        })
      } catch (errorResponse) {
        this.errorDeletePostalCode();
        console.error("Error: ", errorResponse);
      }
    },
    async updatePostalCode() {
      const totalUpdateRecords = this.putRequestArray.length
      if (totalUpdateRecords == 0) {
        this.displayMessage("warning", "Alert! There is no available changes for update");
      } else {
        let updateCodesString = ""
        let response
        this.putRequestArray.map(async (qData) => {
          updateCodesString += `${qData.code}, `
        })
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to update all of the changes that you have already done on postal codes: ${updateCodesString}`, {
          title: 'Update Postal Code Records',
          okVariant: 'primary primary-btn',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            try {
              if (totalUpdateRecords > 1) {
                this.putRequestArray.map(async (qData) => {
                  response = await this.axios({
                    method: "PUT",
                    url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes/${qData.id}`,
                    headers: {
                      Authorization: `Bearer ${this.$store.getters.idToken}`
                    },
                    data: {
                      'id': qData.id,
                      'code': qData.code,
                      'town_id': qData.town_id,
                      'zone_id': qData.zone_id,
                    }
                  });
                  this.displayMessage("success", response.data['success']);
                })
              } else {
                response = await this.axios({
                  method: "PUT",
                  url:
                      `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes/${this.putRequestArray[0]['id']}`,
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.idToken}`
                  },
                  data: this.putRequestArray[0]
                });
                this.displayMessage("success", response.data['success']);
              }
              await this.refreshPage();
            } catch (errorResponse) {
              this.errorUpdatePostalCode()
              console.error("Error: ", errorResponse);
            }
          }
        })
      }
    },
    async getAllTown() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/town`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setTown", response.data.result);
    },
    async getAllZone() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setZone", response.data.result);
    },
    async getAllProvince() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/province`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setProvince", response.data);
    },
    async getAllRegion() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setRegions", response.data);
    },
    async getAllPostalCodesByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {'offset': offset, 'limit': this.pgPerPage}
      });
      this.$store.commit("setPostalCodes", response.data.result);
      this.rowData = this.$store.getters.postalCodes
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
    },
    async getAllPostalCodes() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/postalcodes`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {'offset': 0, 'limit': this.pgPerPage}
      });
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
      this.$store.commit("setPostalCodes", response.data.result);
      this.rowData = this.$store.getters.postalCodes
      this.loopRowdata();
    },
    showAddModal() {
      this.$refs.AddPostalCodeModal.show();
    },
    setUpdateRowArray(event) {
      this.isUpdateDisabled = false
      this.putRequestArray = []
      event.map(qData => {
        this.putRequestArray.push({
          'id': qData.postalcode_id,
          'code': qData.postalcode,
          'town_id': qData.town_id,
          'zone_id': qData.zone_id,
        })
      })
    },
    async refreshGrid(message) {
      await this.getAllPostalCodes();
      this.displayMessage("success", message);
    },
    errorCreatePostalCode() {
      this.displayMessage("warning", "Postal code saving process failed.");
    },
    errorUpdatePostalCode() {
      this.displayMessage("warning", "Postal code update process failed.");
    },
    errorDeletePostalCode() {
      this.displayMessage("warning", "Postal code delete process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    loopRowdata() {
      this.rowData.forEach(d => {
        d['$cellStyle'] =  {
          'postalcode': { backgroundColor: '#e7eef2'},
          'town_name': { backgroundColor: '#e7eef2'},
          'zone_name': { backgroundColor: '#e7eef2' },
          'action': { justifyContent: 'center' }
        }
      })
    }
  }
};
</script>
