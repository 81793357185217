<template>
  <div class="container-fluid p-0 h-100">
    <h3><strong>Ops</strong> Credentials</h3>
    <ops-creds-grid></ops-creds-grid>
  </div>
</template>

<script>
import OpsCredsGrid from "@/components/grids/ops-creds-grid.vue";
export default {
  components: {
    "ops-creds-grid": OpsCredsGrid
  },
  data: function() {
    return {
      columns: [
        {
          sortable: true,
          filter: true,
          field: "linkName",
          headerName: "Link Name",
          editable: false
        },
        {
          sortable: true,
          filter: true,
          field: "id",
          headerName: "ID",
          editable: false
        },
        {
          sortable: true,
          filter: true,
          field: "username",
          headerName: "Username",
          type: "String",
          editable: false
        },
        {
          sortable: true,
          filter: true,
          field: "password",
          headerName: "Password",
          editable: false
        },
        {
          sortable: true,
          filter: true,
          field: "link",
          headerName: "Link",
          editable: false
        }
      ]
    };
  }
};
</script>

<style></style>
