<template>
  <b-modal
      id="modal-add-region"
      ref="modal"
      title="Add New Region"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Name"
          label-for="name"
          invalid-feedback="Region name is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="name"
            v-model="name"
            :state="validationName"
            @keyup="keyupValidationName"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label="Warehouse"
          label-for="warehouse"
          invalid-feedback="Choose a warehouse"
      >
        <v-select
            id="warehouse"
            :options="warehouseOptions"
            placeholder="choose..."
            v-model="warehouseModel"
            :clearable="true"
            :class="validateWarehouse"
            @input="warehouseSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!warehouseModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="Province"
          label-for="province"
          invalid-feedback="Choose a province"
      >
        <v-select
            id="province"
            :options="provinceOptions"
            placeholder="choose..."
            v-model="provinceModel"
            :clearable="true"
            :class="validateProvince"
            @input="provinceSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!provinceModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="Delivery Hub"
          label-for="deliveryHub"
          invalid-feedback="Choose a delivery hub"
      >
        <v-select
            id="deliveryHub"
            :options="deliveryHubOptions"
            placeholder="choose..."
            v-model="deliveryHubModel"
            :clearable="true"
            :class="validateDeliveryHub"
            @input="deliveryHubSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!deliveryHubModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  name: "add-region-popup",
  data() {
    return {
      warehouseModel: "",
      warehouseOptions: [],
      validateWarehouse: "",
      deliveryHubModel: "",
      deliveryHubOptions: [],
      validateDeliveryHub: "",
      provinceModel: "",
      provinceOptions: [],
      validateProvince: "",
      name: "",
      shortCode: "",
      validationName: null,
      validationShortCode: null,
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  async mounted() {
    await this.getProvinceDetails();
    await this.getWarehouseDetails();
    await this.getDeliveryHubDetails();
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    provinceSelectedValue(event) {
      if (event !== null) {
        this.validateProvince = "";
      } else {
        this.validateProvince = "is-invalid";
      }
    },
    deliveryHubSelectedValue(event) {
      if (event !== null) {
        this.validateDeliveryHub = "";
      } else {
        this.validateDeliveryHub = "is-invalid";
      }
    },
    warehouseSelectedValue(event) {
      if (event !== null) {
        this.validateWarehouse = "";
      } else {
        this.validateWarehouse = "is-invalid";
      }
    },
    validateProvinceCMB() {
      if (this.provinceModel !== null) {
        if (this.provinceModel.value == null) {
          this.validateProvince = "is-invalid";
        } else {
          this.validateProvince = "";
        }
      } else {
        this.validateProvince = "is-invalid";
      }
    },
    validateWarehouseCMB() {
      if (this.warehouseModel !== null) {
        if (this.warehouseModel.value == null) {
          this.validateWarehouse = "is-invalid";
        } else {
          this.validateWarehouse = "";
        }
      } else {
        this.validateWarehouse = "is-invalid";
      }

    },
    validateDeliveryHubCMB() {

      if (this.deliveryHubModel !== null) {
        if (this.deliveryHubModel.value == null) {
          this.validateDeliveryHub = "is-invalid";
        } else {
          this.validateDeliveryHub = "";
        }
      } else {
        this.validateDeliveryHub = "is-invalid";
      }
    },
    async getProvinceDetails() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/province`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const provinceArray = response.data;
        provinceArray.forEach(qData => {
          this.provinceOptions.push({value: qData.id, label: qData.name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async getDeliveryHubDetails() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/driver/region/all`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const regionArray = response.data;
        regionArray.forEach(qData => {
          this.deliveryHubOptions.push({
            value: qData.regionId,
            label: qData['regionName']
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async getWarehouseDetails() {
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/warehouse`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const provinceArray = response.data;
        provinceArray.forEach(qData => {
          const warehouseName = `${qData.name} - ${qData.sku}`;
          this.warehouseOptions.push({
            value: qData.id,
            label: warehouseName
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    keyupValidationName() {
      if (this.name !== "") {
        this.validationName = true;
      } else {
        this.validationName = false;
      }
    },
    checkFormValidity() {

      const fullFormValidity = this.$refs.form.checkValidity();

      if (this.$refs.form.name.checkValidity()) {
        this.validationName = true;
      } else {
        this.validationName = false;
      }

      this.validateProvinceCMB();
      this.validateWarehouseCMB();
      this.validateDeliveryHubCMB();

      return fullFormValidity;
    },
    resetModal() {
      this.validateWarehouse = "";
      this.validateDeliveryHub = "";
      this.validateProvince = "";
      this.name = "";
      this.shortCode = "";
      this.validationName = null;
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    eachWordCapitalized(rawText) {
      const formattedText = rawText.replace(/\s+/g, ' ').trim().split(" ");

      return formattedText.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      }).join(" ");
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const shortCode = this.warehouseModel.label.split('-')[1].trim().toUpperCase();
        const name = this.eachWordCapitalized(this.name);
        const underscoreReplacedName = name.replace(/ /g, "_");

        const regionPostArray = {
          "name": name,
          "short_code": shortCode,
          "warehouse_id": this.warehouseModel.value,
          "province_id": this.provinceModel.value,
          "delivery_hub": this.deliveryHubModel.label.trim(),
          "display_name": underscoreReplacedName,
          "tag": underscoreReplacedName
        };

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: regionPostArray
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-region");
        });
        this.$emit("success", response.data['success']);

      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>