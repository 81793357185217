<template>
  <b-overlay :show="show" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <p id="cancel-label">Please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      show: true
    };
  },
  async created() {
    await this.$auth.handleLoginRedirect();
    if (await this.$auth.isAuthenticated()) {
      const user = await this.$auth.getUser();
      this.$router.push("/secure/" + user.sub + "/dashboard");
    } else {
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.overlay-display {
  margin-top: 20%;
}
</style>
