<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Add New" subTopic="Zone"/>
          <CLButton
              format="primary-btn"
              v-on:func="showAddModal"
          >
            <plus-icon
                size="1.5x"
                class="text-white"
            ></plus-icon>
            Add New
          </CLButton>
          <CLButton
              class="ml-1"
              format="secondary-btn"
              :disabled="isUpdateDisabled"
              v-on:func="updatePostalCode"
          >
            Update All Changes
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon
                size="1.5x"
                class="text-dark"
            ></refresh-cw-icon>
          </b-button>
          <add-zone-popup
              ref="AddZoneModal"
              :regionOptions="regionOptions"
              @success="refreshGrid"
              @error="errorCreateZone"
          />
        </div>
      </div>
      <div class="row mb-0">
        <zone-grid
            :putRequestArray="putRequestArray"
            :pgCurrentPage="pgCurrentPage"
            :pgTotalRows="pgTotalRows"
            :pgPerPage="pgPerPage"
            :processing="processing"
            :rowData="rowData"
            :columnDefs="columnDefs"
            @updateRowArray="setUpdateRowArray"
            @deleteZone="deleteZone"
            @gridPaginationEvent="gridPaginationEvent"
            @gridPaginationPerPageChange="updatePgPerPageChanges"
            @changePaginationPageEvent="changePgCurrentPage"
            @filterDataTableEvent="filterZoneData"
        ></zone-grid>
      </div>
    </div>
  </div>
</template>
<script>
import {PlusIcon, RefreshCwIcon} from "vue-feather-icons";
import ZoneGrid from "@/components/grids/zone-grid";
import {CLHeader, CLButton} from "callia-ui-box";
import AddZoneModal from "@/components/zone/add-zone-popup";

export default {
  components: {
    PlusIcon,
    RefreshCwIcon,
    "zone-grid": ZoneGrid,
    "add-zone-popup": AddZoneModal,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      putRequestArray: [],
      rowData: [],
      processing: false,
      columnDefs: [],
      regionOptions: [],
      tblRegionOptions: [],
      isUpdateDisabled: true,
    };
  },
  async created() {
    this.processing = true;
    await this.getAllRegion()
    await this.loadRegionCMB()
    await this.getAllZone()
    await this.initializeEditableTable()
    this.processing = false
    this.putRequestArray = []
  },
  methods: {
    async filterZoneData(filterData) {
      //check filter data array filterOn empty stage
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {filter: filterData}
      });
      this.$store.commit("setZone", response.data.result);
      this.rowData = this.$store.getters.zone
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
      this.pgCurrentPage = 1
      this.processing = false;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(perPage)
      this.getAllZone()
      this.processing = false
    },
    gridPaginationEvent(currentPage) {
      this.getAllZoneByPagination(currentPage)
    },
    async refreshPage() {
      this.isUpdateDisabled = true
      this.processing = true;
      await this.getAllRegion()
      await this.loadRegionCMB()
      this.putRequestArray = []
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(this.pgPerPage)
      await this.getAllZone()
      this.processing = false
    },
    async loadRegionCMB() {
      try {
        const regionArray = this.$store.getters.regions;
        regionArray.map(qData => {
          this.regionOptions.push({value: qData.region_id, label: qData.region_name});
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = event => {
          return 'Delete'
        }
        this.tblRegionOptions = []

        this.$store.getters.regions.map(qData => {
          this.tblRegionOptions.push({value: qData.region_name, text: qData.region_name})
        })

        this.columnDefs.push(
            {sortable: true, filter: true, field: 'zone_name', headerName: 'Zone', editable: true, $rowStyle: { backgroundColor: 'red' }},
            {
              sortable: true,
              filter: true,
              field: 'region_name',
              headerName: 'Region',
              editable: true,
              type: 'select',
              selectOptions: this.tblRegionOptions,
              className: 'b-table-header'
            },
            {
              sortable: false,
              filter: false,
              field: 'action',
              headerName: 'Action',
              editable: false,
              type: 'link',
              formatter: displayActionButton,
              className: 'b-table-header'
            }
        )
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },
    async deleteZone(event) {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete this Zone: ${event.zone_name}.`, {
          title: 'Delete Zone',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone/${event.zone_id}`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              },
              data: {
                'zone_name': event.zone_name
              }
            });
            this.displayMessage("success", response.data['success']);
            await this.getAllZone();
          }
        })
      } catch (errorResponse) {
        this.errorDeleteZone();
        console.error("Error: ", errorResponse);
      }
    },
    async updatePostalCode() {
      const totalUpdateRecords = this.putRequestArray.length
      const [zoneIDArray, regionIDArray, zoneNameArray] = [[], [], []]

      if (totalUpdateRecords == 0) {
        this.displayMessage("warning", "Alert! There is no available changes for update");
      } else {
        let updateCodesString = ""
        let response
        this.putRequestArray.map(async (qData, qIndex) => {
          updateCodesString += `${qData.zone_name}, `
          zoneNameArray.push(qData.zone_name)
          regionIDArray.push(qData.region_id)
          zoneIDArray.push(qData.zone_id)
        })

        this.$bvModal.msgBoxConfirm(`Please confirm that you want to update all of the changes that you have already done on Zone: ${updateCodesString}`, {
          title: 'Update Zone Records',
          okVariant: 'primary primary-btn',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            response = await this.axios({
              method: "PUT",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              },
              data: {
                'zoneIDArray': zoneIDArray,
                'zoneNameArray': zoneNameArray,
                'regionIDArray': regionIDArray
              }
            });
            this.displayMessage("success", response.data['success']);

            this.refreshPage()
          }
        })
      }
    },
    async getAllRegion() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
      });
      this.$store.commit("setRegions", response.data);
    },
    async getAllZoneByPagination(currentPage) {
      const offset = this.pgPerPage * (currentPage - 1);
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {'offset': offset, 'limit': this.pgPerPage}
      });
      this.$store.commit("setZone", response.data.result);
      this.rowData = this.$store.getters.zone
      this.loopRowdata();
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
    },
    async getAllZone() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/zone`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        },
        params: {'offset': 0, 'limit': this.pgPerPage}
      });
      this.pgTotalRows = parseInt(response.data.meta[0]['totalrows'])
      this.$store.commit("setZone", response.data.result);
      this.rowData = this.$store.getters.zone
      this.loopRowdata();
    },
    showAddModal() {
      this.$refs.AddZoneModal.show();
    },
    setUpdateRowArray(event) {
      this.isUpdateDisabled = false
      this.putRequestArray = []
      event.map(qData => {
        this.putRequestArray.push({
          'zone_id': qData.zone_id,
          'zone_name': qData.zone_name,
          'region_id': qData.region_id,
          'region_name': qData.region_name,
          className: 'cell-color'
        })
      })
    },
    async refreshGrid(message) {
      await this.getAllZone();
      this.displayMessage("success", message);
    },
    errorCreateZone() {
      this.displayMessage("warning", "Zone saving process failed.");
    },
    errorUpdateZone() {
      this.displayMessage("warning", "Zone update process failed.");
    },
    errorDeleteZone() {
      this.displayMessage("warning", "Zone delete process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    loopRowdata() {
      this.rowData.forEach(d => {
        d['$cellStyle'] =  {
          'zone_name': { backgroundColor: '#e7eef2'},
          'region_name': { backgroundColor: '#e7eef2' },
          'action': { justifyContent: 'center' }
        }
      })
    }
  }
}

</script>
