<template>
  <div class="container-fluid p-0 h-100">
    <h3><strong>Cutoffs</strong> windows</h3>
    <cuttoff-time-grid></cuttoff-time-grid>
  </div>
</template>

<script>
import CuttoffTimeGrid from "@/components/grids/cutoff-time-grid.vue";
export default {
  components: {
    CuttoffTimeGrid
  }
};
</script>

<style></style>
