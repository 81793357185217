<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <b-row class="py-3 text-center">
      <b-col lg="2" class="my-1">
        <b-button @click="addItem" class="actionbutton">New Report</b-button>
      </b-col>
    </b-row>
    <b-row class="py-3 text-center">
      <b-col offset-lg="4" lg="4" sm="12">
        <b-input-group size="sm">
          <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-icon
                    :disabled="!filter"
                    @click="filter = ''"
                    icon="search"
                    class="icon"
            ></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col offset-lg="4" lg="4" sm="12">
        <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="my-1 py-1 custom-filter-bg"
        >
          <b-form-checkbox value="reportName">Report Name</b-form-checkbox>
          <b-form-checkbox value="reportType">Report Type</b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-table
      hover
      :items="items"
      :fields="fields"
      :filter-included-fields="filterOn"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      small
    >
      <template #cell(actions)="row">
        <b-button
          size="sm"
          @click="editItem(row.item)"
          class="actionbutton mr-1"
        >
          Update
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row.item)"
          class="actionbutton mr-1"
        >
          Delete
        </b-button>
        <b-button
          size="sm"
          @click="generate(row.item)"
          class="actionbutton mr-1"
        >
          Generate Report
        </b-button>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        { key: "actions", label: "Actions", tdClass: 'd-flex' },
        {
          key: "reportName",
          sortable: true,
          label: "Report Name",
        },
        {
          key: "reportType",
          sortable: true,
          label: "Report Type",
        },
        {
          key: "deliveryRegion",
          sortable: true,
          label: "Delivery Region",
        },
        {
          key: "deliveryDateFilterIncluded",
          sortable: true,
          label: "Delivery Date Filter Included",
        },
        {
          key: "noOfDaysToDeliveryDate",
          sortable: true,
          label: "No Of Days To Delivery Date",
        },
        {
          key: "defaultDeliveryStartTime",
          sortable: true,
          label: "Default Delivery Start Time",
        },
        {
          key: "deliveryWindowExcluded",
          sortable: true,
          label: "delivery Window Excluded",
        },
        {
          key: "skippedTag",
          sortable: true,
          label: "Skipped Tag",
        },
      ],
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc",
    };
  },
  async created() {
    this.load();
  },
  methods: {
    async load() {
      this.processing = true;
      this.displayMessage("Success", "Getting data...");
      await this.axios.get(
        process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/config",
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.items = response.data;
        } else {
          console.log("Response is not 200");
        }
        this.processing = false;
      })
      .catch(error => {
        console.error("Error: ", error);
        let message;
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        } else {
          if (error.message === "Network Error" ) {
              message = error.message + ': Please try again later';
          } else {
            message = "Response Error";
          }
        }
        this.displayMessage("warning", message);
        this.processing = false;
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    editItem(item) {
      this.$router.push({ name: "OrderReports", params: { editedItem: item } });
    },

    addItem() {
      this.$router.push({
        name: "OrderReports",
        params: {},
      });
    },

    async deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.processing = true;
        await this.axios
          .delete(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
              "/order-pull/config/" + item.uuid,
          )
          .then((response) => {
            this.load();
            this.processing = false;
          })
          .catch(error => {
            let message;
            if (error.response.data && error.response.data.message) {
              message = error.response.data.message;
            } else {
              message = "response error";
            }
            this.displayMessage("warning", message);
            this.processing = false;
          });
      }
    },

    async generate(item) {
      const params = { uuid: item.uuid, prefix: "(Manual)" };
      this.axios
        .post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/export",
          params,
        )
        .then((response) => {
          if (response && response.status === 200) {
            this.displayMessage("success", "The manual report is generating. Please wait and check Pull Folder");
          } else {
            this.displayMessage("warning", "Request failed. Please check login status");
          }
        })
        .catch(error => {
          console.error("Error:", error)
          this.displayMessage("warning", "Request failed. Please check login status");
        });
      this.displayMessage("success", "The manual report is generating. Please wait and check Pull Folder");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/order-pulls-config-grid.scss";
</style>
