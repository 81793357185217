<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
                @keyup.enter="filterDataTable"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
              v-model="filterOn"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="name">Rate</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light">Type and press enter to filter data.
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="3" sm="6" class="left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select v-model="perPageCMB" :options="perPageCMBOptions"
                           class="col-3 ml-1 mr-2" size="sm"></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge
                variant="secondary" class="mr-2">{{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">{{
                totalRows
              }}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="4" sm="6">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              size="sm"
              @input="gridPagination"
          >
          </b-pagination>
        </b-col>
<!--        <b-col lg="5" sm="12" align="right" class="text-wrap">-->
<!--          <b-badge variant="info">Double click on relevant content and make a change to the content<br> Then press the-->
<!--            "Update All Changes" button.-->
<!--          </b-badge>-->
<!--        </b-col>-->
        <tooltip-modal></tooltip-modal>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <vue-editable-grid
              class="my-grid-class"
              ref="deliveryRateEditableGrid"
              id="myGrid"
              :item-height='35'
              :column-defs="columnDefs"
              rowDataKey="id"
              :row-data="rowData"
              @cell-updated="cellUpdated"
              @link-clicked="linkClicked"
          >
          </vue-editable-grid>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" sm="6" class="text-left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select v-model="perPageCMB" :options="perPageCMBOptions"
                           class="col-3 ml-1 mr-2" size="sm"></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge
                variant="secondary" class="mr-2">{{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">{{
                totalRows
              }}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="4" sm="6">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              size="sm"
              @input="gridPagination"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import {PlusIcon} from "vue-feather-icons";
import VueEditableGrid from 'vue-editable-grid'
import 'vue-editable-grid/dist/VueEditableGrid.css'

export default {
  components: {
    VueEditableGrid
  },
  props: ["processing", "rowData", "pageCount", "columnDefs", "pgCurrentPage", "pgTotalRows", "pgPerPage"],
  name: "postalCodeGrid",
  data() {
    return {
      filter: null,
      filterOn: [],
      currentPage: this.pgCurrentPage,
      totalRows: this.pgTotalRows,
      perPage: this.pgPerPage,
      numOfPages: 0,
      updateArray: [],
      zoneID: 0,
      newRowData: [],
      perPageCMBOptions: [],
      perPageCMB: '20'
    };
  },
  watch: {
    pgTotalRows: function (newVal, oldVal) {
      this.totalRows = this.pgTotalRows
      this.updateNumberOfPages();
    },
    pgPerPage: function () {
      this.perPage = this.pgPerPage
      this.updateNumberOfPages();
    },
    currentPage: function () {
      this.$emit('changePaginationPageEvent', this.currentPage)
    },
    pgCurrentPage: function (newVal, oldVal) {
      this.currentPage = this.pgCurrentPage
    },
    perPageCMB: function (selected) {
      this.$emit('gridPaginationPerPageChange', selected)
    }
  },
  mounted() {
    this.updateNumberOfPages()
  },
  methods: {
    filterDataTable(event) {
      let filterOpt = this.filterOn
      if (this.filterOn.length === 0) {
        filterOpt = ['name']
      }
      this.$emit('filterDataTableEvent', {searchString: event.target.value, filterOn: filterOpt})
    },
    updateNumberOfPages() {
      this.numOfPages = Math.ceil(this.totalRows / this.perPage)
      this.perPageCMBOptions = ['20', '50', '100', '150', '200', '250', {value: this.pgTotalRows, text: 'All'}]
    },
    gridPagination(currentPage) {
      this.$emit('gridPaginationEvent', currentPage)
    },
    cellUpdated(event) {
      console.log("updated event",event)
      if (this.updateArray.length === 0) {
        const data = event.row;
        if (event.column && event.column.field) {
          data[event.column.field] = event.value;
        }
        data.name = `${data.period} ${data.rate}`;
        this.updateArray.push(data)
        console.log("updated array", this.updateArray)
      } else {
        let updated = false;
        for (const qData of this.updateArray) {
          if (qData.id === event.row.id) {
            updated = true;
            if (event.column && event.column.field) {
              qData[event.column.field] = event.value;
            }
            qData.name = `${qData.period} ${qData.rate}`;
            break;
          }
        }
        if (!updated) {
          const data = event.row;
          if (event.column && event.column.field) {
            data[event.column.field] = event.value;
          }
          data.name = `${data.period} ${data.rate}`;
          this.updateArray.push(data)
        }
      }

      // const updatedFieldArray = []
      // switch ($event.column.field) {
      //   case "applicableStartTime":
      //     updatedFieldArray.push({
      //       'updatePoint': 'applicableStartTime',
      //       'id': $event.row.id,
      //       'applicableStartTime': this.getFormatTime($event.value)
      //     });
      //     break;
      //   case "applicableEndTime":
      //     updatedFieldArray.push({
      //       'updatePoint': 'applicableEndTime',
      //       'id': $event.row.id,
      //       'applicableEndTime': this.getFormatTime($event.value)
      //     });
      //     break;
      //   case "applicableDate":
      //     updatedFieldArray.push({
      //       'updatePoint': 'applicableDate',
      //       'id': $event.row.id,
      //       'applicableDate': $event.value
      //     });
      //     break;
      //   case "isSameDayOnly":
      //     updatedFieldArray.push({
      //       'updatePoint': 'isSameDayOnly',
      //       'id': $event.row.id,
      //       'isSameDayOnly': $event.value
      //     });
      //     break;
      // }
      console.log("updateArray", this.updateArray)
      this.updateArray.map((qData, index) => {
        console.log("qData", qData)
        // console.log("updated array", updatedFieldArray[0])


        // if (qData.id === updatedFieldArray[0].id) {
        //   switch (updatedFieldArray[0].updatePoint) {
        //     case "applicableStartTime":
        //       this.updateArray[index]['applicableStartTime'] = updatedFieldArray[0].applicableStartTime
        //       break;
        //     case "applicableEndTime":
        //       this.updateArray[index]['applicableEndTime'] = updatedFieldArray[0].applicableEndTime
        //       break;
        //     case "applicableDate":
        //       this.updateArray[index]['applicableDate'] = updatedFieldArray[0].applicableDate
        //       break;
        //     case "isSameDayOnly":
        //       this.updateArray[index]['isSameDayOnly'] = updatedFieldArray[0].isSameDayOnly
        //       break;
        //   }
        // }
      })
      this.$emit("updateRowArray", this.updateArray);
    },
    getFormatTime(date) {
      if (!date) {
        return null;
      }
      if (typeof date === "string") {
        date = new Date(date);
      }
      const seperator1 = ":";
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      const time = hours + seperator1 + minutes + seperator1 + "00";
      return time;
    },

    linkClicked($event) {
      const deleteArray = {'id': $event.rowData.id, 'name': $event.rowData.name}
      this.$emit("deleteDeliveryRate", deleteArray);
    },
  }
}
</script>
<style>
/*vue-editable-table styles*/
.my-grid-class {
  height: 600px;
}

tr.filters-row {
}

thead {
  background-color: #343a40;
}

th.filter input[data-v-af99a9fe] {
  font-size: 0.9rem;
  color: #15cfd8;
}

th[data-v-af99a9fe]:after {
  background-color: white;
}

th.filter input[data-v-af99a9fe] {
  padding-left: 27px;
}

.cell-content > a {
  text-decoration: none;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  color: #fff;
  background-color: #f75050;
}

/*pagination*/
.pagination .spinner-grow {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  background-color: #f8f9fa;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.

.page-item.active .page-link {
  color: #fff;
  background-color: #18a2b8;
  border-color: #18a2b8;
}

.pagination-details {
  font-size: 0.97rem;
}

table.grid-table {
  border-radius: 3px;
}
</style>
