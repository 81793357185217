<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <cl-header mainTopic="Dates Available" />
          <CLButton
          format="primary-btn"
          v-on:func="showAddModal"
          >
          <plus-icon
            size="1.5x"
            class="text-white"
          ></plus-icon>
          Add New
          </CLButton>
          <add-dates-available-popup
            ref="AddDatesAvailableModal"
            @success="refreshGrid($event)"
            @message="showMessage($event)"
            @error="errorCreateDatesAvailable($event)"
          />
          <edit-dates-available-popup
            ref="EditDatesAvailableModal"
            :title="title"
            :update-array="updateArray"
            @success="refreshGrid($event)"
            @message="showMessage($event)"
            @error="errorUpdateDatesAvailable($event)"
          />
        </div>
      </div>
      <div class="row mt-3 mb-0">
        <dates-available-grid
          :items="gridItems"
          :processing="processing"
          :totalRows="totalRows"
          @showEditModal="showEditDatesAvailableModal($event)"
          @deleteCutoff="deleteCutoff($event)"
        ></dates-available-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import DatesAvailableGrid from "@/components/grids/dates-available-grid";
import {CLButton, CLHeader} from "callia-ui-box";
import AddDatesAvailableModal from "@/components/rule/add-dates-available-popup";
import EditDatesAvailableModal from "@/components/rule/edit-dates-available-popup";

export default {
  components: {
    PlusIcon,
    "dates-available-grid": DatesAvailableGrid,
    "add-dates-available-popup": AddDatesAvailableModal,
    "edit-dates-available-popup": EditDatesAvailableModal,
    "cl-header": CLHeader,
    CLButton
  },
  data() {
    return {
      title: "",
      updateArray: [],
      gridItems: [],
      regions: [],
      processing: false,
      totalRows: 0
    };
  },
  async mounted() {
    await this.getAllRules();
  },
  methods: {
    showAddModal() {
      this.$refs.AddDatesAvailableModal.show();
    },
    showEditDatesAvailableModal(editInfo) {
      this.title = editInfo["region_name"];
      this.updateArray = editInfo;
      this.$refs.EditDatesAvailableModal.show();
    },
    async refreshGrid(message) {
      await this.getAllRules();
      this.displayMessage("success", message);
    },
    async showMessage(message) {
      this.displayMessage("success", message);
    },
    errorCreateDatesAvailable(message) {
      this.displayMessage("warning", `DatesAvailable saving process failed. ${message}`);
    },
    errorUpdateDatesAvailable(message) {
      this.displayMessage("warning", `DatesAvailable updating process failed. ${message}`);
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    async getAllRules() {
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/dates-available-api`
      }).catch(err => {
        this.displayMessage("warning", "Error fetching rule.");
        console.log("Error: ", err);
      });
      console.log(response.data);
      this.gridItems = response.data;
      this.totalRows = this.gridItems.length;
      this.processing = false;
    },
    async getAllRegions() {
      try {
        this.items = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`
        });
        this.regions = response.data;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error fetching Regions.");
        console.log("Error: ", errorResponse);
      }
    },
    async deleteCutoff(event) {
      const h = this.$createElement;
      const messageVNode = h("div", {
        domProps: {
          innerHTML: `Please confirm that you want to delete this cutoff: <strong>${event.name}</strong> <strong>${event.available_date}</strong>.<br />
        Deleting this cutoff will <br />
          a) revert the cutoff to the default cutoff for that day of week (if we are usually open) or <br />
          b) close the date on Callia.com if it's a date we are normally closed in this region`
        }
      });
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Delete cutoff",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then(async status => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/dates-available-api/${event.id}`
            });
            this.refreshGrid("Deleted");
          }
        });
    }
  }
};
</script>
