import { render, staticRenderFns } from "./opslinks-edit-modal.vue?vue&type=template&id=15b14b26&scoped=true"
import script from "./opslinks-edit-modal.vue?vue&type=script&lang=js"
export * from "./opslinks-edit-modal.vue?vue&type=script&lang=js"
import style0 from "./opslinks-edit-modal.vue?vue&type=style&index=0&id=15b14b26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b14b26",
  null
  
)

export default component.exports