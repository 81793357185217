// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Vue from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Vuex from "vuex";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VueAxios from "vue-axios";
import router from "./routes";
import {getCurrentUser} from "./callia-okta-authenticate";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export const store = new Vuex.Store({
    state: {
        currentUser: null,
        accessToken: "",
        idToken: "",
        isAuthenticated: false,
        globalException: {
            displayFor: 2,
            error: ""
        },
        todayOnfleetDrivers: [],
        onfleetDriverSMSTemplate: [],
        regions: [],
        giftsetsalert: [],
        postalCodes: [],
        zone: [],
        deliveryRates: [],
        town: [],
        province: [],
        deliverySMSTemplate: [],
        giftsetAlertTemplate: [],
    },
    getters: {
        currentUser(state) {
            return state.currentUser;
        },
        accessToken(state) {
            return state.accessToken;
        },
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        idToken(state) {
            return state.idToken;
        },
        regions(state) {
            return state.regions
        },
        giftsetsalert(state) {
            return state.giftsetsalert
        },
        todayOnfleetDrivers(state) {
            return state.todayOnfleetDrivers
        },
        onfleetDriverSMSTemplate(state) {
            return state.onfleetDriverSMSTemplate
        },
        postalCodes(state) {
            return state.postalCodes
        },
        zone(state) {
            return state.zone
        },
        deliveryRates(state) {
            return state.deliveryRates
        },
        town(state) {
            return state.town
        },
        province(state) {
            return state.province
        },
        deliverySMSTemplate(state) {
            return state.deliverySMSTemplate;
        },
        giftsetAlertTemplate(state) {
            return state.giftsetAlertTemplate;
        }
    },
    mutations: {
        setCurrentUser(state, user) {
            state.currentUser = user;
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setIdToken(state, idToken) {
            state.idToken = idToken;
        },
        setRegions(state, regions) {
            state.regions = regions;
        },
        setGiftSetsAlert(state, giftsetsalert) {
            state.giftsetsalert = giftsetsalert;
        },

        setTodayOnfleetDrivers(state, todayOnfleetDrivers) {
            state.todayOnfleetDrivers = todayOnfleetDrivers;
        },
        setOnfleetDriverSMSTemplate(state, onfleetDriverSMSTemplate) {
            state.onfleetDriverSMSTemplate = onfleetDriverSMSTemplate;
        },
        setPostalCodes(state, postalCodes) {
            state.postalCodes = postalCodes
        },
        setZone(state, zone) {
            state.zone = zone
        },
        setDeliveryRates(state, deliveryRates) {
            state.deliveryRates = deliveryRates
        },
        setTown(state, town) {
            state.town = town
        },
        setProvince(state, province) {
            state.province = province
        },
        setAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated
        },
        setDeliverySMSTemplate(state, SMSTemplate) {
            state.deliverySMSTemplate = SMSTemplate;
        },
        setGiftsetAlertTemplate(state, giftsetAlertTemplate) {
            state.giftsetAlertTemplate = giftsetAlertTemplate;
        },
    },
    actions: {
        async getCurrentuser(context) {
            if (!context.state.currentUser) {
                const user = await getCurrentUser();
                if (user) {
                    context.commit("setCurrentUser", user);
                } else {
                    console.log("can't get token");
                }
            }
        },

        async setAuthContext(context, payload) {
            const {tokens, currentUser} = payload;
            const {idToken, accessToken} = tokens;
            context.commit("setIdToken", idToken);
            context.commit("setAccessToken", accessToken);
            context.commit("setCurrentUser", currentUser);
            context.commit("setAuthenticated", true);
        },

        async logout(context) {
            context.commit("setIdToken", null);
            context.commit("setAccessToken", null);
            context.commit("setCurrentUser", null);
            context.commit("setAuthenticated", false);
        }
    }
});
