import { render, staticRenderFns } from "./CreateBulkOrder.vue?vue&type=template&id=28531cf4&scoped=true"
import script from "./CreateBulkOrder.vue?vue&type=script&lang=js"
export * from "./CreateBulkOrder.vue?vue&type=script&lang=js"
import style0 from "./CreateBulkOrder.vue?vue&type=style&index=0&id=28531cf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28531cf4",
  null
  
)

export default component.exports