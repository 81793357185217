<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="mt-3">
        <b-col lg="3" sm="6" class="left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select
                v-model="perPageCMB"
                :options="perPageCMBOptions"
                class="col-3 ml-1 mr-2"
                size="sm"
            ></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge variant="secondary" class="mr-0">
              {{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">
              {{ totalRows }}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="5" sm="6" class="paginationLinks">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              size="sm"
              @input="gridPagination"
          >
          </b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <b-table
              hover
              :sticky-header="'80vh'"
              medium
              no-border-collapse
              class="my-grid-class"
              ref="giftsetAlertTemplateEditableGrid"
              id="giftsetAlertTemplateGrid"
              :item-height='40'
              :fields="fields"
              :column-defs="columnDefs"
              rowDataKey="name"
              :items="rowData"
          >
            <template #cell(action)="row">
              <edit-icon
                  size="3x"
                  class="custom-class"
                  @click="editTemplate(row.item)"
              ></edit-icon>
              <trash-icon
                size="3x"
                class="custom-class trashIcon"
                @click="deleteTemplate(row.item)"
              ></trash-icon>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" sm="6" class="text-left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select
                v-model="perPageCMB"
                :options="perPageCMBOptions"
                class="col-3 ml-1 mr-2"
                size="sm"
            ></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge variant="secondary" class="mr-2">
              {{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">
              {{ totalRows }}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="5" sm="6">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              size="sm"
              class="paginationLinks"
              @input="gridPagination"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import {EditIcon, TrashIcon} from "vue-feather-icons";

export default {
  components: {
    EditIcon,
    TrashIcon
  },
  props: ["processing", "rowData", "pageCount", "columnDefs", "pgCurrentPage", "pgTotalRows", "pgPerPage"],
  name: "giftsetAlertTemplateGrid",
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "text-center",
          tdClass: "text-center cell-font"
        },

        {
          key: "alertHeader",
          label: "Alert Header",
          thClass: "text-center",
          tdClass: "text-center cell-font"
        },
        {
          key: "alertBody",
          label: "Alert Body",
          thClass: "text-center",
          tdClass: "text-center cell-font"
        },
        {
          key: "action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center"
        }
      ],
      currentPage: this.pgCurrentPage,
      totalRows: this.pgTotalRows,
      perPage: this.pgPerPage,
      numOfPages: 0,
      updateArray: [],
      perPageCMBOptions: [],
      perPageCMB: '20'
    };
  },
  watch: {
    pgTotalRows: function (newVal, oldVal) {
      this.totalRows = this.pgTotalRows;
      this.updateNumberOfPages();
    },
    pgPerPage: function () {
      this.perPage = this.pgPerPage;
      this.updateNumberOfPages();
    },
    currentPage: function () {
      this.$emit('changePaginationPageEvent', this.currentPage);
    },
    pgCurrentPage: function (newVal, oldVal) {
      this.currentPage = this.pgCurrentPage;
    },
    perPageCMB: function (selected) {
      this.$emit('gridPaginationPerPageChange', selected);
    }
  },
  mounted() {
    this.updateNumberOfPages();
  },
  methods: {
    editTemplate(row) {
      this.$emit("showEditModal", row);
    },
    updateNumberOfPages() {
      this.numOfPages = Math.ceil(this.totalRows / this.perPage);
      this.perPageCMBOptions = ['20', '50', '100', '150', '200', '250', {value: this.pgTotalRows, text: 'All'}];
    },
    gridPagination(currentPage) {
      this.$emit('gridPaginationEvent', currentPage);
    },
    deleteTemplate(row) {
      const deleteArray = {
        'name': row.name,
        'alertHeader': row.alertHeader,
        'alertBody': row.alertBody,
      };
      this.$emit("deleteTemplate", deleteArray);
    }
  }
};
</script>

<style>
/*vue-editable-table styles*/
.my-grid-class {
  height: 450px;
}

thead {
  background-color: #636e78 !important;
}

.cell-content > a {
  text-decoration: none;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  color: #fff;
  background-color: #f75050;
}

.cell-font {
  font-size: 0.9rem;
}

.trashIcon {
  color: red
}

.custom-class {
  min-width: 30px;
  min-height: 30px;
}

/*pagination*/
.pagination .spinner-grow {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  background-color: #f8f9fa;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.paginationLinks {
  display: flex;
  justify-content: center;
}

.pagination-sm .page-link {
  padding: 0.25rem 1rem;
}

.primary-btn {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.primary-btn:hover {
  color: white;
  background-color: #18b2cb;
  border-color: #18b2cb;
}

.primary-btn:focus {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(177 179 179 / 23%);
}

.page-item.active .page-link {
  color: #fff;
  background-color: #18a2b8;
  border-color: #18a2b8;
}

.pagination-details {
  font-size: 0.97rem;
}

table.grid-table {
  border-radius: 3px;
}
</style>