<template>

  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">

    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row>
        <b-col lg="12">
          <b-table
              hover
              responsive
              :sticky-header="'58vh'"
              small
              id="driver-template-table"
              class="table-height"
              :items="items"
              :fields="fields"
              head-variant="dark"
              :select-mode="tableSelectMode"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>

export default {
  props: ["items", "processing", "totalRows"],
  name: "onfleet-driver-sms-template-grid",
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "selected",
          sortable: true,
          label: "️✔",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          key: "template",
          sortable: true,
          label: "Template",
          thClass: "text-center",
          tdClass: "text-left"
        }
      ],
      tableSelectMode: "single",
      selected: []
    };
  },
  created() {
    this.$root.$refs.OnfleetDriverSMSTemplate = this;
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      this.$emit("selectedTemplate", this.selected)
    }
  }
};
</script>
<style>
.input-group {
  margin-left: 0 !important;
  text-align: center;
}

/*checkbox filter*/
.custom-filter-bg {
  background: radial-gradient(
      circle,
      #e9f1f966 44%,
      rgba(255, 255, 255, 1) 100%
  );
  border-radius: 20px;
}

/*filter search*/
input#filter-input {
  border-color: #343a405c;
  padding: 10px 20px;
  border-radius: 28px;
}

/*filter icon*/
.icon {
  right: 10px !important;
  top: 6px !important;
  margin-left: -23px;
  margin-top: 7px;
  z-index: 99;
}

/*custom pagination*/
.page-item.disabled .page-link {
  color: #bcc1c6;
  background-color: #222e3d;
  border-color: #ffffff;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #0a9ea5;
  border-color: #0a9ea5;
}

.page-link {
  font-weight: 600;
  color: #bbbfc5;
  background-color: #222e3d;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 0px;
}

.page-link:hover {
  background-color: #edf0f3;
  color: black;
  font-weight: 600;
}

.table.b-table > tbody > .table-active > td {
  background-color: rgb(10 158 165);
  color: white;
}
.table.b-table > tbody > .table-active > td:hover {
  color: white !important;
}

td {
  font-size: 0.79vw;
}
</style>