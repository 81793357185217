<template>
  <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto mt-5 wrapper">
    <div class="card card0 border-0">
      <div class="row d-flex">
        <div>
          <div class="card1 pb-5">
            <div class="card2 card border-0">
              <div class="row justify-content-center">
                <img
                        src="@/assets/images/login/callia-primary-logo.png"
                        class="logo"
                />
              </div>
              <div class="logo-tittle justify-content-center d-flex">
                <h4 class="pt-1 pr-1">Welcome to</h4>
                <h2 class="font-weight-bold">Admin Portal</h2>
              </div>
              <div class="d-flex">
                <div class="row px-3 mb-4 mobile-view">
                  <div class="justify-content-center">
                    <img
                            src="@/assets/images/login/login-page-icon.svg"
                            class="image login-icon"
                    />
                  </div>
                  <div class="mt-6 mobile-signing">
                    <label class="font-weight-bold mt-2 mb-2 text-center"
                    >Already have a callia account?, Login here</label
                    >
                    <a
                            class="btn btn-block btn-social btn-google"
                            @click="authenticate()"
                    >
                      <img
                              src="@/assets/images/login/google.svg"
                              class="image"
                      /> Sign in with Google
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="bg-green py-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6 text-left">
              <p class="mb-0">
                <a href="#"><strong>Callia Admin</strong></a> ©
              </p>
            </div>
            <div class="col-6">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a href="#">Support</a>
                </li>
                <li class="list-inline-item">
                  <a ref="#">Help Center</a>
                </li>
                <li class="list-inline-item">
                  <a href="#">Privacy</a>
                </li>
                <li class="list-inline-item">
                  <a href="#">Terms</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    authenticate: async function() {
      await this.$auth.signIn();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/social.scss";

.card {
  margin: 1rem;
  border-radius: 2rem !important;
}

.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.logo {
  width: 50%;
  height: 20%;
  margin-top: 20px;
  margin-left: 35px;
}

.logo-tittle {
  margin-top: 5px;
  margin-left: 35px;
}

.logo-tittle h2 {
  color: #0a9ea5;
}

.image {
  width: 360px;
  height: 280px;
}

.border-line {
  border-right: 1px solid #eeeeee;
}

.line {
  height: 1px;
  width: 45%;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #304ffe;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-blue {
  background-color: $callia-green;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-blue:hover {
  background-color: #000;
  cursor: pointer;
}

.bg-green {
  color: #fff;
  background-color: $callia-green;
  border-radius: 0 0 2rem 2rem;
}
.btn-google {
  background-color: white !important;
  color: #0f253c !important;
}
.login-icon {
  width: 90%;
}

.btn-google img {
  height: 2rem !important;
  background: white;
}

.mobile-signing label {
  color: #787b7e;
}

.wrapper {
  width: fit-content; border-radius: 2rem;
}

@media screen and (max-width: 600px) {

  .card1 {
    padding-bottom: 1rem !important;
  }

  .logo {
    margin-left: 3px;
  }

  .logo-tittle {
    margin-left: 5px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
  }

  .mobile-view {
    justify-content: center;
  }

  .mobile-signing {
    margin-top: 2rem !important;
  }

  h4 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.2rem;
  }
}
</style>
