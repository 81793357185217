<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <cl-header mainTopic="Dates Closed" />
          <CLButton
          format="primary-btn"
          v-on:func="showAddModal"
          >
          <plus-icon
                  size="1.5x"
                  class="text-white"
          ></plus-icon>
          Batch Add Closed Dates
          </CLButton>
          <add-dates-closed-popup
            ref="AddDatesClosedModal"
            @success="refreshGrid($event)"
            @error="errorCreateDatesClosed"
          />
          <edit-dates-closed-popup
            ref="EditDatesClosedModal"
            :title="title"
            :update-array="updateArray"
            @success="refreshGrid($event)"
            @error="errorUpdateDatesClosed"
          />
        </div>
      </div>
      <div class="row mt-3 mb-0">
        <dates-closed-grid
          :items="gridItems"
          :processing="processing"
          :totalRows="totalRows"
          @showEditModal="showEditDatesClosedModal($event)"
        ></dates-closed-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import DatesClosedGrid from "@/components/grids/dates-closed-grid";
import {CLButton, CLHeader} from "callia-ui-box";
import AddDatesClosedModal from "@/components/rule/add-dates-closed-popup";
import EditDatesClosedModal from "@/components/rule/edit-dates-closed-popup";

export default {
  components: {
    PlusIcon,
    "dates-closed-grid": DatesClosedGrid,
    "add-dates-closed-popup": AddDatesClosedModal,
    "edit-dates-closed-popup": EditDatesClosedModal,
    "cl-header": CLHeader,
    CLButton
  },
  data() {
    return {
      title: "",
      updateArray: [],
      gridItems: [],
      regions: [],
      processing: false,
      totalRows: 0
    };
  },
  async mounted() {
    await this.getAllRules();
    this.getAllRegions();
  },
  methods: {
    showAddModal() {
      this.$refs.AddDatesClosedModal.show();
    },
    showEditDatesClosedModal(editInfo) {
      this.title = editInfo["region_name"];
      this.updateArray = editInfo;
      this.$refs.EditDatesClosedModal.show();
    },
    async refreshGrid(message) {
      await this.getAllRules();
      this.displayMessage("success", message);
    },
    errorCreateDatesClosed() {
      this.displayMessage("warning", "DatesClosed saving process failed.");
    },
    errorUpdateDatesClosed() {
      this.displayMessage("warning", "DatesClosed update process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    async getAllRules() {
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/region-rule-api`
      }).catch(err => {
        this.displayMessage("warning", "Error fetching rule.");
        console.log("Error: ", err);
      });
      console.log(response?.data);
      this.gridItems = response?.data;
      this.totalRows = this.gridItems?.length;
      this.processing = false;
    },
    async getAllRegions() {
      try {
        this.items = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region`
        });
        this.regions = response?.data;
        return true;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error fetching Regions.");
        console.log("Error: ", errorResponse);
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.modal.show .modal-dialog {
  max-width: 800px;
  width: 800px;
}
</style>