<template>
  <div class="card">
    <b-form-group label="Event schedule:" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="flavour-1"
      ></b-form-checkbox-group>
    </b-form-group>
    <b-form-timepicker
      placeholder="Choose a time"
      id="timepicker"
      size="sm"
      locale="en"
      class="mb-2"
      v-model="time"
    ></b-form-timepicker>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      cronExpression: "",
      time: "16:00:00",
      selected: [],
      options: [
        { text: "Sun", value: "0" },
        { text: "Mon", value: "1" },
        { text: "Tue", value: "2" },
        { text: "Wed", value: "3" },
        { text: "Thu", value: "4" },
        { text: "Fri", value: "5" },
        { text: "Sat", value: "6" },
      ],
      weeks: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    };
  },
  created() {
    this.calcCron();
  },
  methods: {
    getCronExpression() {
      const weeksNumbers = this.selected;
      const now = dayjs().tz("America/Winnipeg");
      const timeArray = this.time.split(":");
      let date = now.hour(timeArray[0]).minute(timeArray[1]);
      date = date.utc();
      const selectedWeeks = [];
      if (date.day() !== now.day()) {
        for (const week of weeksNumbers) {
          let weekNew = parseInt(week) + 1;
          if (weekNew === 7) {
            weekNew = 0;
          }
          selectedWeeks.push(this.weeks[weekNew]);
        }
      } else {
        for (const week of weeksNumbers) {
          const weekNew = parseInt(week);
          selectedWeeks.push(this.weeks[weekNew]);
        }
      }
      const days = selectedWeeks.join(",");
      this.cronExpression = `${date.minute()} ${date.hour()} ? * ${days} *`;
    },
    calcCron() {
      if (this.value) {
        const timeArray = this.value.split(" ");
        if (timeArray.length === 6) {
          let minute = timeArray[0];
          if (minute.length === 1) {
            minute = "0" + minute;
          }
          let hour = timeArray[1];
          if (hour.length === 1) {
            hour = "0" + hour;
          }
          const now = dayjs().utc();
          const date = now.hour(hour).minute(minute).tz("America/Winnipeg");
          hour = date.hour();
          this.time = hour + ":" + minute + ":00";
          let weekArray;
          let selectedWeeks;
          if (timeArray[4]) {
            if (timeArray[4].includes("-")) {
              weekArray = timeArray[4].split("-");
              const rangeWeeks = weekArray.map((item) => {
                return this.getWeekNumber(item);
              });
              selectedWeeks = this.getWeeks(rangeWeeks[0], rangeWeeks[1]);
            } else {
              weekArray = timeArray[4].split(",");
              selectedWeeks = weekArray.map((item) => {
                return this.getWeekNumber(item);
              });
            }
          }

          if (selectedWeeks) {
            if (date.day() !== now.day()) {
              for (const week of selectedWeeks) {
                let weekNew = parseInt(week) - 1;
                if (weekNew === -1) {
                  weekNew = 6;
                }
                this.selected.push(weekNew);
              }
            } else {
              this.selected = selectedWeeks;
            }
          }
        }
      }
    },
    getWeeks(weekBegin, weekEnd) {
      const weeks = [];
      for (let index = weekBegin; index <= weekEnd; index++) {
        weeks.push(index);
      }
      return weeks;
    },
    getWeekNumber(week) {
      return this.options.find((t) => {
        return t.text.toLowerCase() === week.toLowerCase();
      }).value;
    },
  },
  watch: {
    time() {
      this.getCronExpression();
      this.$emit("input", this.cronExpression);
    },
    selected() {
      this.getCronExpression();
      this.$emit("input", this.cronExpression);
    },
    value() {
      // this.calcCron();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
