
<template>
  <div>

    <div>
      <label>Tags:</label>
      <b-form-tags input-id="tags-basic" v-model="tags"></b-form-tags>
      <br/>
    </div>
    <div>
      <label>Discount Code:</label>
      <b-input v-model="discountCode"></b-input>
    </div>
    <!--
    <div>
      <label>Locale:</label>
      <b-form-select  v-model="locale" :options="localeList"></b-form-select>
    </div>
    --->

    <!--
    <b-dropdown text="Province">
      <b-dropdown-item
        v-for="region in Object.keys(regions)"
        v-bind:key="regions[region]"
        >{{ region }}</b-dropdown-item>

    </b-dropdown>

    <b-input  type="number"/>

    <b-dropdown @change="console.log('Here')">

      <b-dropdown-item> Bouquet </b-dropdown-item>
      <b-dropdown-item> Card </b-dropdown-item>
      <b-dropdown-item> Addons </b-dropdown-item>
      <b-dropdown-item> Vase </b-dropdown-item>
    </b-dropdown>
    -->
    <!--
    <div>
      <br/>
      <label>Find products:</label>
      <b-row>
        <b-col>
          <b-input v-model="titleInput"> </b-input>
        </b-col>
        <b-col>
          <b-button @click="doGraphQL"> Search </b-button>
        </b-col>
      </b-row>
    </div>
    --->
    <!--
    <div v-if="show === true">


      <h2>
        Result
      </h2>

      <b-row>
        <b-col style="display: grid">
          <img v-bind:src="imageURL" style="height: 100px; width: 100px;">
          {{title}}
          <div>
          </div>
        </b-col>
        <b-col>
          <div v-if="productType === 'GiftAdds'" style="display: grid">
            <b-row>
              <b-col>
                <b-button v-if="quantityChosen > 0" @click="changeQuantity(-1)">-</b-button>
              </b-col>
              <b-col>
                {{quantityChosen}}
              </b-col>
              <b-col>
                <b-button @click="changeQuantity(1)">+</b-button>
              </b-col>
            </b-row>



          </div>
          <b-form-select v-if="variantList.length > 1" v-model="currentVariant" :options="variantList"></b-form-select>
        </b-col>
        <b-col>
          <b-button @click="addToCart"> Add to Cart </b-button>
        </b-col>
      </b-row>

    </div>
    -->
    <div>
      <b-row>
        <b-col>
          <b-row v-bind:key="order.productTitle + index" v-for="(order,index) in cart.Flowers">
            <b-button class = "removeItem" @click="removeFromCart(cart.Flowers,index)">X</b-button>
            <div class = "cartItem">
              {{"x"+order.quantity+" | "+order.productTitle + " | "+order.variantTitle}}
            </div>
          </b-row>
          <b-row v-bind:key="order.productTitle" v-for="order in cart.Vase">
            <b-button class = "removeItem" @click="removeFromCart(cart.Vase, index)">X</b-button>
            <div class = "cartItem">
              {{"x"+order.quantity+" | "+order.productTitle + " | "+order.variantTitle}}
            </div>

          </b-row>
          <b-row v-bind:key="order.productTitle" v-for="order in cart.GiftAdds">
            <b-button class = "removeItem" @click="removeFromCart(cart.GiftAdds, index)">X</b-button>

            <div class = "cartItem">
              {{"x"+order.quantity+" | "+order.productTitle + " | "+order.variantTitle}}
            </div>

          </b-row>
          <b-row v-bind:key="order.productTitle" v-for="order in cart.Card">
            <b-button class = "removeItem" @click="removeFromCart(cart.Card, index)">X</b-button>

            {{"x"+order.quantity+" | "+order.productTitle + " | "+order.variantTitle}}

          </b-row>
        </b-col>
      </b-row>
      <br/>

      <div>
        <label> Upload File </label>
        <b-form-file v-model = "file" accept=".xlsx">

        </b-form-file>
      </div>
      <br/>
      <br/>
      <b-row>
        <b-col>
          <b-button @click = "handleSubmit()">
            Submit
          </b-button>
        </b-col>
        <b-col>
          <a style="color: green;" v-if="show === true && success === true">
                Orders Succeeded!
          </a>
          <a style="color: red;" v-if="show === true && success === false">
            Orders Failed!
          </a>
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires

const regions ={"Manitoba":"MB",  "Alberta" :"AB", "Ottawa":"OT", "Calgary":"CG", "Halifax":"HX", "Hamilton":"HM", "Saskatchewan":"SK", "Toronto":"TR"}
const bouquetChosen = "";
const vaseChosen = "";
const cardChosen = "";
const set = false;
const addons = {};
const cards = {};
const bouquets = {};
const vases = {};
//
const shippingType = "Anytime";
const tags = [];
const title = "";
const imageURL = "";
const variantList = [];
const titleInput = "";
const currentVariant = "";
const productType = "";
const cart = { Flowers: [], GiftAdds: [], Card: [], Vase: [] };
const quantityChosen = 0;
const show = false;
const file = {};
const localeList = ["en-ca", "en-us", "fr-ca"];
const locale = "";
const discountCode = "";
const success = false;
  export default {
  name: "b2bordersubmission",
  methods: {
    removeFromCart(array, index){
      array.splice(index, 1);
    },
    async doGraphQL(){
      console.log(this.title)

      let query = `{
        products(first: 1, reverse: true, query: "title:*'{0}'*") {
          edges {
            node {
              id
              title
              images (first: 1){
                edges{
                  node{
                    src
                  }
                }
              }
              productType
              variants(first: 10) {
                edges {
                  node {
                    id
                    title
                    sku
                  }
                }
              }
            }
          }
        }
      }`

      query = query.replace('{0}', this.titleInput)
      console.log(query)
      const uninterceptedAxiosInstance = axios.create ({
      });

      //const data = '{products(first: 50, query:"tag:bouquet and tag:MB and tag:0622"){edges{node{title id tags variants(first:15) {edges{node{ id title sku }}} }}} }'

      const dataObject = await uninterceptedAxiosInstance.post("https://checkout.callia.com/api/2020-01/graphql.json", query, {
        'headers': {
          'X-Shopify-Storefront-Access-Token': 'nil',
          'content-type': 'application/graphql',
          'accept': 'application/json',
        }
      })
      this.processResponse(dataObject.data)
      console.log(this)

    },
    changeQuantity(modifier){
      const newVal = this.quantityChosen + modifier;
      if(newVal >= 0){
        this.quantityChosen = newVal;
      }
    },
    addToCart(){
      const newObj = {
        "productTitle": this.title,
        "variantTitle": this.currentVariant,
        "productType": this.productType,
        "quantity": this.quantityChosen
      }
      if(this.cart[this.productType] === undefined){
        this.cart[this.productType] = [];
      }
      this.cart[this.productType].push(newObj)
      this.show = false;


    },
    processResponse(response){
      console.log(response)
      const productType = response["data"]["products"]["edges"][0]["node"]["productType"];
      const imageSRC = response["data"]["products"]["edges"][0]["node"]["images"]["edges"][0]["node"]["src"];
      const productName = response["data"]["products"]["edges"][0]["node"]["title"]
      const variantNames = [];
      for (let variant of response["data"]["products"]["edges"][0]["node"]["variants"]["edges"]) {
          variant = variant["node"];
          variantNames.push(variant["title"]);
        }

      this.imageURL = imageSRC;
      this.variantList = variantNames;
      if(this.variantList.length === 1){
        this.currentVariant = this.variantList[0];
      }
      else{
        this.currentVariant = "";
      }
      this.title = productName;
      this.productType = productType;
      if(productType === "Gift-adds"){
        this.productType = "GiftAdds"
      }
      this.quantityChosen = 1;
      this.show = true;

      console.log(variantNames)
    },
    async handleSubmit(){

      console.log(this.$store.getters.currentUser.email)
      const request = {"tags": this.tags, "discountCode": this.discountCode, "alertEmail": this.$store.getters.currentUser.email, "xlsxBuffer": null};

      console.log(request);
      console.log(this.file);

      /*
      const products = []
      for(const type of Object.keys(this.cart)){
        console.log(type)
        for(const product of this.cart[type]){
          products.push(product)
        }
      }
    */
      //request.products = products;
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      reader.onload = async function(e) {
        const data = new Uint8Array(reader.result);
        //const uninterceptedAxios = axios.create({});
        console.log(request)
        request.xlsxBuffer = data
        request.shippingType = this.shippingType


        //process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/orders/bulkorder"
        //https://g7xyz7ntjj.execute-api.us-east-2.amazonaws.com/dev/orders/bulk
        await axios.post( process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/orders/bulk", request,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((response) =>{
          console.log(response)

          if(response.status === 200){
            self.show =true
            self.success = true

          }
          else {
            self.show =true
            self.success = false
          }

            }





        )




      }

      await reader.readAsArrayBuffer(this.file);



    }
  },
    data(){
    return{
      regions:regions,
      addons: addons,
      bouquets: bouquets,
      bouquetChosen: bouquetChosen,
      cards: cards,
      cardChosen: cardChosen,
      set: set,
      vases:vases,
      vaseChosen:vaseChosen,
      titleInput: titleInput,
      imageURL: imageURL,
      title: title,
      variantList: variantList,
      currentVariant: currentVariant,
      cart: cart,
      productType: productType,
      quantityChosen: quantityChosen,
      show: show,
      file: file,
      tags: tags,
      locale: locale,
      localeList: localeList,
      discountCode: discountCode,
      success: success

    }
    }
}

</script>

<style scoped>
.removeItem{
  background-color: #F07272;
  margin-right: 10px;
  border:none;
}
.cartItem{
  margin:auto;
  margin-left: 10px;
  vertical-align: middle;
}

</style>
