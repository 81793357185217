<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <b-row class="py-3 text-center">
      <b-col offset-lg="4" lg="4" sm="12">
        <b-input-group size="sm">
          <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-icon
                    icon="search"
                    :disabled="!filter"
                    @click="filter = ''"
                    class="icon"
            ></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col offset-lg="4" lg="4" sm="12">
        <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="my-1 py-1 custom-filter-bg"
        >
          <b-form-checkbox value="region">Region</b-form-checkbox>
          <b-form-checkbox value="availableDateChange"
          >Earliest change date</b-form-checkbox
          >
        </b-form-checkbox-group>
        <b-badge variant="light">
          Leave all unchecked to filter on all data
        </b-badge>
      </b-col>
    </b-row>
    <b-table
      hover
      :items="items"
      :fields="fields"
      :filter-included-fields="filterOn"
      :filter="filter"
      @filtered="onFiltered"
      show-empty
      small
    >
      <template #cell(lastPullDatelastPullTimeisManual)="data">
        {{ data.item.lastPullDate }} - {{ data.item.lastPullTime }}
        <b-badge :variant="data.item.isManual ? 'warning' : 'info'">{{
          data.item.isManual ? "Manual" : "Auto"
        }}</b-badge>
      </template>
      <template #cell(comment)="row">
        <b-form-input
          @keyup.enter="updateComment(row.item.region, row.item.comment)"
          v-model="row.item.comment"
          placeholder="Enter your comment"
        ></b-form-input>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "region",
          sortable: true,
          label: "Region"
        },
        {
          key: "availableDateChange",
          label: "Earliest change date",
          sortable: true,
          variant: "success"
        },
        {
          key: "lastPullDatelastPullTimeisManual",
          sortable: false,
          label: "Record updated"
        },
        { key: "comment", label: "Comment" }
      ],
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc"
    };
  },
  async created() {
    this.processing = true;
    await this.axios
      .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/latest", {
        headers: {
          Authorization:
            "Bearer " +
            this.$store.getters.idToken
        }
      })
      .then(response => {
        this.items = response.data;
        this.processing = false;
      });
  },
  methods: {
    async updateComment(region, commentText) {
      this.processing = true;
      if (event) {
        event.preventDefault();
      }
      await this.axios
        .post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/latest",
          {
            region: region,
            comment: commentText
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                this.$store.getters.idToken
            }
          }
        )
        .then(response => {
          console.log(response.data);
          this.processing = false;
        });
      console.log(region);
      console.log(commentText);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/latest-order-pulls-grid.scss";
</style>
