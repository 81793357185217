<template>
  <div class="container-fluid p-0 h-100">
    <div class="container-fluid p-0">
      <b-alert
          :show="notification1.showErrorCountDown"
          dismissible
          fade
          :variant="notification1.type"
      >
        {{ notificationMessage1 }}
      </b-alert>
      <h3><strong>Add to the Customer List</strong></h3>
      <div class="row">
        <div class="col-12 col-xl-12">
          <b-overlay :show="processing1" rounded="sm" class="overlay-display">
            <template #overlay>
              <div class="text-center">
                <b-spinner
                    type="grow"
                    variant="info"
                    label="Loading..."
                ></b-spinner>
                <p id="cancel-label">Please wait...</p>
              </div>
            </template>
            <div class="card">
              <b-form @submit="addCustomer" @reset="onReset" v-if="show">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-xl-6 col-lg-6">
                      <label class="form-label"
                      >Email/Phone<strong class="required-star">*</strong></label
                      >
                      <b-form-input
                          id="input-1"
                          v-model="form.email"
                          type="text"
                          placeholder="Email/Phone"
                          required
                      ></b-form-input>
                    </div>
                    <div class="form-group col-xl-6 col-lg-6">
                      <form>
                        <p>Which list should it be on?</p>
                        <div class="radio-group padding-left-1">
                          <div class="row">
                            <label for="reviewList"
                            >Review List
                              <input
                                  type="radio"
                                  id="reviewList"
                                  name="list"
                                  value="Review List"
                                  v-model="form.reviewList"
                                  class="radio-btn"
                              />
                            </label>
                          </div>
                          <label for="VIPList" class="padding-left-2"
                          >&nbsp;&nbsp;&nbsp;&nbsp;VIP List<input
                              type="radio"
                              id="VIPList"
                              name="list"
                              value="VIP List"
                              v-model="form.VIPList"
                              class="radio-btn"
                          /></label>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-label"
                    >Result:
                      <strong class="required-star">{{ result }}</strong></label
                    >
                  </div>
                  <div class="row submit-btns">
                    <b-button type="reset" variant="danger">Reset</b-button>
                    &nbsp;
                    <b-button
                        type="submit"
                        variant="primary"
                        class="action-btn form-submit-button"
                        :disabled="form.reviewList === '' && form.VIPList === ''"
                    >Submit
                    </b-button
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </b-overlay>
        </div>
      </div>
      <h3><strong>Customer List</strong></h3>
      <b-form v-if="show">
        <b-button @click="exportTable" class="action-btn" type="export">Export table to CSV</b-button>
      </b-form>
      <div class="row mb-0">
        <template-grid
            :pgCurrentPage="pgCurrentPage"
            :pgTotalRows="pgTotalRows"
            :pgPerPage="pgPerPage"
            :processing="processing"
            :rowData="rowData"
            @deleteCustomer="deleteCustomer"
            @editCustomer="updateCustomer"
            @editSubscription="editSubscription"
            @gridPaginationPerPageChange="updatePgPerPageChanges"
            @changePaginationPageEvent="changePgCurrentPage"
            @filterDataTableEvent="filterCustomerData"
        ></template-grid>
      </div>
    </div>
  </div>
</template>

<script>
import customerListGrid from "@/components/grids/customer-list-grid";
import {unparse} from "papaparse";
import {saveAs} from "file-saver";
import unsubscribeEmail from "@/views/unsubscribe/UnsubscribeEmail";

export default {
  name: "customerListGrid",
  components: {
    "template-grid": customerListGrid
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      rowData: [],
      processing: false,
      numOfPages: 0,
      newRowData: [],
      perPageCMBOptions: [],
      perPageCMB: "20",
      /**
       * Should the module add SEP={delimiter}
       *
       * Useful for opening file with Excel
       */
      separatorExcel: {
        type: Boolean,
        default: false
      },
      /**
       * What will be the encoding of the file
       */
      encoding: {
        type: String,
        default: "utf-8"
      },
      /**
       * Advanced options for Papaparse that is used to export to CSV
       */
      advancedOptions: {
        type: Object,
        default: {}
      },
      /**
       * Used only for testing purposes
       */
      testing: {
        required: false,
        default: false
      },
      email: "",
      processingMessage: "Please wait...",
      fields: [
        {
          key: "email",
          label: "email"
        }
      ],
      items: [],
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: "",
      form: {
        email: "",
        reviewList: "",
        VIPList: ""
      },
      result: "",
      show: true,
      processing1: false,
      notification1: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage1: ""
    };
  },
  async created() {
    this.processing = true;
    await this.getAllCustomers();
    this.processing = false;
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    onReset(event) {
      if (event) {
        event.preventDefault();
      }
      this.form.email = "";
      this.form.VIPList = "";
      this.form.reviewList = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage;
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(perPage);
      this.getAllCustomers();
      this.processing = false;
    },
    async refreshPage() {
      this.processing = true;
      this.pgCurrentPage = 1;
      this.pgPerPage = parseInt(this.pgPerPage);
      await this.getAllCustomers();
      this.processing = false;
    },
    async exportTable() {
      this.processing = true;
      event.preventDefault();
      const dataExport = this.rowData.map(row => {
        return {"Email/Phone": row.email, "VIP": row.VIPList, "reviewUser": row.reviewList}
      });
      if (!dataExport) {
        console.error("No data to export");
        return;
      }
      let csv = unparse(
          dataExport,
          Object.assign(
              {
                header: true,
                delimiter: ",",
                encoding: this.encoding
              },
              this.advancedOptions
          )
      );
      if (this.separatorExcel) {
        csv = "SEP=" + "," + "\r\n" + csv;
      }
      //Add BOM when UTF-8
      if (this.encoding === "utf-8") {
        csv = "\ufeff" + csv;
      }
      const blob = new Blob([csv], {
        type: "text/csv;charset=" + this.encoding
      });
      saveAs(blob, "Customer List");
      this.processing = false;
    },
    async filterCustomerData(filterData) {
      //check filter data array filterOn empty stage
      this.processing = true;
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/customerlist/customer?email=${filterData.searchString}`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        }
      });
      const filteredCustomers = [];

      for (const customers of response.data) {
        for (const filters of filterData.filterOn) {
          if (customers[filters].match(filterData.searchString)) {
            filteredCustomers.push(customers);
            break;
          }
        }
      }

      this.$store.commit("setDeliverySMSTemplate", filteredCustomers);
      this.rowData = response.data;
      this.pgTotalRows = filteredCustomers.length;
      this.pgCurrentPage = 1;
      this.processing = false;
    },

    async addCustomer() {
      this.processing = true;
      event.preventDefault();
      //For adding an email to the list
      try {
        await this.axios({
          method: "PUT",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/customerlist/customer`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: {
            email: this.form.email,
            reviewList: (this.form.reviewList === "Review List") ? true : "",
            VIPList: (this.form.reviewList === "Review List") ? "" : true
          }
        }).then(response => {
          if (response && response.data) {
            if (response.data.msg) {
              this.result = response.data.msg;
            } else {
              this.result = "success";
            }
          } else {
            this.result = "failed";
          }
        });
        this.processing = false;
        this.onReset();
        await this.getAllCustomers();
      } catch (error) {
        this.processing = false;
        this.notificationMessage = "Oops, Something isn't right!!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },

    async deleteCustomer(event) {
      try {
        this.$bvModal
            .msgBoxConfirm(
                `Please confirm that you want to delete this customer: ${event.email}.`,
                {
                  title: "Delete Customer",
                  okVariant: "danger",
                  okTitle: "Yes",
                  cancelTitle: "No",
                  footerClass: "p-2",
                  hideHeaderClose: false
                }
            )
            .then(async status => {
              if (status) {
                const response = await this.axios({
                  method: "DELETE",
                  url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/customerlist/customer`,
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.idToken}`
                  },
                  data: {
                    email: event.email
                  }
                });
                this.displayMessage("success", response.data["message"]);
                await this.getAllCustomers();
              }
            });
      } catch (errorResponse) {
        this.errorDeleteCustomer();
        console.error("Error: ", errorResponse);
      }
    },

    async getKlvSubStatusByEmail(email) {

      try {

        const headerConfig = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        }

        const baseURL = `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/klaviyo/customer/profile?email=${email}`;

        const response = await this.axios.get(baseURL, headerConfig);

        if (response.data) {
          return (response.data && response.data !== "") ? response.data : null;
        } else {
          return null;
        }
      } catch (errorMessage) {

        console.log(`Error on getKlvSubStatusByEmail :: ${errorMessage}`)
        return null;
      }
    },

    async getAllCustomers() {

      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/customerlist/customer`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        }
      });
      this.pgTotalRows = parseInt(response.data.length);
      this.rowData = response.data;
    },

    async updateCustomer(event) {
      try {
        this.$bvModal
            .msgBoxConfirm(
                `Which list would you like this customer to be on? ${event.email}.`,
                {
                  title: "Edit Customer",
                  okVariant: "primary",
                  okTitle: "VIP list",
                  cancelTitle: "Review list",
                  footerClass: "p-2",
                  hideHeaderClose: false
                }
            )
            .then(async status => {
              if (status !== null) {
                const response = await this.axios({
                  method: "POST",
                  url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/customerlist/customer`,
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.idToken}`
                  },
                  data: {
                    email: event.email,
                    reviewList: (status) ? "" : true,
                    VIPList: (status) ? true : ""
                  }
                });
                this.displayMessage("success", response.data["message"]);
                await this.getAllCustomers();
              }
            });
      } catch (errorResponse) {
        this.errorEditCustomer();
        console.error("Error: ", errorResponse);
      }
    },

    async unsubscribeEmail(email) {

      try {

        const headerConfig = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        }

        const body = {
          email: email
        }

        const baseURL = `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/klaviyo/customer/unsubscribe`;

        await this.axios.post(baseURL, body, headerConfig);

        this.displayMessage("success", "Successfully unsubscribed");
      } catch (e) {
        console.log('Error: ', e);
        this.displayMessage("warning", "Unsubscription Failed! for this customer email.");
      }
    },

    async subscribeEmail(email) {

      try {

        const headerConfig = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        }

        const body = {
          email: email
        }

        const baseURL = `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/klaviyo/customer/subscribe`;

        await this.axios.post(baseURL, body, headerConfig);

        this.displayMessage("success", "Successfully re-subscribed");
      } catch (e) {
        console.log('Error: ', e);
        this.displayMessage("warning", "Subscription Failed! for this customer email.");
      }
    },

    async editSubscription(event) {

      const currentSubStatus = await this.getKlvSubStatusByEmail(event.email)

      try {
        this.$bvModal
            .msgBoxConfirm(
                `Would you like to update ${event.email}  subscription status?. current status: ${(currentSubStatus && currentSubStatus === 'SUBSCRIBED') ? 'Subscribed' : 'Unsubscribed'}`,
                {
                  title: "Update Subscription Status",
                  okVariant: "primary",
                  okTitle: (currentSubStatus && currentSubStatus === 'SUBSCRIBED') ? 'Unsubscribed' : 'Subscribed',
                  cancelTitle: "No",
                  footerClass: "p-2",
                  hideHeaderClose: false
                }
            )
            .then(async status => {
              if (status) {
                if (currentSubStatus && currentSubStatus === 'SUBSCRIBED') {
                  await this.unsubscribeEmail(event.email)
                } else {
                  await this.subscribeEmail(event.email)
                }
              }
            });
      } catch (errorResponse) {
        this.displayMessage("warning", "Update Subscription Process Failed!");
        console.error("Error: ", errorResponse);
      }
    },

    async refreshGrid(message) {
      await this.getAllTemplate();
      this.displayMessage("success", message);
    },

    errorDeleteCustomer() {
      this.displayMessage("warning", "Customer email/phone delete process failed.");
    },
    errorEditCustomer() {
      this.displayMessage("warning", "Customer email/phone update process failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("success", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    }
  }
};
</script>

<style>
.action-btn {
  background-color: #17a2b8;
  border: none;
}

.radio-btn {
  position: absolute;
  width: 1rem;
  margin-left: 0.4rem;
  margin-top: 0.3rem;
}

.radio-group {
  display: inline-flex;
}

.padding-left-1 {
  padding-left: 1rem;
}

.padding-left-2 {
  padding-left: 2rem;
}

.submit-btns {
  float: right;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
</style>
