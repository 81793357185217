<template>
  <div>
    <CLAlert :show="alert.status" :color="alert.color" :message="alert.message"/>

    <div class="container-fluid p-0" id="order-reports">
      <div v-if="showProgress" class="progress-flex">
        <div class="progress-container">
          <CLProgress :value="progressBarWidth" animated/>
          <p class="mt-2 text-center">Exporting file. Please wait</p>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Callia labels" subTopic="Curated Gift Box"/>
          <p class="mt-4 mb-0">All fields are required</p>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-8 col-xl-6 col-12">
          <div class="form-card">
            <b-form id="order-report-form">

              <b-form-group>
                <label class="mt-2 report-label">Exported file name</label>
                <b-form-input
                  v-model="form.fileName"
                  type="text"
                  @keyup="validateFileName"
                  placeholder="exportFileName"
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-3 report-label">Orders per file (max)</label>
                <b-form-input
                  v-model="form.maxSize"
                  type="text"
                  @keyup="validateMaxSize"
                  placeholder="300"
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-2 report-label">Excel file</label>
                <b-form-file
                  v-model="form.file"
                  placeholder="Choose a file"
                  class="input-height"
                ></b-form-file>
              </b-form-group>

              <CLButton
                class="mt-3"
                format="primary-btn"
                v-on:func="onSubmit"
                :disabled="!submitBtnValidate"
              >
                Export file
              </CLButton>

            </b-form>
          </div>
        </div>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>
<script>
import {CLProgress, CLHeader, CLButton, CLAlert} from 'callia-ui-box';
import {v4 as uuid} from 'uuid';

export default {
  components: {
    CLProgress,
    CLHeader,
    CLButton,
    CLAlert
  },
  data() {
    return {
      /** initialize form fields */
      form: {
        fileName: '',
        maxSize: '300',
        file: [],
      },
      /** initialize progress bar */
      progressBarWidth: 0,
      showProgress: false,
      /** initialize upload unique process id */
      processId: uuid(),
      /** initialize alert box */
      alert: {
        status: false,
        color: '',
        message: ''
      },
    }
  },
  methods: {
    /** form submit event */
    async onSubmit() {
      await this.excelToPdfExporter()
      await this.loadProgressBar()
    },
    /** form filed validation */
    validateFileName(event) {
      const string = event.target.value
      this.form.fileName = string.replace(/\s+/g, "")
    },
    validateMaxSize() {
      const maxsize = event.target.value
      if (!maxsize.match(/^[1-9]+[0-9]*$/g)) {
        this.form.maxSize = ''
      }
    },
    /** excel to pdf upload main handler */
    async excelToPdfExporter() {
      try {
        this.alert.status = false
        this.showProgress = true

        this.processId = uuid()
        const values = {
          processId: this.processId,
          exportFileName: this.form.fileName,
          maxSize: this.form.maxSize,
          file: this.form.file
        }

        const formData = new FormData()
        for (const key in values) {
          formData.append(key, values[key])
        }

        await this.axios({
          method: "post",
          url: 'https://excel2pdf.callia.com/labels/uploadEshipper',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        })
      } catch (errorResponse) {
        this.alert = {
          color: 'warning',
          message: 'File exported failed!',
          status: true
        }
        setTimeout(() => {
          this.alert.status = false
        }, 5500)
      }
    },
    /** form refresh */
    refreshPage() {
      this.showProgress = false
      this.progressBarWidth = 0
      this.alert.status = false
      this.form.fileName = ''
      this.form.maxSize = '300'
      this.form.file = []
      this.processId = uuid()
    },
    /** Load progressbar and calculate progress event  */
    async loadProgressBar() {
      let tempProgressValue = 0
      const progressRunner = setInterval(() => {
        try {
          this.axios.get(
            "https://excel2pdf.callia.com/labels/query?processId=" + this.processId,
          )
            .then(response => {
              if (tempProgressValue < parseInt(response.data['percentage'])) {
                this.progressBarWidth = parseInt(response.data['percentage'])
              }
              if (parseInt(response.data['percentage']) == 100) {
                this.refreshPage()
                this.alert = {
                  color: 'success',
                  message: 'File exporting successfully done',
                  status: true
                }
                setTimeout(() => {
                  this.alert.status = false
                }, 5500)
                clearInterval(progressRunner)
              } else {
                if (parseInt(this.progressBarWidth) < 100) {
                  this.progressBarWidth += 1
                }
              }
              tempProgressValue = this.progressBarWidth
            })
        } catch (errorResponse) {
          this.showProgress = false
        }
      }, 1200)
    },
  },
  computed: {
    /** Submit button disable status validation by all field required. */
    submitBtnValidate() {
      if (this.form.fileName.trim() && this.form.maxSize &&
        this.form.file.name && /^[0-9]+$/.test(this.form.maxSize) && this.form.file.name.includes('.xlsx')) {
        return true
      } else {
        return false
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
@import 'callia-ui-box/src/assets/scss/styles.scss';

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

#order-reports {
  position: relative;
}
</style>