<template>
  <div>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0" id="order-reports">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Order</strong> reports</h3>
          <p class="mt-4 mb-0">Report name and report type are required</p>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-8 col-xl-8 col-12">
          <div class="form-card">
            <b-form id="order-report-form">
              
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Report type</label>
                    <b-form-select
                      v-model="form.reportType"
                      :options="typesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Report name</label>
                    <b-form-input
                      v-model="form.reportName"
                      type="text"
                      placeholder="ORDER_REPORT_EXAMPLE"
                      class="input-height mt-1 upper"
                    ></b-form-input>
                    <div class="input-box">
                      <p v-show="checkRepName" class="input-alert mb-0 mt-0">
                        Special characters are not allowed
                      </p>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Delivery Window Restrict</label
                    >
                    <b-form-checkbox
                      v-model="deliveryWindowRestrict"
                      value="true"
                      unchecked-value="false"
                    ></b-form-checkbox>
                  </b-form-group>
                </div>
                <div class="col-sm" v-if="form.reportType == 'ORDER_REPORT_NON_FLORAL_GIFTSETS'">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Excluding regions (For Canada Giftset Report, you can check this. For Montreal, uncheck this)</label
                    >
                    <b-form-checkbox
                      v-model="excludingRegions"
                      value="true"
                      unchecked-value="false"
                    ></b-form-checkbox>
                  </b-form-group>
                </div>
              </div>

              <b-form-group class="mt-2">
                <p class="report-label mb-2">
                  Regions <span>(Do not need to select for Error Report, Not Pulled Report and Not Sent Report)</span>
                </p>
                <div class="row">
                  <div
                    v-for="(region, index) in regionsOpt"
                    :key="index"
                    class="col-md-6 col-lg-4 col-6"
                  >
                    <b-form-checkbox
                      :value="region.value"
                      v-model="form.deliveryRegion"
                      >{{ region.text }}</b-form-checkbox
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group v-if="form.reportType == 'ORDER_REPORT_V_DAY'">
                <label class="mt-0 report-label">Delivery date</label>
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  v-model="deliveryDate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group>
                <label class="mt-0 report-label">Description</label>
                <b-form-input
                  v-model="form.description"
                  type="text"
                  placeholder="Description"
                  class="input-height mt-1 upper"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-0 report-label"
                  >Default Delivery Start Time(12:00PM)</label
                >
                <b-form-input
                  v-model="form.defaultDeliveryStartTime"
                  type="text"
                  placeholder="Default Delivery Start Time(12:00PM)"
                  class="input-height mt-1 upper"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-0 report-label"
                  >Skipped Tag</label
                >
                <b-form-input
                  v-model="form.skippedTag"
                  type="text"
                  placeholder="Please input tag name"
                  class="input-height mt-1 upper"
                ></b-form-input>
              </b-form-group>

              <b-button
                type="button"
                variant="primary"
                class="submit-button"
                v-if="enableButton"
                @click="genReport"
              >
                Submit report
              </b-button>
              <b-button type="button" class="disabled-button" disabled v-else>
                Submit report
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";
import { uuid } from "vue-uuid";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {
      deliveryDate: "",
      deliveryWindowRestrict: "false",
      excludingRegions: "false",
      form: {
        uuid: uuid.v4(),
        reportName: "",
        description: "",
        reportType: "",
        errorCheckReport: false,
        fileType: "excel",
        deliveryDateFilterIncluded: true,
        noOfDaysToDeliveryDate: 0,
        deliveryDate: "",
        filtersForRestUrl: {
          fulfillmentStatus: "unshipped",
          fields:
            "id,name,note,discount_codes,line_items,shipping_address,shipping_lines,tags,created_at",
          status: "open",
        },
        deliveryRegion: [],
        excludingRegion: [],
        provinceCode: "",
        deliveryWindowExcluded: false,
        deliveryWindow: ["(1pm - 6pm)", "(1pm - 5pm)", "(3pm - 6pm)", "(3pm - 7pm)"],
        createAtIncluded: false,
        hours: 0,
        minutes: 0,
        defaultDeliveryStartTime: "",
        skippedTag: "",
        sortHeaderName: "",
        consolidateOrder: false,
      },

      alert: {
        error: false,
        message: "",
      },

      regionsOpt: [
        { text: "Halifax", value: "Halifax" },
        { text: "Maritimes", value: "Maritimes" },
        { text: "Québec_City", value: "Québec_City" },
        { text: "Montreal", value: "Montreal" },
        { text: "Montreal_Surrounding", value: "Montreal_Surrounding" },
        { text: "Ottawa", value: "Ottawa" },
        { text: "Kingston", value: "Kingston" },
        { text: "Hamilton", value: "Hamilton" },
        { text: "Toronto", value: "Toronto" },
        { text: "Kitchener_Waterloo", value: "Kitchener_Waterloo" },
        { text: "London", value: "London" },
        { text: "Really_Rural_MB", value: "Really_Rural_MB" },
        { text: "Rural_MB", value: "Rural_MB" },
        { text: "Winnipeg_Surrounding", value: "Winnipeg_Surrounding" },
        { text: "Winnipeg", value: "Winnipeg" },
        { text: "Regina", value: "Regina" },
        { text: "Saskatoon", value: "Saskatoon" },
        { text: "Southern_AB", value: "Southern_AB" },
        { text: "Calgary", value: "Calgary" },
        { text: "Edmonton", value: "Edmonton" },
        { text: "Northern_AB", value: "Northern_AB" },
        { text: "Okanagan", value: "Okanagan" },
        { text: "Kelowna_Surrounding", value: "Kelowna_Surrounding" },
        { text: "Kelowna", value: "Kelowna" },
        { text: "Surrounding_VAC", value: "Surrounding_VAC" },
        { text: "Langley", value: "Langley" },
        { text: "Vancouver", value: "Vancouver" },
        { text: "Victoria", value: "Victoria" },
      ],

      typesOpt: [
        { text: "Select an option", value: null },
        { text: "Same day", value: "ORDER_REPORT_SAME_DAY" },
        { text: "Next day", value: "ORDER_REPORT_NEXT_DAY" },
        { text: "Specific day", value: "ORDER_REPORT_V_DAY" },
        { text: "Curated Gift Box", value: "ORDER_REPORT_NON_FLORAL_GIFTSETS" },
        { text: "Error Report", value: "ERROR_REPORT" },
        { text: "Not Pulled Report", value: "NOT_PULLED_REPORT" },
        { text: "Not Sent Report", value: "NOT_SENT_REPORT" },
        { text: "Maximum Report", value: "ORDER_REPORT_MAXIMUM" },
        { text: "Flashbox Report", value: "ORDER_REPORT_FLASHBOX" },
      ],
    };
  },
  async created() {
    if (this.$route.params.editedItem) {
      this.form = this.$route.params.editedItem;
      if (
        this.form.reportType === "ORDER_REPORT_NEXT_DAY" ||
        this.form.reportType === "ORDER_REPORT_MAXIMUM" ||
        this.form.reportType === "ORDER_REPORT_FLASHBOX"
      ) {
        this.form.noOfDaysToDeliveryDate = 1;
        if (this.form.deliveryWindowExcluded === true) {
          this.deliveryWindowRestrict = "true";
        }
      }
    }
    await this.axios
      .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/regions", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.idToken,
        }
      })
      .then((response) => {
        if (response.data && response.data.records) {
          this.regionsOpt = [];
          for (const data of response.data.records) {
            const region = {
              text: data.name + "(" + data.province_code + ")",
              value: data.tag,
              provinceCode: data.province_code,
            };
            this.regionsOpt.push(region);
          }
        }
      });
  },
  methods: {
    async genReport() {
      this.form.reportName = this.form.reportName
        .trim()
        .replace(/\s/g, "_")
        .toUpperCase();
      if (this.form.deliveryRegion.length > 0) {
        const region = this.form.deliveryRegion[0];
        this.form.provinceCode = (
          this.regionsOpt.find((t) => {
            return t.text.toLowerCase() === region.toLowerCase();
          }) || {}
        ).provinceCode;
      }

      if (this.form.reportType === "ORDER_REPORT_V_DAY" || 
          this.form.reportType === "ORDER_REPORT_SAME_DAY" || 
          this.form.reportType === "ORDER_REPORT_NEXT_DAY") {
        this.form.sortHeaderName = "Recipient_Name";
        this.form.consolidateOrder = true;
      }

      if (this.form.reportType === "ORDER_REPORT_V_DAY") {
        this.form.deliveryDateFilterIncluded = false;
        this.form.deliveryDate = dayjs(this.deliveryDate, "YYYY-MM-DD")
          .tz("America/Winnipeg")
          .format("MM/DD/YYYY");
      }
      if (this.form.reportType === "ORDER_REPORT_SAME_DAY") {
        this.form.noOfDaysToDeliveryDate = 0;
      } else if (
        this.form.reportType === "ORDER_REPORT_NEXT_DAY" ||
        this.form.reportType === "ORDER_REPORT_MAXIMUM"
      ) {
        this.form.noOfDaysToDeliveryDate = 1;
        if (this.deliveryWindowRestrict === "true") {
          this.form.deliveryWindowExcluded = true;
        }
      } else if (this.form.reportType === "ERROR_REPORT") {
        this.form.errorCheckReport = true;
        this.form.deliveryDateFilterIncluded = false;
      }
      if (this.form.reportType === "ORDER_REPORT_NON_FLORAL_GIFTSETS") {
        this.form.filtersForRestUrl.fields = "id,name,note,discount_codes,line_items,billing_address,shipping_address,shipping_lines,tags,created_at";
        if (this.excludingRegions === "true") {
          this.form.excludingRegion = this.form.deliveryRegion;
          this.form.deliveryRegion = [];
        }
      }

      await this.axios.post(
        process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/config",
        this.form,
        {
          headers: {
            Authorization: "Bearer " + this.$store.getters.idToken,
          },
        }
      );

      this.alert = {
        message: "Order report submitted successfully",
        error: false,
        success: true,
      };
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    },
  },
  computed: {
    checkRepName() {
      if (
        this.form.reportName &&
        !/^[0-9A-Za-z_ ]+$/.test(this.form.reportName)
      ) {
        return true;
      }
      return false;
    },
    enableButton() {
      if (
        (this.form.reportType === "ERROR_REPORT" ||
          this.form.reportType === "NOT_PULLED_REPORT" ||
          this.form.reportType === "NOT_SENT_REPORT" ||
          this.form.deliveryRegion.length > 0) &&
        this.form.reportType &&
        this.form.reportName &&
        /^[0-9A-Za-z_ ]+$/.test(this.form.reportName)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.upper {
  text-transform: uppercase;
}

#checkbox-group,
.custom-select a:hover,
a:active,
a:focus {
  outline: 0;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>
