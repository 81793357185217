<template>
  <b-modal
      id="modal-add-zone-delivery-rate"
      ref="modal"
      title="Add New Zone Delivery Rate"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-variant="primary primary-btn"
      ok-title="Add"
      cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Name"
          label-for="name"
          invalid-feedback="Name is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="name"
            v-model="name"
            readonly
            :state="validateName"
            @keyup="keyupValidationName"
            required
        ></b-form-input>
        <p v-if="isHidden=(this.deliveryRateModel && this.zoneModel)? false:true" class="text-info">* Fill the zone and
          delivery rate fields below to generate name.</p>
      </b-form-group>
      <b-form-group
          label="Province"
          label-for="province"
          invalid-feedback="Choose a Province"
      >
        <v-select
            id="province"
            :options="provinceOptions"
            placeholder="choose..."
            v-model="provinceModel"
            :clearable="true"
            :class="validateProvince"
            @input="provinceSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!provinceModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="Region"
          label-for="region"
          invalid-feedback="Choose a Region"
      >
        <v-select
            id="region"
            :options="regionOptions"
            placeholder="choose..."
            v-model="regionModel"
            :clearable="true"
            :class="validateRegion"
            @input="regionSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!regionModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="Zone"
          label-for="zone"
          invalid-feedback="Choose a Zone"
      >
        <v-select
            id="zone"
            :options="zoneOptions"
            placeholder="choose..."
            v-model="zoneModel"
            :clearable="true"
            :class="validateZone"
            @input="zoneSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!zoneModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="DeliveryRate"
          label-for="deliveryRate"
          invalid-feedback="Choose a DeliveryRate"
      >
        <v-select
            id="deliveryRate"
            :options="deliveryRateOptions"
            placeholder="choose..."
            v-model="deliveryRateModel"
            :clearable="true"
            :class="validateDeliveryRate"
            @input="deliveryRateSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!deliveryRateModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="ApplicableStartTime"
          label-for="applicableStartTime"
          invalid-feedback=""
      >
        <b-form-timepicker id="applicableStartTime" reset-button v-model="applicableStartTime"
                           locale="en"></b-form-timepicker>
        {{ applicableStartTime }}
      </b-form-group>
      <b-form-group
          label="ApplicableEndTime"
          label-for="applicableEndTime"
          invalid-feedback=""
      >
        <b-form-timepicker id="applicableEndTime" reset-button v-model="applicableEndTime"
                           locale="en"></b-form-timepicker>
        {{ applicableEndTime }}
      </b-form-group>
      <b-form-group
          label="ApplicableDate"
          label-for="applicableDate"
          invalid-feedback=""
      >
        <v-select
            id="applicableDate"
            :options="applicableDateOptions"
            placeholder="choose..."
            v-model="applicableDateModel"
            :clearable="true"
            :class="validateApplicableDate"
            @input="applicableDateSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!applicableDateModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
          label="IsSameDayOnly"
          label-for="isSameDayOnly"
          invalid-feedback="Choose IsSameDayOnly"
      >
        <v-select
            id="isSameDayOnly"
            :options="isSameDayOnlyOptions"
            placeholder="choose..."
            v-model="isSameDayOnlyModel"
            :clearable="true"
            :class="validateZone"
            @input="isSameDayOnlySelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!isSameDayOnlyModel"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  props: ["zoneOptions", "deliveryRateOptions", "provinceOptions", "regionOptions"],
  name: "add-region-popup",
  data() {
    return {
      validateName: null,
      validateProvince: "",
      validateRegion: "",
      validateZone: "",
      validateDeliveryRate: "",
      validateApplicableDate: "",

      provinceModel: "",
      regionModel: "",
      zoneModel: "",
      deliveryRateModel: "",
      applicableStartTime: null,
      applicableEndTime: null,
      applicableDateModel: {value: 1, label: "1"},
      isSameDayOnlyModel: {value: false, label: "false"},

      applicableDateOptions: [
        {value: 0, label: "0"},
        {value: 1, label: "1"}
      ],

      isSameDayOnlyOptions: [
        {value: true, label: "true"},
        {value: false, label: "false"}
      ],

      name: "",

      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  methods: {
    clearRegionCMB() {
      this.validateRegion = "";
      this.regionModel = "";
    },
    clearZoneCMB() {
      this.validateZone = "";
      this.zoneModel = "";
      this.name = "";
    },
    clearDeliveryRateCMB() {
      this.validateDeliveryRate = "";
      this.deliveryRateModel = "";
    },
    clearApplicableDateCMB() {
      this.validateApplicableDate = "";
      this.applicableDate = "";
    },

    show() {
      this.$refs.modal.show();
    },
    provinceSelectedValue(event) {
      if (event !== null) {
        this.validateProvince = "";
      } else {
        this.validateProvince = "is-invalid";
      }
      this.clearRegionCMB()
      this.clearZoneCMB()
      this.clearApplicableDateCMB()
      this.$emit('changeProvince', event.value)
    },
    regionSelectedValue(event) {
      if (event !== null) {
        this.validateRegion = "";
      } else {
        this.validateRegion = "is-invalid";
      }
      this.clearZoneCMB()
      this.clearApplicableDateCMB()
      this.$emit('changeRegion', event.value)
    },
    zoneSelectedValue(event) {
      if (event !== null) {
        this.validateZone = "";
        this.name = event.label + " - " + (this.deliveryRateModel.label || "");
      } else {
        this.validateZone = "is-invalid";
      }
    },
    deliveryRateSelectedValue(event) {
      if (event !== null) {
        this.validateDeliveryRate = "";
        this.name = (this.zoneModel.label || "") + " - " + this.deliveryRateModel.label;
      } else {
        this.validateDeliveryRate = "is-invalid";
      }
    },
    applicableDateSelectedValue(event) {
      if (event !== null) {
        this.validateApplicableDate = "";
      } else {
        this.validateApplicableDate = "is-invalid";
      }
    },
    validateProvinceCMB() {
      if (this.provinceModel !== null) {
        if (this.provinceModel.value == null) {
          this.validateProvince = "is-invalid";
        } else {
          this.validateProvince = "";
        }
      } else {
        this.validateProvince = "is-invalid";
      }
    },
    validateRegionCMB() {
      if (this.regionModel !== null) {
        if (this.regionModel.value == null) {
          this.validateRegion = "is-invalid";
        } else {
          this.validateRegion = "";
        }
      } else {
        this.validateRegion = "is-invalid";
      }
    },
    validateZoneCMB() {
      if (this.zoneModel) {
        if (this.zoneModel.value == null) {
          this.validateZone = "is-invalid";
        } else {
          this.validateZone = "";
        }
      } else {
        this.validateZone = "is-invalid";
      }
    },
    validateDeliveryRateCMB() {
      if (this.deliveryRateModel !== null) {
        if (this.deliveryRateModel.value == null) {
          this.validateDeliveryRate = "is-invalid";
        } else {
          this.validateDeliveryRate = "";
        }
      } else {
        this.validateDeliveryRate = "is-invalid";
      }
    },
    validateApplicableDateCMB() {
      if (this.applicableDateModel !== null) {
        if (this.applicableDateModel.value == null) {
          this.validateApplicableDate = "is-invalid";
        } else {
          this.validateApplicableDate = "";
        }
      } else {
        this.validateApplicableDate = "is-invalid";
      }
    },
    keyupValidationName() {
      if (this.name !== "") {
        this.validateName = true;
      } else {
        this.validateName = false;
      }
    },
    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      //validate name
      if (this.$refs.form.name.checkValidity()) {
        this.validateName = true;
      } else {
        this.validateName = false;
      }

      this.validateProvinceCMB();
      this.validateRegionCMB();
      this.validateZoneCMB();
      this.validateApplicableDateCMB()

      return fullFormValidity;
    },
    resetModal() {
      this.validateProvince = "";
      this.validateRegion = "";
      this.validateZone = "";
      this.validateApplicableDate = "";
      this.name = "";
      this.validateName = null;
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const zoneDeliveryRatePostArray = {
          name: this.name,
          zoneId: this.zoneModel.value,
          deliveryRateId: this.deliveryRateModel.value,
          applicableStartTime: this.applicableStartTime,
          applicableEndTime: this.applicableEndTime,
          applicableDate: this.applicableDateModel.value,
          isSameDayOnly: this.isSameDayOnlyModel.value,
        };

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/zone/rate`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: zoneDeliveryRatePostArray
        });

        if (response.status == 201) {

          this.$nextTick(() => {
            this.$bvModal.hide("modal-add-zone-delivery-rate");
          });

          this.$emit("success", response.data['success']);
        } else {

          this.$emit("error", response.data['error']);
        }

      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>