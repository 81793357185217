<template>
  <div class="container-fluid p-0">
    <div class="row mb-2 mb-xl-3">
      <div class="col-auto d-none d-sm-block">
        <h3><strong>Delivery</strong> schedules</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
