<template>
  <div>
    <b-row class="py-3 text-center">
      <b-col offset-lg="4" lg="4" sm="12">
        <b-input-group size="sm">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
          ></b-form-input>

          <b-input-group-append>
            <b-icon icon="search" class="icon"></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col offset-lg="3" lg="6" sm="12">
        <b-form-group
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="cutOff">Cut-Off-Time</b-form-checkbox>
            <b-form-checkbox value="region">Region</b-form-checkbox>
            <b-form-checkbox value="city.cityName">City</b-form-checkbox>
            <b-form-checkbox value="effect">Effect</b-form-checkbox>
            <b-form-checkbox value="dayOfWeek">Day</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light">Type and press enter to filter data.
          </b-badge>
        </b-form-group>
      </b-col>
    </b-row>
    <b-col sm="5" md="6" class="my-1 selectPage">
          <overrideModal
              v-bind:selectedOverride="selectedOverride"
              ref="overrideModal"
          />
    </b-col>
    <b-row class="mt-3">
      <b-col lg="2" sm="6" class="left pr-0 mr-0">
        <h6 class="pagination-details">Per page
          <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
          ></b-form-select>
        </h6>
      </b-col>
      <b-col sm="7" md="4" class="my-1 right-float">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <!--    <b-col sm="7" md="6" class="my-1">-->
    <!--      <b-pagination-->
    <!--        v-model="currentPage"-->
    <!--        :total-rows="totalRows"-->
    <!--        :per-page="perPage"-->
    <!--        align="fill"-->
    <!--        size="sm"-->
    <!--        class="my-0"-->
    <!--      ></b-pagination>-->
    <!--    </b-col>-->
    <b-table
        :items="items"
        :fields="fields"
        striped
        empty-text="No Data Available"
        responsive="sm"
        :per-page="perPage"
        class="table"
        :busy="isBusy"
        :current-page="currentPage"
        outlined
        sticky-header="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner
            type="grow"
            variant="info"
            label="Loading..."
            class="align-middle"
          ></b-spinner>
          <strong class="loadingLabel">Loading...</strong>
        </div>
      </template>

      <template #cell(region)="data">
        <div class="cityModel">
          <b-button @click="data.toggleDetails" @focus="getSelectedRegion(data.item.region_id, data.item.region)"
                    class="citybtn mr-2">
            {{ data.item.region }}
            <b-icon class="dropDownIcon right-float" icon="caret-down-fill"></b-icon>
          </b-button>
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        </div>
      </template>

      <template #row-detail="data">
        <b-card>
          <ul>
            <li v-for="(value, key) in data.items" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>

      <template #cell(action)="data">
        <div class="middle">
          <b-button @click="overrideData(data.item)" class="Overide-btn">
            View Dates Closed
          </b-button>
        </div>
      </template>

      <template #cell(province)="data">
        <div class="province">
          {{ data.item.province }}
        </div>
      </template>

      <template #row-details="data">
        <div>
          <b-card>
            <b-row align="center">
              <b-col cols="12">
                <label><strong>Default Cutoffs</strong></label>
                &nbsp;&nbsp;&nbsp;
                <b-button class="btn btn-success" size="sm" @click="addNew">
                  Add New
                </b-button>
              </b-col>
            </b-row>
            <b-table
              :items="data.item.default_cutoffs"
              :fields="innerField"
              class="middle"
              empty-text="No Data Available"
              show-empty
              striped
              responsive="sm"
              sticky-header="true"
              hover
            >
              <template #cell(DayOfWeek)="data">
                <div class="dayOfWeek">
                  {{ data.item.day_of_week }}
                </div>
              </template>

              <template #cell(cutOffTime)="data">
                <div class="cutofftime">
                  {{ data.item.cut_off_time }}
                </div>
              </template>

              <template #cell(effect)="data">
                <b-badge
                  :class="
                    data.item.effect === 'SAME_DAY' ? 'Same-Day' : data.item.effect === 'NEXT_DAY'?
                     'Next-Day' : 'Next-Day-plus'
                  ">
                  {{
                    data.item.effect === "SAME_DAY" ? "Same-Day" : data.item.effect === "NEXT_DAY"?
                  "Next-Day" : data.item.effect === "NEXT_DAY_1"? "Next-Day + 1": data.item.effect === "NEXT_DAY_2"?
                  "Next-Day + 2": "Next-Day + 3"
                  }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <edit-icon
                  size="1.5x"
                  class="custom-class"
                  @click="editData(data.item)"
                ></edit-icon>
                <delete-icon
                  size="1.5x"
                  class="custom-class"
                  @click="deleteData(data.item)"
                ></delete-icon>
                <x-icon size="1.5x" class="custom-class" id="x-icon"></x-icon>
              </template>
            </b-table>
            <b-button class="hideDetails" size="sm" @click="data.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
          <editModal ref="modalComponent" v-bind:selectedId="selectedId"
            v-bind:selectedRegion="selectedRegion"
            v-bind:selectedRegionId="selectedRegionId"
            @refreshPage="loadData"
          />
        </div>
        <div>
          <b-card>
            <b-row align="center">
              <b-col cols="12">
                <label><strong>Override Cutoffs</strong></label>
                &nbsp;&nbsp;&nbsp;
                <b-button class="btn btn-success" size="sm" @click="addNewOverride">
                  Add New
                </b-button>
              </b-col>
            </b-row>
            <b-table
              :items="data.item.override_cutoffs"
              :fields="overrideInnerField"
              class="middle"
              empty-text="No Data Available"
              show-empty
              striped
              responsive="sm"
              sticky-header="true"
              hover
            >
              <template #cell(availableDate)="data">
                <div class="availableDate">
                  {{ data.item.available_date }}
                </div>
              </template>

              <template #cell(cutOffTime)="data">
                <div class="cutofftime">
                  {{ data.item.cut_off_time }}
                </div>
              </template>

              <template #cell(effect)="data">
                <b-badge :class="data.item.effect == 0 ? 'Same-Day' : data.item.effect == 1 ?
                  'Next-Day' : 'Next-Day-plus'">
                  {{
                    data.item.effect == 0 ? "Same-Day" : data.item.effect == 1 ?
                  "Next-Day" : data.item.effect == 2 ? "Next-Day + 1": data.item.effect == 3 ?
                  "Next-Day + 2": "Next-Day + 3"
                  }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <edit-icon
                  size="1.5x"
                  class="custom-class"
                  @click="editOverrideData(data.item)"
                ></edit-icon>
                <delete-icon
                  size="1.5x"
                  class="custom-class"
                  @click="deleteOverrideData(data.item)"
                ></delete-icon>
                <x-icon size="1.5x" class="custom-class" id="x-icon"></x-icon>
              </template>
            </b-table>
            <b-button class="hideDetails" size="sm" @click="data.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
          <editOverrideModal ref="modalOverrideComponent" v-bind:selectedId="selectedId"
            v-bind:selectedRegion="selectedRegion"
            v-bind:selectedRegionId="selectedRegionId"
            @refreshPage="loadData"
          />
        </div>
      </template>
    </b-table>
    <b-row class="mt-3">
      <b-col lg="2" sm="6" class="left pr-0 mr-0">
        <h6 class="pagination-details">Per page
          <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
          ></b-form-select>
        </h6>
      </b-col>
      <b-col sm="7" md="4" class="my-1 right-float">
        <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {EditIcon, XIcon, DeleteIcon} from "vue-feather-icons";
import editModal from "../cutoffedits/cutoff-edit-modal.vue";
import editOverrideModal from "../cutoffedits/override-cutoff-edit-modal.vue";
import overrideModal from "../viewoverrides/view-overrides-modal.vue";

export default {
  data() {
    return {
      fields: [
        "region",
        {province: "Province"},
        {action: "Action"}
      ],
      innerField: [
        {DayOfWeek: "DayOfWeek"},
        {cutOffTime: "CutOffTime"},
        {effect: "Effect"},
        {actions: "Action"}
      ],
      overrideInnerField: [
        {availableDate: "AvailableDate"},
        {cutOffTime: "CutOffTime"},
        {effect: "Effect"},
        {actions: "Action"}
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      selectedId: [],
      selectedOverride: [],
      pageOptions: [5, 10, 15, {value: 100, text: "Show More"}],
      isBusy: true,
      items: [],
      filter: null,
      filterOn: [],
      sortBy: "",
      selectedRegion: "",
      selectedRegionId: ""
    };
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editData(id) {
      this.$refs.modalComponent.show();
      this.selectedId = id;
    },
    editOverrideData(id) {
      this.$refs.modalOverrideComponent.show();
      this.selectedId = id;
    },
    overrideData(overrideid) {
      this.selectedOverride = overrideid;
      this.$refs.overrideModal.show();
    },
    getSelectedRegion(regionId, region) {
      this.selectedRegionId = regionId;
      this.selectedRegion = region;
    },
    async addNew() {
      this.$refs.modalComponent.show();
      this.selectedId = {
        cut_off_time: "15:55",
        effect: "NEXT_DAY",
        day_of_week: "",
        id: ""
      };
    },
    async addNewOverride() {
      this.$refs.modalOverrideComponent.show();
      this.selectedId = {
        cut_off_time: "15:55",
        effect: "1",
        available_date: "",
        id: ""
      };
    },
    async deleteData(event) {
      const h = this.$createElement;
      const messageVNode = h("div", {
        domProps: {
          innerHTML: `Please confirm that you want to delete this default cutoff:
          <strong>${this.selectedRegion}</strong>
          <strong>${event.day_of_week}</strong>
          <strong>${event.effect}</strong>`
        }
      });
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Delete default cutoff",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          html: true
        })
        .then(async status => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/cutoff/default/${event.id}`
            });
            this.loadData();
            this.displayMessage("success", response.data["success"]);
          }
        });
    },
    async deleteOverrideData(event) {
      const h = this.$createElement;
      const messageVNode = h("div", {
        domProps: {
          innerHTML: `Please confirm that you want to delete this cutoff:
          <strong>${this.selectedRegion}</strong> <strong>${event.available_date}</strong>.<br />
        Deleting this cutoff will <br />
          a) revert the cutoff to the default cutoff for that day of week (if we are usually open) or <br />
          b) close the date on Callia.com if it's a date we are normally closed in this region`
        }
      });
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Delete cutoff",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          html: true
        })
        .then(async status => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/dates-available-api/${event.id}`
            });
            this.loadData();
            this.displayMessage("success", response.data["success"]);
          }
        });
    },
    async loadData() {
      try {
        const res = await this.axios.get(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/cutoff/default/all",
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.idToken
            }
          }
        );
        const result = res.data;
        this.items = result;
        this.totalRows = this.items.length;
        this.isBusy = false;
      } catch (err) {
        console.error(err);
      }
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return {text: f.label, value: f.key};
        });
    }
  },
  components: {
    EditIcon,
    DeleteIcon,
    XIcon,
    editModal,
    editOverrideModal,
    overrideModal
  },
  async created() {
    this.loadData();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cutoff-time-grid.scss";

td div button .btn-seondary {
  width: 10rem;
}
</style>
