<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Greet" subTopic="List"/>
          <b-badge variant="light">Enter Order ID and Find Video Greet Information</b-badge>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-input-group class="mt-3">
              <b-form-input
                  id="orderId"
                  v-model="orderId"
                  :state="orderIdValidate"
                  @keyup="validateOrderID"
                  placeholder="Enter Order ID"
                  required
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="info" @click="handleSubmit" :disabled="viewButtonDisableStatus">View
                  Greet
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </form>

          <greet-info-popup
              ref="GreetInfoPopup"
              :result="result"
          />
        </div>
        <div class="col-lg-8 col-sm-5">

          <dl class="row bg-light pl-5 pt-4 pb-4 border-info" v-show="displayInfo">
            <dt class="col-sm-3">Order ID</dt>
            <dd class="col-sm-9" id="orderId">{{ this.dispOrderId }}</dd>

            <dt class="col-sm-3">Delivery Date</dt>
            <dd class="col-sm-9" id="deliveryDate">{{ this.deliveryDate }}</dd>

            <dt class="col-sm-3">Recipient</dt>
            <dd class="col-sm-9" id="recipient">{{ this.recipient }}</dd>

            <dt class="col-sm-3">Sender</dt>
            <dd class="col-sm-9" id="sender">{{ this.sender }}</dd>

            <dt class="col-sm-3">Video Upload URL</dt>
            <dd class="col-sm-9" id="videoUploadURL">
              <b-link class="h6 blink" :href="videoUploadURL" target="_blank" size="sm">{{videoUploadURL}}
              </b-link>
            </dd>

            <dt class="col-sm-3">Short URL</dt>
            <dd class="col-sm-9" id="shortURL">
              <b-link class="h6 blink" :href="shortURL" target="_blank">{{ shortURL }}</b-link>
            </dd>

            <dt class="col-sm-3">Status</dt>
            <dd class="col-sm-9" id="jobStatus">
              <b-badge variant="secondary">{{ this.jobStatus }}</b-badge>
            </dd>

          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.blink {
  color: #005ef7 !important;
  word-wrap: break-word;
}

a.blink:hover{
  color: #005ef7 !important;
}
</style>
<script>

import {CLHeader} from "callia-ui-box";

export default {
  components: {
    CLHeader,
  },
  data() {
    return {
      result: null,
      orderId: '',
      orderIdValidate: null,
      viewButtonDisableStatus: true,
      dispOrderId: '',
      deliveryDate: '',
      recipient: '',
      sender: '',
      videoUploadURL: '#',
      shortURL: '#',
      jobStatus: '',
      title: '',
      displayInfo: false
    }
  },
  methods: {

    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    validateOrderID() {
      const regExp = /^[0-9]+$/;

      if (this.orderId !== "" && this.orderId.match(regExp)) {

        this.orderIdValidate = true;
        this.viewButtonDisableStatus = false;
      } else {

        this.orderIdValidate = false;
        this.viewButtonDisableStatus = true;
      }
    },
    async handleSubmit() {
      try {

        const regExp = /^[0-9]+$/;

        if (!this.viewButtonDisableStatus && this.orderId !== "" && this.orderId.match(regExp)) {

          this.orderIdValidate = true;
          this.viewButtonDisableStatus = false;

          const response = await this.axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/greet/videoinfo/${this.orderId}/all`,
            headers: {
              Authorization: `Bearer ${this.$store.getters.idToken}`
            }
          });

          if (response.status == 200) {

            this.result = response.data[0];
            this.displayInfo = true;
            this.dispOrderId = `#${this.result.orderId}`
            this.title = `Greet Info for Order ID: ${this.orderId}`
            this.deliveryDate = this.result['orderDetails']['deliveryDate'];
            this.recipient = `${this.result['orderDetails'].recipient.firstName} ${this.result['orderDetails'].recipient.lastName}`;
            this.sender = `${this.result['orderDetails'].sender.firstName} ${this.result['orderDetails'].recipient.lastName}`;
            this.videoUploadURL = (this.result.videoUploadURL && this.result.videoUploadURL !== "") ? this.result.videoUploadURL : '#';
            this.jobStatus = (this.result['videoDetails'].jobStatus && this.result['videoDetails'].jobStatus !== "") ? this.result['videoDetails'].jobStatus : "";
            this.shortURL = (this.result['videoDetails'].shortURL && this.result['videoDetails'].shortURL !== "") ? this.result['videoDetails'].shortURL : '#';

          } else {

            this.displayInfo = false;
            this.displayMessage('warning', 'Greet Info Not Found!');
          }

        } else {

          this.orderIdValidate = false;
          this.viewButtonDisableStatus = true;
          this.displayInfo = false;

          this.displayMessage('info', 'Please enter valid orderId...');
        }
      } catch (errorResponse) {

        this.displayMessage('error', 'Alert! Processing Error');
      }
    }
  }
}
</script>