<template>
  <b-modal
    id="modal-batch-add-available-dates"
    ref="modal"
    title="Batch Add Available Dates"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    ok-variant="primary primary-btn"
    ok-title="Add"
    cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group class="mt-2">
        <p class="report-label mb-2">
          Available Dates(format: 2024-01-01)
        </p>
        <b-form-tags
          v-model="datesAvailable"
          type="text"
          placeholder="Enter New Available Dates"
          required
        ></b-form-tags>
      </b-form-group>

      <b-form-group class="mt-2">
        <p class="report-label mb-2">
          Regions
          <b-form-checkbox v-model="selectAll" @change="selectAllItems">
            Choose All
          </b-form-checkbox>
        </p>
        <div class="row mt-3">
          <div class="col-sm">
            <b-form-input
              v-model="cutoff_time"
              type="text"
              maxlength="10"
              placeholder="Cutoff Time"
              class="input-height mt-1"
            ></b-form-input>
          </div>
          <div class="col-sm">
            <b-form-select
              v-model="cutoff_effect"
              :options="cutoffEffectsOpt"
              class="input-height mt-1"
            ></b-form-select>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(region, index) in regionsOpt"
            :key="index"
            class="col-md-6 col-lg-4 col-6"
          >
            <b-form-checkbox
              :value="region.value"
              v-model="regions"
              @change="handleItemChange"
              >{{ region.text }}
            </b-form-checkbox>
          </div>
        </div>
        <p class="text-danger">
          {{ message }}
        </p>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>

export default {
  props: ["zoneOptions"],
  name: "add-town-popup",
  data() {
    return {
      datesAvailable: [],
      regions: [],
      regionsOpt: [],
      cutoff_time: "15:55",
      cutoff_effect: "1",
      cutoffEffectsOpt: [
        { text: "SameDay", value: "0" },
        { text: "NextDay", value: "1" },
        { text: "NextDay + 1", value: "2" },
        { text: "NextDay + 3", value: "3" },
        { text: "NextDay + 3", value: "4" },
      ],
      message: "",
      selectAll: false,
      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  async created() {
    await this.axios
      .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/places/region")
      .then((response) => {
        if (response?.data) {
          this.regionsOpt = [];
          for (const data of response.data) {
            const region = {
              text: data.region_name + "(" + data.short_code + ")",
              value: data.region_id,
            };
            this.regionsOpt.push(region);
          }
        }
      });
  },
  methods: {
    selectAllItems() {
      if (this.selectAll) {
        this.regions = this.regionsOpt.map(item => item.value);
      } else {
        this.regions = [];
      }
    },
    handleItemChange() {
      if (this.regions.length === this.regionsOpt.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    show() {
      this.$refs.modal.show();
    },
    checkFormValidity() {
      //validate code
      if (!this.datesAvailable || this.datesAvailable.length === 0) {
        this.message = "Please Input Available Dates";
        console.log(this.message);
        this.$emit("warning", this.message);
        return false;
      }
      if (!this.regions || this.regions.length === 0) {
        this.message = "Please Choose Regions";
        console.log(this.message);
        this.$emit("warning", this.message);
        return false;
      }
      this.message = "";
      return true;
    },
    resetModal() {
      this.datesAvailable = [];
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return;
        }
        this.$emit("message", "Adding ...");

        console.log(process.env.VUE_APP_API_ADMIN_API_ENDPOINT);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/dates-available-api`,
          {
            availableDates: this.datesAvailable,
            regions: this.regions,
            cutoffTime: this.cutoff_time,
            cutoffEffect: this.cutoff_effect,
          }
        );
        console.log(response.data);

        this.$nextTick(() => {
          this.$bvModal.hide("modal-batch-add-available-dates");
        });
        if (response.data.statusCode < 202) {
          this.$emit("success", "success");
        } else {
          this.$emit("error", response.data.body);
        }
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>
