import { getPriceByLocale } from "../utils/locale";
export default {
  methods: {
    isPhoneNumberValid(phone) {
      const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return regex.test(phone);
    },
    isEmailValid(email) {
      const regex = /^(?=^.{8,}$)[-_A-Za-z0-9]+([_.-][a-zA-Z0-9]+)*@[A-Za-z0-9]+([.-][a-zA-Z0-9]+)*\.[A-Za-z]{2,}$/;
      return regex.test(email);
    },
    isPostalCodeValid(postal) {
      if (this.$i18n.getLocaleCookie() === "en-us") {
        const regexUS = /^\d{5}(?:[-\s]\d{4})?$/;
        return regexUS.test(postal.trim());
      } else {
        const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
        return regex.test(postal.trim());
      }
    },
    formatPhoneNumber(phone) {
      if (!phone) return phone;

      const match = phone
        .replace(/[^\d]/g, "")
        .match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

      if (match && match.length > 3) {
        return `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
          match[3] ? "-" : ""
        }${match[3]}`;
      }
      return phone;
    },
    isBuzzerCodeValid(code) {
      return code ? /^[A-Za-z0-9#]{1,11}$/.test(code) : true; // Regular expression for a 10 -places alpha-numeric buzzer code
    },
    getPriceWithLocale(price) {
      return getPriceByLocale(this.$i18n.locale, Number(price));
    },
    capitalizeFirst(str) {
      if (!str || str === "") return;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
