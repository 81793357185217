<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle d-flex">
      <i @click="hideLeftMenu" class="hamburger align-self-center"></i>
    </a>
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <!-- <li class="nav-item dropdown">
          <notification-dropdown></notification-dropdown>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <message-dropdown></message-dropdown>
        </li> -->
        <li class="nav-item dropdown">
          <user-dropdown></user-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
// import NotificationDropDown from "@/components/notification-dropdown.vue";
// import MessageDropDown from "@/components/message-dropdown.vue";
import UserDropDown from "@/components/user-dropdown.vue";
import { eventBus } from "../../main";
export default {
  data: function() {
    return {
      hidenNavMenu: false
    };
  },
  components: {
    // "notification-dropdown": NotificationDropDown,
    // "message-dropdown": MessageDropDown,
    "user-dropdown": UserDropDown
  },
  methods: {
    hideLeftMenu() {
      this.hidenNavMenu = !this.hidenNavMenu;
      eventBus.hideLeftMenu(this.hidenNavMenu);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/navbar.scss";
@import "@/assets/scss/avatar.scss";
@import "@/assets/scss/hamburger.scss";
</style>
