<template>
  <div>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0" id="order-reports">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Quick</strong> reports</h3>
          <p class="mt-4 mb-0">
            Enter a region or select an option from the list
          </p>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-12">
          <div class="form-card">
            <b-form>
              <b-form-group
                class="mt-3 col-xs-12 col-md-7 col-lg-5 col-xl-5 p-0"
              >
                <b-form-input
                  v-model="form.region"
                  list="region"
                  placeholder="Search"
                  class="input-height"
                ></b-form-input>
                <b-form-datalist
                  id="region"
                  :options="options"
                ></b-form-datalist>
              </b-form-group>

              <div class="row mt-3">
                <div
                  v-for="(region, index) in regionsOpt"
                  :key="index"
                  class="col-12 mt-4"
                >
                  <p class="region-name">{{ region.name }}</p>

                  <div class="row mt-2">
                    <div
                      v-for="(type, ind) in region.types"
                      :key="ind"
                      class="col-xs-12 col-md-6 col-lg-4 col-xl-4"
                    >
                      <b-form-checkbox
                        :value="type.value"
                        v-model="form.regions"
                      >
                        <span class="highlight" :ref="getId(type.text)">
                          {{ type.text }}
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="buttons">
                <b-button
                  type="submit"
                  variant="primary"
                  class="submit-button submit"
                  v-if="enableButton"
                  @click="genReport"
                >
                  Submit report
                </b-button>
                <b-button
                  type="button"
                  class="disabled-button submit"
                  disabled
                  v-else
                >
                  Submit report
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </div>
</template>

<script>
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";

export default {
  components: {
    ErrorAlert,
    SuccessAlert
  },
  data() {
    return {
      isSelected: false,
      options: [],

      form: {
        region: "",
        regions: []
      },

      alert: {
        error: false,
        message: ""
      },

      regionsOpt: [
        {
          name: "Callia",
          types: [
            { text: "Callia error report", value: "Callia error report" },
            {
              text: "Callia error report (region)",
              value: "Callia error report (region)"
            },
            { text: "Not sent", value: "Not sent" },
            { text: "Not sent (next day)", value: "Not sent (next day)" },
            {
              text: "Not sent (region, email, total price)",
              value: "Not sent (region, email, total price)"
            }
          ]
        },
        {
          name: "Manitoba",
          types: [
            {
              text: "Manitoba same day 11:00am",
              value: "Manitoba same day 11:00am"
            },
            {
              text: "Manitoba same day 12:30pm",
              value: "Manitoba same day 12:30pm"
            },
            {
              text: "Manitoba next day 4:00pm",
              value: "Manitoba next day 4:00pm"
            },
            {
              text: "Manitoba Saturday 11:00am",
              value: "Manitoba Saturday 11:00am"
            },
            {
              text: "Manitoba Saturday 7:00pm",
              value: "Manitoba Saturday 7:00pm"
            }
          ]
        },
        {
          name: "Alberta",
          types: [
            {
              text: "Edmonton same day 11:00am",
              value: "Edmonton same day 11:00am"
            },
            {
              text: "Edmonton same day 12:30pm",
              value: "Edmonton same day 12:30pm"
            },
            {
              text: "Edmonton next day 4:00pm",
              value: "Edmonton next day 4:00pm"
            },
            {
              text: "Calgary next day 4:00pm",
              value: "Calgary next day 4:00pm"
            },
            {
              text: "Calgary same day 11:00am",
              value: "Calgary same day 11:00am"
            },
            {
              text: "Calgary same day 12:30pm",
              value: "Calgary same day 12:30pm"
            },
            {
              text: "Northern AB next day 12:30pm",
              value: "Northern AB next day 12:30pm"
            },
            {
              text: "Southern AB next day 11:00am",
              value: "Southern AB next day 11:00am"
            }
          ]
        },
        {
          name: "Ontario",
          types: [
            {
              text: "Hamilton next day 4:00pm",
              value: "Hamilton next day 4:00pm"
            },
            {
              text: "Hamilton same day 7:00am",
              value: "Hamilton same day 7:00am"
            },
            {
              text: "Toronto next day 4:00pm",
              value: "Toronto next day 4:00pm"
            },
            {
              text: "Toronto same day 7:00am",
              value: "Toronto same day 7:00am"
            },
            { text: "Ottawa next day 4:00pm", value: "Ottawa next day 4:00pm" },
            { text: "Ottawa same day 9:00am", value: "Ottawa same day 9:00am" },
            {
              text: "London next day 11:00am",
              value: "London next day 11:00am"
            }
          ]
        }
      ]
    };
  },
  methods: {
    genReport() {
      if (this.form.regions.length > 0) {
        console.log(this.form.regions);

        this.alert = {
          message: "Order report generated successfully",
          error: false,
          success: true
        };
      } else {
        this.alert = {
          message: "One or more options must be selected",
          success: false,
          error: true
        };
      }
    },
    getId(val) {
      const id = val
        .trim()
        .replace(/\s/g, "-")
        .toLowerCase();
      return id;
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    }
  },
  computed: {
    enableButton() {
      if (this.form.regions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    "form.region": {
      handler: function() {
        const reg = this.form.region.trim().toLowerCase();
        for (let i = 0; i < this.options.length; i++) {
          const id = this.getId(this.options[i]);
          if (reg.length && reg.length > 0) {
            if (this.options[i].toLowerCase().includes(reg)) {
              this.$refs[id][0].classList.add("highlight-active");
            } else {
              this.$refs[id][0].classList.remove("highlight-active");
            }
          } else {
            this.$refs[id][0].classList.remove("highlight-active");
          }
        }
      },
      deep: true
    },
    "form.regions": {
      handler: function() {
        console.log(this.form.regions);
      },
      deep: true
    }
  },
  beforeMount() {
    for (let i = 0; i < this.regionsOpt.length; i++) {
      for (let j = 0; j < this.regionsOpt[i].types.length; j++) {
        this.options.push(this.regionsOpt[i].types[j].text);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.region-name {
  font-size: 18px;
}

.highlight {
  position: relative;
  bottom: 2px;
  right: 4px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}

.highlight-active {
  background-color: $tags;
}

.upper {
  text-transform: uppercase;
}

#checkbox-group,
.custom-select a:hover,
a:active,
a:focus {
  outline: 0;
}

#tags {
  width: 100%;
  padding-bottom: 10px;
  min-height: 196px;

  ul {
    padding-left: 0 !important;
    display: inline;

    li {
      display: inline-block;
      width: auto;
      list-style: none;
      background-color: $tags !important;
      padding: 5px 14px 3px 15px !important;
      border-radius: 50px;
      font-size: 13px;
      font-weight: 600;
      color: #fff;
    }

    li {
      margin: 10px 10px 0 0;
    }

    .close-tag {
      position: relative;
      bottom: 3px;
      background-color: transparent !important;
      padding: 2px 2px 2px 1px;
      border: 0;
      margin-left: 8px !important;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.submit {
  margin-top: 37px;
}

.green {
  color: $callia-green;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>
