function install(Vue, axios, enableGlobalAuth) {
    Vue.prototype.$calliaAxios = async (props) => {
        props.headers = {
            ...props.headers,
            "Authorization": `Bearer ${Vue.prototype.$auth.getTokens().accessToken}`,
            "callia-lang": process.env.VUE_APP_PROJECT_CALLIA_LANG || 'en-CA'
        }
        return await axios(props);
    };

    Vue.prototype.$axios = axios;

    if (enableGlobalAuth) {
        axios.interceptors.request.use(
            function (config) {
               if (config.url && config.url.startsWith(process.env.VUE_APP_API_ADMIN_API_ENDPOINT)) {
                    config.headers["Authorization"] = `Bearer ${Vue.prototype.$auth.getTokens().accessToken}`;
                    config.headers["callia-lang"] = process.env.VUE_APP_PROJECT_CALLIA_LANG || 'en-CA';
                }

                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    console.log("init-callia-axios");
}

export default { install }
