<template>
  <div class="container-fluid p-0">
    <b-alert
        :show="notification.showErrorCountDown"
        dismissible
        fade
        :variant="notification.type"
    >
      {{ notificationMessage }}
    </b-alert>
    <h3><strong>Mass</strong> Adjust Tags</h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner
                  type="grow"
                  variant="info"
                  label="Loading..."
              ></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>

          <div class="row">
            <div class="col-auto">
              <p class="mt-3 mb-0">Order Ids Format: #11111,#22222,#33333</p>
              <p class="mt-3 mb-0">Or: 11111,22222,33333</p>
              <p class="mt-3 mb-0">Or: 11111<br/>22222<br/>33333</p>
            </div>
          </div>

          <div class="form-card mt-3 mb-0 form">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <div>
                <div class="form-group">
                  <b-form-group>
                    <label class="mt-2 report-label">Order Ids</label>
                    <b-form-textarea
                        id="textarea"
                        v-model="form.orderIds"
                        placeholder="Order Ids..."
                        rows="3"
                        max-rows="100"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group>
                    <label class="mt-1 report-label">Tags to be removed:</label>
                    <b-form-input
                      v-model="form.removedTags"
                      type="text"
                      maxlength="50"
                      placeholder="Please input tags(seperated by ,)"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group>
                    <label class="mt-1 report-label">Tags to be added:</label>
                    <b-form-input
                      v-model="form.addedTags"
                      type="text"
                      maxlength="50"
                      placeholder="Please input tags(seperated by ,)"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <b-button type="submit" variant="primary"
                          class="form-submit-button submit-button adj-btn"
                >Submit
                </b-button>
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      user: this.$store.getters.currentUser,
      email: "",
      initials: "",
      form: {
        orderIds: "",
        removedTags: "",
        addedTags: "",
      },
      show: true,
      processing: false,
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: ""
    };
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    // console.log("user:", this.user);
    if (this.user) {
      this.email = this.user.email;
      // console.log("email:", this.email);
      const initials = this.user.name.split(" ").map((n, index) => {
        if (index == 0) {
          return n[0];
        } else {
          return n.substring(0, 2);
        }
      }).join("");
      this.initials = initials;
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.processing = true;
      try {
        await this.axios.put(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/orders/adjust-tags",
            {
              orderIds: this.form.orderIds,
              removedTags: this.form.removedTags,
              addedTags: this.form.addedTags,
              initials: this.initials,
              email: this.email,
            }
        );

        this.displayMessage("Success", "Tags have been updated.");
        this.processing = false;
        this.onReset();
      } catch (error) {
        this.processing = false;
        console.log(error);
        this.notificationMessage = "Oopps, Something is not right !!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }

      this.form.orderIds = "";
      this.form.removedTags = "";
      this.form.addedTags = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.adj-btn {
  margin-top: 30px;
}

.btn-outline-primary {
  background-color: #fff !important;
  color: $callia-green !important;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>