<template>
  <div>
    <h3><strong>Add Greet To Order</strong></h3>
    <div class="row">
      <div class="col-12 col-xl-6">
        <b-overlay :show="processing" rounded="sm" class="overlay-display">
          <template #overlay>
            <div class="text-center">
              <b-spinner
                type="grow"
                variant="info"
                label="Loading..."
              ></b-spinner>
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>
          <div class="card">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label"
                    >Order Number
                    <strong class="required-star">*</strong></label
                  >
                  <b-form-input
                    id="input-1"
                    v-model="orderNum"
                    type="text"
                    placeholder="Order Number"
                    required
                  ></b-form-input>
                </div>

                <b-button
                  type="submit"
                  variant="primary"
                  class="form-submit-button submit-button"
                  >Submit</b-button
                >
                &nbsp;
              </div>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNum: "",
      show: true,
      processing: false
    };
  },
  methods: {
    async onSubmit(event) {
      this.processing = true;
      event.preventDefault();
      await this.axios
        .post(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            "/video/order/" +
            this.orderNum.trim(), {},
          {
            headers: {
              Authorization: "Bearer " +this.$store.getters.idToken
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
            this.$emit("displayAlert", {
              message:
                "Success: Greet has been added to the order and an email was sent to the customer.",
              isError: false
            });
          }
        })
        .catch(error => {
          this.$emit("displayAlert", {
            message:
              "Error: Something went wrong with adding the greet to the order.",
            isError: true
          });
          this.processing = false;
        });
      this.processing = false;
      this.onReset();
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }
      this.orderNum = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
</style>
