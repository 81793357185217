<template>
  <b-modal centered ref="modal" hide-footer id="modal-edit-cutoff" :title="modalTitle"
    hide-backdrop>
    <div>
      <b-form @submit="onSubmit">
        <p>cutoff id: {{ selectedId.id }}</p>
        <b-form-group id="input-group-1" label="CutOff-Time:" label-for="input-1">
          <b-form-input id="input-1" v-model="selectedId.cut_off_time" type="text" placeholder="Enter New Time"
            required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Effect:" label-for="input-2">
          <b-form-select v-model="selectedId.effect" :options="optionsEffect"></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-2" label="AvailableDate:" label-for="input-3">
          <b-form-datepicker :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }" v-model="selectedId.available_date" :date-disabled-fn="dateDisabled" class="mb-2" required></b-form-datepicker>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
export default {
  props: ["selectedId", "selectedRegion", "selectedRegionId"],
  data() {
    return {
      optionsEffect: [
        { value: "0", text: "Same-Day" },
        { value: "1", text: "Next-Day" },
        { value: "2", text: "Next-Day + 1" },
        { value: "3", text: "Next-Day + 2" },
        { value: "4", text: "Next-Day + 3" }
      ]
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      try {
        const result = await this.axios.put(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + `/region-rules/${this.selectedRegionId}/rule`,
          {
            id: this.selectedId.id,
            type: "datesAvailable",
            availableDates: this.selectedId.available_date,
            cutoffTime: this.selectedId.cut_off_time,
            cutoffEffect: this.selectedId.effect,
          }
        );
        const msg = this.selectedId.id ? "updating" : "adding";

        result && result.status == 200
          ? this.displayMessage(
              "success",
              `${this.selectedRegion} :: ${result.data}`
            )
          : result.data ?
          this.displayMessage('warning', `${this.selectedRegion} :: ${result.data}`) :
          this.displayMessage('warning', `${this.selectedRegion} :: Error ${msg} cutoff time.`);
        if (!this.selectedId.id) {
          this.$emit("refreshPage");
        }
      } catch (err) {
        console.error(err);
      }
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 10000,
        solid: true
      });
    },
    dateDisabled(ymd, date) {
      const dateSelected = dayjs.tz(ymd, "America/Winnipeg");
      const currentTimeStamp = dayjs().tz("America/Winnipeg");
      const today = currentTimeStamp.startOf("day");
      if (dateSelected.isBefore(today)) {
        return true;
      }
      return false;
    }
  },
  computed: {
    modalTitle() {
      if (this.selectedId.id) {
        return "Edit Override CutOff Information";
      } else {
        return "Add Override CutOff Information";
      }
    }
  }
};
</script>

<style></style>
