<template>
  <div class="container-fluid p-0 h-100">
    <div class="container-fluid p-0">
      <b-alert
        :show="notification1.showErrorCountDown"
        dismissible
        fade
        :variant="notification1.type"
      >
        {{ notificationMessage1 }}
      </b-alert>
      <h3><strong>Unsubscribe Email</strong></h3>
      <div class="row">
        <div class="col-12 col-xl-6">
          <b-overlay :show="processing1" rounded="sm" class="overlay-display">
            <template #overlay>
              <div class="text-center">
                <b-spinner
                  type="grow"
                  variant="info"
                  label="Loading..."
                ></b-spinner>
                <p id="cancel-label">Please wait...</p>
              </div>
            </template>
            <div class="card">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <div class="card-body">
                  <div class="form-group">
                    <label class="form-label"
                      >Email <strong class="required-star">*</strong></label
                    >
                    <b-form-input
                      id="input-1"
                      v-model="form.email"
                      type="text"
                      placeholder="Email"
                      required
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label class="form-label"
                      >Result:
                      <strong class="required-star">{{ result }}</strong></label
                    >
                  </div>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="action-btn form-submit-button"
                    >Submit</b-button
                  >
                  &nbsp;
                  <b-button class="action-btn" type="reset" variant="danger"
                    >Reset</b-button
                  >
                </div>
              </b-form>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      email: "",
      processing: false,
      processingMessage: "Please wait...",
      items: [],
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: "",
      form: {
        email: ""
      },
      result: "",
      show: true,
      processing1: false,
      notification1: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage1: ""
    };
  },
  methods: {
    async onSubmit(event) {
      this.processing = true;
      event.preventDefault();
      try {
        await this.axios
          .post(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/unsubscribe/email",
            {
              email: this.form.email
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.idToken
              }
            }
          )
          .then(response => {
            if (response.data) {
              if (response.data.msg) {
                this.result = response.data.msg;
              } else {
                this.result = "success";
              }
            } else {
              this.result = "failed";
            }
          });
        this.processing = false;
        this.onReset();
      } catch (error) {
        this.processing = false;
        console.log(error);
        this.notificationMessage = "Oopps, Something is not right !!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }
      this.form.email = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style>
.action-btn {
  background-color: #17a2b8;
  border: none;
}
.grid-main {
  height: 800px;
}
</style>
