<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
              v-model="filterOn"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="region_name">Region</b-form-checkbox>
            <b-form-checkbox value="warehouse_sku"
            >Warehouse SKU
            </b-form-checkbox
            >
            <b-form-checkbox value="province_name">Province</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light"
          >Leave all unchecked to filter on all data
          </b-badge
          >
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col offset-lg="10" offset-md="9" md="3" lg="2" sm="12">
          <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="7"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
          >
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-table
              hover
              responsive
              :sticky-header="'58vh'"
              small
              id="exporter-process-table"
              class="table-height"
              :items="items"
              :fields="fields"
              :filter-included-fields="filterOn"
              :filter="filter"
              @filtered="onFiltered"
              :current-page="currentPage"
              :per-page="perPage"
          >
            <template #cell(action)="row">
              <edit-icon
                  size="1.5x"
                  class="custom-class"
                  @click="editRegion(row.item)"
              ></edit-icon>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            offset-lg="9"
            offset-md="8"
            offset-sm="4"
            md="4"
            lg="3"
            sm="8"
            class="text-right"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import {EditIcon, PlusIcon} from "vue-feather-icons";

export default {
  components: {
    EditIcon
  },
  props: ["items", "processing", "totalRows"],
  name: "region-grid",
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "region_name",
          sortable: true,
          label: "Region",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          key: "tag",
          sortable: true,
          label: "Tag",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          key: "short_code",
          sortable: true,
          label: "Short Code",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "warehouse_name",
          sortable: true,
          label: "Warehouse",
          thClass: "text-center",
          tdClass: "text-center"
        },{
          key: "delivery_hub",
          sortable: true,
          label: "Delivery Hub",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "province_name",
          sortable: true,
          label: "Province",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          key: "action",
          sortable: false,
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center"
        }
      ],
      filter: null,
      filterOn: [],
      sortDirection: "asc",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 20, {value: 100, text: "Show a lot"}]
    };
  },
  created() {
    this.$root.$refs.RegionGrid = this;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editRegion(row) {
      this.$emit("showEditModal", row);
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/scss/tablecommon.scss";

.input-group {
  margin-left: 0 !important;
  text-align: center;
}

/*checkbox filter*/
.custom-filter-bg {
  background: radial-gradient(
      circle,
      #e9f1f966 44%,
      rgba(255, 255, 255, 1) 100%
  );
  border-radius: 20px;
}

/*filter search*/
input#filter-input {
  border-color: #343a405c;
  padding: 10px 20px;
  border-radius: 28px;
}

/*filter icon*/
.icon {
  right: 10px !important;
  top: 6px !important;
  margin-left: -23px;
  margin-top: 7px;
  z-index: 99;
}

/*custom pagination*/
.page-item.disabled .page-link {
  color: #bcc1c6;
  background-color: #222e3d;
  border-color: #ffffff;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #0a9ea5;
  border-color: #0a9ea5;
}

.page-link {
  font-weight: 600;
  color: #bbbfc5;
  background-color: #222e3d;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 0px;
}

.page-link:hover {
  background-color: #edf0f3;
  color: black;
  font-weight: 600;
}
/*th {*/
/*  background-color: #636e78 !important;*/
/*  padding: 0.8rem !important;;*/
/*}*/
/*  th> div {*/
/*    color: white;*/
/*  }*/
/*  .pagination li span {*/
/*    background-color: #636e78 !important;*/
/*    color: white !important;*/
/*  }*/
/*.pagination li button {*/
/*  background-color: #636e78;*/
/*  color: white;*/
/*}*/
</style>
