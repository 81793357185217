<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Checkout Automation</strong></h3>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-12 col-xl-12 col-12">
          <div class="form-card">
            <b-form id="order-report-form">
              <div>
                <b-tabs
                  active-nav-item-class="font-weight-bold text-white bg-primary"
                  nav-class="font-weight-bold text-dark bg-secondary"
                  justified
                >
                  <b-tab title="Create checkout" active>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      Update checkout
                    </template>
                    <div class="row mt-3">
                      <div class="col-sm">
                        <b-form-group>
                          <b-form-input
                            v-model="queryURL"
                            type="text"
                            maxlength="100"
                            placeholder="Please input short URL"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-sm">
                        <b-button
                          type="button"
                          variant="primary"
                          class="submit-button"
                          @click="queryCheckout"
                        >
                          Query Checkout
                        </b-button>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <b-form-input
                      v-model="order.orderId"
                      type="text"
                      maxlength="10"
                      placeholder="Please input Order Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-button
                    type="button"
                    variant="primary"
                    class="submit-button"
                    @click="query"
                  >
                    Query Order
                  </b-button>
                </div>
              </div>
              <div class="row mt-3" v-if="carts.length === 1 && indexEdit === -1">
                <div class="col-sm">
                  <b-form-group label="Delivering to the same address?" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      v-model="sameAddress"
                      :options="sameAddressOpt"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Province: ({{ order.province }})</label>
                    <b-form-select
                      v-model="form.province"
                      v-bind:disabled="isReadOnly"
                      :options="provincesOpt"
                      @change="selectProvince"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">City: ({{ order.city }})</label>
                    <v-select :options="citiesOpt"
                      v-model="form.city"
                      v-bind:disabled="isReadOnly"
                      @input="selectCity"
                      class="input-height mt-1"></v-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Region: ({{ order.region }})</label>
                    <b-form-input
                      v-model="form.region"
                      type="text"
                      readonly
                      placeholder="Region"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery date: ({{ order.deliveryDate }})</label>
                    <b-form-datepicker
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="form.deliveryDateNew"
                      v-bind:disabled="isReadOnly"
                      :date-disabled-fn="dateDisabled"
                      @input="queryProducts"
                      class="mb-2"
                      required
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Postal Code: ({{ order.postalCode }})</label>
                    <b-form-input
                      v-model="form.postalCode"
                      v-bind:readonly="isReadOnly"
                      :state="postalCodeState"
                      @blur="queryDeliveryWindow"
                      maxlength="7"
                      type="text"
                      placeholder="Please input Postal Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Lang: ({{ order.lang }})</label>
                    <b-form-select
                      v-model="form.lang"
                      :options="langOpt"
                      @change="queryDeliveryWindow"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">First Name: ({{ order.firstName }})</label>
                    <b-form-input
                      v-model="form.firstName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input First Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Last Name: ({{ order.lastName }})</label>
                    <b-form-input
                      v-model="form.lastName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Last Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Contact Name: ({{ order.cname }})</label>
                    <b-form-input
                      v-model="form.cName"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Contact Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Relationship: ({{ order.relationship }})</label>
                    <b-form-select
                      v-model="form.relationship"
                      :options="relationshipsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Occasion: ({{ order.occasion }})</label>
                    <b-form-select
                      v-model="form.occasion"
                      :options="occasionsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address Type: ({{ order.addressType }})</label>
                    <b-form-select
                      v-model="form.addressType"
                      v-bind:disabled="isReadOnly"
                      :options="addressTypesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Phone Number: ({{ order.phone }})</label>
                    <b-form-input
                      v-model="form.phoneNumber"
                      type="text"
                      maxlength="14"
                      :state="isPhoneNumberValid(form.phoneNumber)"
                      @blur="form.phoneNumber = formatPhoneNumber(form.phoneNumber)"
                      placeholder="Please input Phone Number"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address: ({{ order.address }})</label>
                    <b-form-input
                      v-model="form.address"
                      v-bind:readonly="isReadOnly"
                      type="text"
                      maxlength="150"
                      placeholder="Please input Address"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Apartment Number: ({{ order.apartmentNumber }})</label>
                    <b-form-input
                      v-model="form.apartmentNumber"
                      type="text"
                      maxlength="50"
                      placeholder="Please input Apartment Number"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Buzzer Code: ({{ order.buzzerCode }})</label>
                    <b-form-input
                      v-model="form.buzzerCode"
                      type="text"
                      maxlength="20"
                      placeholder="Please input Buzzer Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery Window: ({{ order.deliveryRate }})</label>
                    <b-form-select
                      v-model="form.deliveryRate"
                      v-bind:disabled="isReadOnly"
                      :options="shippingMethodsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Consent: ({{ order.consent }})</label>
                    <b-form-select
                      v-model="form.consent"
                      :options="consentOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Bouquet Name: ({{ order.bouquetName }})</label>
                    <b-form-select
                      v-model="form.bouquetName"
                      :options="bouquetOpt"
                      @change="selectBouquet"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Bouquet Size: ({{ order.bouquetSize }})</label>
                    <b-form-select
                      v-model="form.bouquetSize"
                      :options="bouquetSizesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Vase: ({{ order.vase }})</label>
                    <b-form-select
                      v-model="form.vase"
                      :options="vaseOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Addons Inventory:</label>
                    <b-form-select
                      v-model="addons"
                      :options="addonsInventoryOpt"
                      :select-size="6"
                      @dblclick.native="selectAddon"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="w-10">
                  <b-button-group vertical>
                    <label class="mt-1 report-label">Options</label>
                    <br />
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="selectAddon"
                    >
                      &gt;&gt;
                    </b-button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="unselectAddon"
                    >
                      &lt;&lt;
                    </b-button>
                  </b-button-group>
                </div>
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Order Addons: ({{ order.addons }})</label>
                    <b-form-select
                      v-model="form.addons"
                      :options="addonsOpt"
                      :select-size="6"
                      @dblclick.native="unselectAddon"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery Notes: ({{ order.deliveryNotes }})</label>
                    <b-form-input
                      v-model="form.deliveryNotes"
                      type="text"
                      maxlength="200"
                      placeholder="Please input Delivery Notes"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Card Type: ({{ order.cardType }})</label>
                    <b-form-select
                      v-model="form.cardType"
                      :options="cardOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Card Message (Maximum 800 characters)</label
                    >
                    <b-form-textarea
                      v-model="form.message"
                      rows="3"
                      max-rows="8"
                      :state="form.message && form.message.length <= 800"
                      placeholder="Please input Message"
                    ></b-form-textarea>

                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-input-group class="mt-1" prepend="Subtotal $">
                    <b-form-input
                      v-model="subtotal"
                      type="text"
                      readonly
                    ></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <b-button v-if="!isEditMode"
                      type="button"
                      variant="primary"
                      class="submit-button"
                      @click="addCart"
                    >
                      Add Cart
                    </b-button>&nbsp;
                    <b-button v-if="isEditMode"
                      type="button"
                      variant="primary"
                      class="submit-button"
                      @click="saveCart"
                    >
                      Save Cart
                    </b-button>
                  </b-form-group>
                </div>
              </div>

              <b-card-group deck class="mb-3">
                  <b-card v-for="(cart, index) in carts" :key="index"
                    header-tag="header" footer-tag="footer"
                    border-variant="primary"
                    header="Primary"
                    header-bg-variant="primary"
                    header-text-variant="white">
                    <template #header>
                      <h6 class="mb-0">{{ cart.firstName }} {{ cart.lastName }} {{ cart.phoneNumber }}</h6>
                    </template>
                    <b-card-text>{{ cart.deliveryDate }} {{ cart.deliveryRate.value }} - {{ cart.deliveryRate.price }}</b-card-text>
                    <b-card-text>{{ cart.address }}, {{ cart.city }}, {{ cart.province }}</b-card-text>
                    <b-card-text>{{ cart.bouquetName }} - {{ cart.bouquetSize.value }}, {{ cart.vase.value }}</b-card-text>
                    <b-card-text>{{ cart.addonsStr }}</b-card-text>
                    <template #footer>
                      <b-button
                        :disabled="isEditMode"
                        variant="primary" @click="removeCart(index)">
                        Remove
                      </b-button>&nbsp;
                      <b-button
                        :disabled="isEditMode"
                        variant="primary" @click="editCart(index)">
                        Edit
                      </b-button>
                    </template>
                  </b-card>
              </b-card-group>
              <div class="row mt-3">
                <div class="col-sm mt-2">
                  <b-form-group
                    label="Checkout Destination: ()"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio
                      v-model="checkoutDestination"
                      :aria-describedby="ariaDescribedby"
                      name="Callia.com Basket"
                      value="Callia.com Basket"
                      >Callia.com Basket</b-form-radio
                    >
                    <b-form-radio
                      v-model="checkoutDestination"
                      :aria-describedby="ariaDescribedby"
                      name="Shopify Checkout"
                      value="Shopify Checkout"
                      >Shopify Checkout</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <b-button
                      v-b-tooltip.hover title="Create a new short URL"
                      type="button"
                      variant="primary"
                      size="lg"
                      :disabled="isDisabledCreateCheckout"
                      @click="createCheckout"
                    >
                      Create checkout
                    </b-button>&nbsp;
                    <b-button v-if="isUpdateCheckout"
                      v-b-tooltip.hover title="Use the same short URL"
                      type="button"
                      variant="success"
                      size="lg"
                      @click="updateCheckout"
                    >
                      Update checkout
                    </b-button>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Checkout URL</label
                    >
                    <b-form-input
                      v-model="result"
                      type="text"
                      maxlength="200"
                      placeholder="Checkout URL"
                      class="input-height mt-1"
                      readonly
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <b-button
                      type="button"
                      variant="primary"
                      class="submit-button"
                      :disabled="isDisabledCheckoutURL"
                      @click="copyURL"
                    >
                      Copy URL
                    </b-button>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <b-button
                      type="button"
                      variant="primary"
                      class="submit-button"
                      :disabled="isDisabledCheckoutURL"
                      @click="jump"
                    >
                      Jump to Checkout Page
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </b-overlay>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

import { nanoid } from "nanoid";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";
import { CustomAttributs } from "@/constants/custom-attributs";
import { getDeliveryProducts, invokeShopifyCheckoutCreate } from "@/utils/shopifyUtil";
import { getAllCities, getCutoffs, getOccasions } from "@/utils/queryUtil";

import FormatMixin from "@/mixin/format-mixin";

export default {
  mixins: [FormatMixin],
  components: {
    ErrorAlert,
    SuccessAlert,
    "v-select": vSelect,
  },
  computed: {
    postalCodeState() {
      return this.form.postalCode && this.form.postalCode.length >= 6 ? true : false;
    },
    isDisabledCreateCheckout() {
      return this.carts.length === 0;
    },
    isDisabledCheckoutURL() {
      return this.result.length === 0;
    },
    isEditMode() {
      return this.indexEdit >= 0;
    },
    isUpdateCheckout() {
      return this.queryURL.length > 0 && this.isShortURL === true;
    },
    subtotal() {
      let price = 0;
      try {
        if (this.form.deliveryRate) {
          const deliveryRateObj = this.form.deliveryRate;
          if (deliveryRateObj && deliveryRateObj.price) {
            price += parseFloat(deliveryRateObj.price.replace("$", ""));
          } else {
            console.log("Could not find deliveryRate:", this.form.deliveryRate);
          }
        }
        if (this.form.vase) {
          const vaseObj = this.form.vase;
          if (vaseObj && vaseObj.price) {
            price += parseFloat(vaseObj.price);
          }
        }
        if (this.form.cardType) {
          const cardObj = this.form.cardType;
          if (cardObj && cardObj.price) {
            price += parseFloat(cardObj.price);
          }
        }
        if (this.addonsOpt && this.addonsOpt.length > 0) {
          for (const addon of this.addonsOpt) {
            if (addon && addon.value && addon.value.price) {
              price += parseFloat(addon.value.price) * addon.value.quantity;
            }
          }
        }
        if (this.form.bouquetSize) {
          const bouquetObj = this.form.bouquetSize;
          if (bouquetObj && bouquetObj.price) {
            price += parseFloat(bouquetObj.price);
          }
        }
      } catch(e) {
        console.error("subtotal error:", e);
      }
      return `${price}`;
    }
  },
  data() {
    return {
      processing: false,
      processingMessage: "Please wait...",
      result: "",
      queryURL: "",
      id: "",
      isShortURL: true,
      indexEdit: -1,
      order: {
        orderId: "",
      },
      form: {
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        deliveryDate: "",
        deliveryDateNew: "",
        deliveryRate: "",
        deliveryRateBackup: "",
        sameAddress: "No",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        giftSetName: "",
        addons: "",
        vase: "",
        consent: "Yes",
        phoneNumber: "",
        cName: "",
        note: "",
        lang: "en-ca",
        regionCode: ""
      },
      addons: "",

      alert: {
        error: false,
        message: "",
      },

      provincesOpt: [],
      citiesOpt: [],
      shippingMethodsOpt: [],
      shippingMethodsOptBackup: [],
      sameAddress: "No",
      deliveryProducts: {},
      deliveryProducts2: {},

      addressTypesOpt: [
        { text: "House", value: "House" },
        { text: "Apartment/Complex", value: "Apartment/Complex" },
        { text: "Business", value: "Business" },
        { text: "Hospital", value: "Hospital" },
      ],

      relationshipsOpt: [
        { text: "Select an option", value: null },
        { text: "Partner", value: "Partner" },
        { text: "Friend", value: "Friend" },
        { text: "Mom", value: "Mom" },
        { text: "Grandparent", value: "Grandparent" },
        { text: "Family member", value: "Family member" },
        { text: "Colleague", value: "Colleague" },
        { text: "Client", value: "Client" },
        { text: "Myself", value: "Myself" },
        { text: "Other", value: "Other" },
      ],

      occasionsOpt: [
        { text: "Select an option", value: null },
      ],

      sameAddressOpt: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      isReadOnly: false,

      bouquetSizesOpt: [
        { text: "Select an option", value: null },
      ],

      bouquetOpt: [
      ],
      bouquetFlatOpt: [
      ],
      addonsInventoryOpt: [
      ],
      addonsOpt: [
      ],
      vaseOpt: [
      ],
      vaseObj: {
        "Contemporary Vase": "Contemporary Modern",
      },
      cardOpt: [
      ],
      bouquetSizeString: "",
      bouquetSizeObj: {},
      consentOpt: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      langOpt: [
        { text: "CA-EN", value: "en-ca" },
        { text: "CA-FR", value: "fr-ca" },
      ],
      text2EmailLangObj: {
        "ca-en": "en-ca",
        "ca-fr": "fr-ca",
      },
      email2TextLangObj: {
        "en-ca": "ca-en",
        "fr-ca": "ca-fr",
      },
      provinceCityObj: {},
      cityRegionObj: {},
      allCitiesObj: {},
      closedDays: [],
      unavailableDates: [],
      availableDates: [],
      cutoffTime: [],
      carts: [],
      regionTemp: "",
      checkoutDestination: "Callia.com Basket"

    };
  },
  watch: {
    sameAddress(newVal, oldVal) {
      this.handleSameAddressChange();
    }
  },
  async mounted() {
    this.queryCities();
    this.queryOccasions();
    const deliveryProductsRes = await getDeliveryProducts();
    const deliveryEdges = deliveryProductsRes?.data?.products?.edges;
    if (deliveryEdges) {
      const deliverys = deliveryEdges.filter(item => item.node.productType === "Delivery");
      for (const delivery of deliverys) {
        console.log("delivery.node.title", delivery.node.title);
        const deliveryVariants = delivery.node?.variants?.edges;
        if (deliveryVariants) {
          for (const variant of deliveryVariants) {
            if (delivery.node.title === "Delivery Charge") {
              this.deliveryProducts[`$${parseInt(variant?.node?.priceV2?.amount)}`] = {
                id: variant?.node?.id,
                sku: variant?.node?.sku
              };
            } else {
              this.deliveryProducts2[`$${parseInt(variant?.node?.priceV2?.amount)}`] = {
                id: variant?.node?.id,
                sku: variant?.node?.sku
              };
            }
          }
          console.log("this.deliveryProducts", this.deliveryProducts);
        }
      }
    }
  },
  methods: {
    handleSameAddressChange() {
      console.log('Selected option:', this.sameAddress);
      this.form.sameAddress = this.sameAddress;
      if (this.sameAddress === "Yes") {
        this.isReadOnly = true;
        this.form.city = this.carts[0].city;
        this.form.province = this.carts[0].province;
        this.form.region = this.carts[0].region;
        this.form.postalCode = this.carts[0].postalCode;
        this.form.addressType = this.carts[0].addressType;
        this.form.address = this.carts[0].address;

        this.form.deliveryRateBackup = JSON.parse(JSON.stringify(this.carts[0].deliveryRate));

        this.shippingMethodsOptBackup = JSON.parse(JSON.stringify(this.shippingMethodsOpt));
        this.shippingMethodsOpt = [];
        const rate = this.carts[0].deliveryRate;
        const ZONE_RATE = "$2";
        const deliveryProduct = this.deliveryProducts2[ZONE_RATE];
        this.shippingMethodsOpt.push({ text: `${rate.value} - ${ZONE_RATE}`,
                                      value: {value: rate.value, id: deliveryProduct.id, sku: deliveryProduct.sku,
                                      price: ZONE_RATE} });

        this.form.deliveryRate = this.shippingMethodsOpt[0].value;
        console.log("deliveryRate", this.form.deliveryRate);
      } else {
        console.log("isReadOnly false");
        this.isReadOnly = false;
        this.shippingMethodsOpt = this.shippingMethodsOptBackup;
        console.log("shippingMethodsOpt", this.shippingMethodsOpt);
        this.form.deliveryRate = this.form.deliveryRateBackup;
        console.log("deliveryRate", this.form.deliveryRate);
      }
    },
    async query() {
      if (!this.order.orderId) {
        this.displayMessage("warning", `Order Name can't be empty`);
        return false;
      }
      this.processing = true;
      this.displayMessage("Success", "Getting order data...");

      try {
        this.clearOptions();
        this.clearForm();
        this.result = "";
        this.form.bouquetName = null;
        const orderName = this.order.orderId.replace('#', '').trim();
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/order/" + orderName;
        const response = await this.axios.get(url).then(
          res => {
            console.log("response:", res);
            if (res && res.data) {
              return res.data;
            } else {
              this.displayMessage("warning", `Query Exception: Please try again or re-login.`);
            }
          }
        ).catch(err => {
          this.displayMessage("warning", `Query Exception: ${err}`);
        });
        if (!response) {
          this.processing = false;
          return;
        }
        this.order = response;
        if (this.order.province) {
          this.form.province = this.order.province.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          this.selectProvince();
          if (this.order.city) {
            const city = this.order.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (this.cityRegionObj[city]) {
              this.form.city = city;
              await this.selectCity();
            }
          }
        }

        this.form.orderId = this.order.orderId;
        this.$set(this.form, "postalCode", this.order.postalCode?.toUpperCase());
        this.form.lang = this.text2EmailLangObj[this.order.lang?.toLowerCase()] || "en-ca";
        if (!this.order.deliveryDate) {
          this.processing = false;
          return;
        }
        const deliveryDate = dayjs.tz(this.order.deliveryDate,  'America/Winnipeg');
        const currentTimeStamp = dayjs().tz('America/Winnipeg');
        const today = currentTimeStamp.startOf('day');
        if (!deliveryDate.isBefore(today)) {
          this.form.deliveryDateNew = deliveryDate.format("YYYY-MM-DD");
          if (this.form.region) {
            await this.queryProducts();
          }
        } else {
          this.displayMessage("warning", `Please select new delivery date`);
        }

        this.form.bouquetName = "";
        this.form.bouquetSize = "";
        if (this.order && this.order.bouquetName) {
          console.log(`this.order.bouquetName: ${this.order.bouquetName}`);
          if (this.order.bouquetName.includes("-")) {
            this.order.bouquetSize = this.order.bouquetName.split('-')[1].trim();
            this.order.bouquetName = this.order.bouquetName.split('-')[0].trim();
            const bouquetNameObj = this.bouquetFlatOpt.find(item => item.value === this.order.bouquetName);
            if (bouquetNameObj) {
              this.form.bouquetName = this.order.bouquetName;
              this.selectBouquet();
              const bouquetSizeObj = this.bouquetSizesOpt.find(item => item.value.value === this.order.bouquetSize);
              if (bouquetSizeObj) {
                this.form.bouquetSize = bouquetSizeObj.value;
              }
            }
          }
        } else {
          console.log("bouquet name empty", this.order);
        }
        this.addonsOpt = [];
        if (this.order.addons) {
          const addons = this.order.addons.split(',');
          for (const addon of addons) {
            let addonStr = addon;
            let addonQuantity = 1;
            if (addon.includes("(")) {
              addonStr = addon.split("(")[0].trim();
              addonQuantity = parseInt(addon.split("(")[1].trim().replace(/\s|[)]|x/g, ""));
            }
            if (this.addonsInventoryOpt) {
              const orderAddonsObj = this.addonsInventoryOpt.find(item => item.value.value.toLowerCase() === addonStr.toLowerCase());
              if (orderAddonsObj) {
                this.addonsOpt.push({ text: `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price} (x${addonQuantity})`,
                                      value: {value: orderAddonsObj.value.value, id: orderAddonsObj.value.id,
                                              price: orderAddonsObj.value.price, quantity: addonQuantity,
                                              quantityAvailable: orderAddonsObj.value.quantityAvailable,
                                              variantShopifyProd: orderAddonsObj.value.variantShopifyProd, parentProd: orderAddonsObj.value.parentProd
                                              } });
              }
            }
          }
          console.log("this.addonsOpt:", this.addonsOpt);
        }
        this.form.vase = "";
        if (this.order.vase && this.vaseOpt) {
          if (this.vaseObj[this.order.vase]) {
            this.order.vase = this.vaseObj[this.order.vase];
          }
          const vaseObj = this.vaseOpt.find(item => item.value.value === this.order.vase);
          if (vaseObj) {
            this.form.vase = vaseObj.value;
          }
        }

        this.form.deliveryRate = "";
        if (this.shippingMethodsOpt) {
          const deliveryRateObj = this.shippingMethodsOpt.find(item => item.value.value === this.order.deliveryRate);
          if (deliveryRateObj) {
            this.form.deliveryRate = deliveryRateObj.value;
          }
        }
        this.form.cardType = "";
        if (this.cardOpt) {
          const cardTypeObj = this.cardOpt.find(item => item.value.value === this.order.cardType);
          if (cardTypeObj) {
            this.form.cardType = cardTypeObj.value;
          }
        }
        this.form.addressType = this.order.addressType;
        this.form.address = this.order.address;
        this.form.buzzerCode = this.order.buzzerCode;
        this.form.apartmentNumber = this.order.apartmentNumber;
        this.form.consent = this.order.consent;
        this.form.phoneNumber = this.order.phone;
        this.form.firstName = this.order.firstName;
        this.form.lastName = this.order.lastName;
        this.form.cName = this.order.cname || this.order.cName;
        this.form.relationship = this.order.relationship;
        this.form.occasion = this.order.occasion;
        this.form.deliveryNotes = this.order.deliveryNotes;
        this.form.message = this.order.message;
      } catch(e) {
        console.error("query error:", e);
      }

      this.processing = false;
    },

    async queryCheckout() {
      if (!this.queryURL) {
        this.displayMessage("warning", `Short URL can't be empty`);
        return false;
      }
      this.processing = true;
      this.displayMessage("Success", "Getting Checkout data...");

      try {
        this.order = {
          orderId: "",
        };
        this.clearOptions();
        this.clearForm();
        let id;
        let url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/checkout-automation/";
        if (this.queryURL.includes("checkout.callia.com")) {
          id = this.queryURL.substring(this.queryURL.lastIndexOf('/') + 1).split("?")[0];
          url += "long/" + id;
          this.isShortURL = false;
        } else {
          id = this.queryURL.substring(this.queryURL.lastIndexOf('/') + 1);
          url += id;
          this.isShortURL = true;
        }
        this.id = id;
        console.log(`id: ${id}`);
        this.result = "";
        this.indexEdit = -1;
        this.sameAddress = "No";
        this.form = {};
        const response = await this.axios.get(url).then(
          res => {
            console.log("response:", res);
            if (res && res.data) {
              return res.data;
            } else {
              this.displayMessage("warning", `Query Exception: Please try again or re-login.`);
            }
          }
        ).catch(err => {
          this.displayMessage("warning", `Query Exception: ${err}`);
        });
        if (!response) {
          this.processing = false;
          return;
        }

        this.order.orderId = response.referenceOrder;
        let carts = response.carts;
        if (typeof carts === "string") {
          carts = JSON.parse(carts);
          if (typeof carts === "string") {
            carts = JSON.parse(carts);
          }
        }
        for (const cart of carts) {
          cart.deliveryRate = cart.deliveryRate || cart.deliveryRateObj;
          cart.deliveryRate.value = cart.deliveryRate?.value || cart.deliveryRate?.title;
          cart.bouquetName = cart.bouquetName || cart.bouquetObj?.bouquetName;
          cart.bouquetSize = cart.bouquetSize || cart.bouquetObj;
          cart.bouquetSize.value = cart.bouquetSize?.value || cart.bouquetSize?.title;
          cart.cardType = cart.cardType || cart.cardObj || { value: ""};
          cart.cardType.value = cart.cardType?.value || cart.cardType?.title;
          cart.vase = cart.vase || cart.vaseObj || { value: ""};
          cart.vase.value = cart.vase?.value || cart.vase?.title;
          cart.addons = cart.addons || cart.addonsObj || [];
          // cart.addons.value = cart.addons.value || cart.addons.title;
          cart.deliveryDate = cart.deliveryDate || cart.deliveryDateNew;
          const deliveryDate = dayjs.tz(cart.deliveryDate,  'America/Winnipeg');
          cart.deliveryDateNew = deliveryDate.format("YYYY-MM-DD");

          const addons = [];
          if (cart.addons && cart.addons.length > 0) {
            for (const addon of cart.addons) {
              if (addon && addon.title) {
                const addonStr = `${addon.title} (x${addon.quantity})`;
                addons.push(addonStr);
              }
            }
          }
          cart.addonsStr = addons.join(",");
        }
        console.log("carts:", JSON.stringify(carts, null, 2));
        this.carts = carts;
      } catch(e) {
        console.error("queryCheckout error:", e);
      }

      this.processing = false;
    },

    clearForm() {
      this.form = {
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        deliveryDate: "",
        deliveryDateNew: "",
        deliveryRate: "",
        deliveryRateBackup: "",
        sameAddress: "No",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        giftSetName: "",
        addons: "",
        vase: "",
        consent: "Yes",
        phoneNumber: "",
        cName: "",
        note: "",
        lang: "en-ca",
        regionCode: "",
      }
    },
    clearOptions() {
      this.bouquetOpt = [];
      this.bouquetSizesOpt = [];
      this.bouquetFlatOpt = [];
      this.bouquetSizeObj = {};
      this.vaseOpt = [];
      this.addonsOpt = [];
      this.addonsInventoryOpt = [];
      this.cardOpt = [];
      this.shippingMethodsOpt = [];
    },

    async queryCities() {
      // this.displayMessage("Success", "Getting cities...");
      const response = await getAllCities();
      if (response) {
        this.allCitiesObj = response;
        for (const key of Object.keys(this.allCitiesObj)) {
          const provinceName = key;
          const cities = [];
          const province = { text: provinceName, value: provinceName };
          for (const val of this.allCitiesObj[key]) {
            for (const city of val.cities) {
              cities.push(city.name);
              this.cityRegionObj[city.name] = val.regionTag;
            }
          }
          this.provincesOpt.push(province);
          this.provinceCityObj[provinceName] = cities.sort((a, b) =>
            a > b ? 1 : -1
          );
        }
        this.provincesOpt = this.provincesOpt.sort((a, b) =>
          a.value > b.value ? 1 : -1
        );
        // this.displayMessage("Success", "Query Cities: Done...");
        return true;
      } else {
        this.displayMessage("warning", `Query Cities Exception`);
      }
    },
    async queryOccasions() {
      const response = await getOccasions();
      const occasions = response?.data?.occasions;
      if (occasions && occasions[0]["occasion-types"]) {
        this.occasionsOpt = [];
        this.occasionsOpt.push({ text: "Select an option", value: "" });
        for (const occasionObj of occasions[0]["occasion-types"]) {
          const occasion = { text: occasionObj["occasion-name"], value: occasionObj["occasion-name"] };
          this.occasionsOpt.push(occasion);
        }
      }
    },
    selectProvince() {
      // this.form.city = "";
      // this.form.region = "";
      this.$set(this.form, "city", "");
      this.$set(this.form, "region", "");
      this.$set(this.form, "deliveryDateNew", "");
      console.log("selectProvince:", this.form.province);
      this.citiesOpt = this.provinceCityObj[this.form.province];
    },
    async selectCity() {
      console.log("selectCity:", this.form.city);
      this.regionTemp = this.form.region;
      this.form.region = "";
      if (this.form.city) {
        const region = this.cityRegionObj[this.form.city];
        if (region) {
          this.$set(this.form, "region", region);
          console.log("region:", this.form.region);
          console.log("old region:", this.regionTemp);
          if (this.form.region !== this.regionTemp) {
            await this.queryCutoffs();
            this.$set(this.form, "deliveryDateNew", null);
            this.form.deliveryDateNew = null;
            this.form.deliveryDateNew = "";
            console.log("deliveryDateNew:", this.form.deliveryDateNew);
            this.$set(this.form, "deliveryDateNew", "");
          }
        } else {
          this.displayMessage("warning", `Region does not exist for city ${this.form.city} `);
          this.form.city = "";
        }
      } else {
        this.displayMessage("warning", `City is empty`);
      }
    },

    async queryCutoffs() {
      // this.displayMessage("Success", "Getting cutoffs...");
      const region = this.form.region;
      if (!region) {
        this.displayMessage("warning", `Please select region`);
        return;
      }
      const cutoffs = await getCutoffs(region);
      if (cutoffs) {
        if (cutoffs.closedDates) {
          this.closedDays = cutoffs.closedDates.map((closeD) =>
            parseInt(closeD)
          );
        }
        this.form.regionCode = cutoffs.region;
        this.unavailableDates = cutoffs.un_available;
        this.availableDates = cutoffs.available;
        this.cutoffTime = cutoffs.cutoff_time;
        // this.displayMessage("Success", "Cutoffs done...");
        return true;
      } else {
        this.displayMessage("warning", `Query Cutoffs Exception`);
      }
    },

    async queryProducts() {
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Please select  delivery date`);
        return;
      }
      const deliveryDate = dayjs(this.form.deliveryDateNew);
      console.log(`deliveryDate: ${deliveryDate.format()}, deliveryDateNew: ${this.form.deliveryDateNew}`);
      const weekNum = deliveryDate.format("WWYY");
      const warehouseCode = this.form.regionCode;

      this.bouquetOpt = [];
      this.bouquetFlatOpt = [];
      this.bouquetOpt.push({ text: "Select an option", value: "" });
      this.bouquetSizeObj = {};
      // this.displayMessage("Success", "Getting bouquets...");
      await this.queryBouquets(warehouseCode, weekNum, "Flowers");
      // this.displayMessage("Success", "Getting Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Bundle");
      // this.displayMessage("Success", "Getting Curated_Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Curated_Bundle");
      this.displayMessage("Success", "Getting Products...");
      // this.displayMessage("Success", "Getting vases...");
      await this.queryVases(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting addons...");
      await this.queryAddons(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting cards...");
      await this.queryCard(warehouseCode, weekNum);
      if (this.form.postalCode && this.form.postalCode.length >= 6) {
        await this.queryDeliveryWindow();
      }
      // this.displayMessage("Success", "Query Products: Done...");
    },

    async queryDeliveryWindow() {
      if (!this.form.postalCode) {
        this.displayMessage("warning", `Please input postal code`);
        return;
      }
      if (this.form.postalCode.length < 6) {
        this.displayMessage("warning", `Postal code is incorrect`);
        return;
      }
      if (!this.form.lang) {
        this.displayMessage("warning", `Please select Lang`);
        return;
      }
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Please select delivery date`);
        return;
      }

      // this.displayMessage("Success", "Getting Delivery window...");
      this.form.postalCode = this.form.postalCode.toUpperCase();
      await this.queryDeliveryRates(this.form.postalCode, this.form.deliveryDateNew, this.form.lang);
      // this.displayMessage("Success", "Query Delivery window: Done...");
    },
    async queryBouquets(warehouseCode, weekNum, productType) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const data = {"queryId": "products_filter", "productType": productType, "region": warehouseCode, "dateCode": weekNum};
      const bouquetList = await this.axios.post(inventoryUrl, data).then(
        res => {
          console.log("Inventory response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Query Bouquets Exception: ${err}`);
      });
      if (bouquetList) {
        const bouquetOpt = [];
        for (const bouquet of bouquetList) {
          if (bouquet.node.availableForSale) {
            bouquetOpt.push({ text: bouquet.node.title, value: bouquet.node.title });
            this.bouquetFlatOpt.push({ text: bouquet.node.title, value: bouquet.node.title });
            const variantOpt = [];
            variantOpt.push({ text: "Select an option", value: "" });
            for (const variant of bouquet.node.variants.edges) {
              if (variant.node.quantityAvailable > 0) {
                variantOpt.push({ text: `${variant.node.title} - $${variant.node.price.amount} (${variant.node.quantityAvailable})`,
                                  value: {value: variant.node.title, id: variant.node.id, sku: variant.node.sku,
                                  price: variant.node.price.amount, quantityAvailable: variant.quantityAvailable, variantShopifyProd: variant, parentProd: bouquet.node} });
              }
            }
            this.bouquetSizeObj[bouquet.node.title] = variantOpt;
          }
        }
        this.bouquetOpt.push({
          label: productType,
          options: bouquetOpt
        });
      } else {
        console.log(`bouquetList is null: productType: ${productType}, region: ${warehouseCode}, dateCode: ${weekNum}`);
      }
    },
    async queryVases(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const vaseData = {"queryId": "products_with_product_type_filter", "productType": "Vase", "region": warehouseCode, "dateCode": weekNum};
      const vaseList = await this.axios.post(inventoryUrl, vaseData).then(
        res => {
          console.log("query vase response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Query Vases Exception: ${err}`);
      });
      if (vaseList) {
        this.vaseOpt = [];
        this.vaseOpt.push({ text: "Select an option", value: "" });
        for (const vase of vaseList) {
          if (vase.node.availableForSale) {
            const variant = vase.node.variants.edges[0].node;
            this.vaseOpt.push({ text: `${vase.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: {value: vase.node.title, id: variant.id, sku: variant.sku,
                                        price: variant.price.amount, quantityAvailable: variant.quantityAvailable, variantShopifyProd: variant, parentProd: vase.node} });
          } else {
            console.log("vase is not availableForSale:", vase.node.availableForSale);
          }
        }
      } else {
        console.error("vaseList is empty:", vaseList);
      }
    },
    async queryAddons(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const addonsData = {"queryId": "products_filter", "productType": "Gift-adds", "region": warehouseCode, "dateCode": weekNum};
      const addonsList = await this.axios.post(inventoryUrl, addonsData).then(
        res => {
          console.log("query addons response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Query Vases Exception: ${err}`);
      });
      if (addonsList) {
        this.addonsOpt = [];
        this.addonsInventoryOpt = [];
        for (const addons of addonsList) {
          const variant = addons.node.variants?.edges[0]?.node;
          if (addons.node.availableForSale && variant.quantityAvailable > 0) {
            this.addonsInventoryOpt.push({ text: `${addons.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                          value: {value: addons.node.title, id: variant.id, sku: variant.sku,
                                                  price: variant.price.amount, quantityAvailable: variant.quantityAvailable, variantShopifyProd: variant, parentProd: addons.node} });
          }
        }
      }
    },
    async queryCard(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const cardData = {"queryId": "products_with_product_type_filter", "productType": "Card", "region": warehouseCode, "dateCode": weekNum};
      const cardList = await this.axios.post(inventoryUrl, cardData).then(
        res => {
          console.log("query card response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Query Vases Exception: ${err}`);
      });
      if (cardList) {
        this.cardOpt = [];
        for (const card of cardList) {
          const tags = card.node.tags;
          const variant = card.node.variants?.edges[0]?.node;
          if (card.node.availableForSale && variant.quantityAvailable > 0 &&
              (tags.includes(weekNum) || tags.includes("card-ongoing")) &&
              !tags.includes("not-" + weekNum)) {
            this.cardOpt.push({ text: `${card.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: {value: card.node.title, id: variant.id, sku: variant.sku,
                                        price: variant.price.amount, quantityAvailable: variant.quantityAvailable, variantShopifyProd: variant, parentProd: card.node} });
          }
        }
      }
    },
    async queryDeliveryRates(postal, deliveryDate, lang) {
      const postalNew = postal.replace(/\s+/g, "");
      const rateUrl = `https://shop.callia.com/rates/delivery/postalcode/${postalNew}/date/${deliveryDate}?lang=${lang}`;
      const rateList = await this.axios.get(rateUrl).then(
        res => {
          console.log("queryDeliveryRates response:", res.data);
          return JSON.parse(JSON.parse(res.data.body)).sort(
            (a, b) => {
              return Number(a.zone_rate.replace("$", "")) - Number(b.zone_rate.replace("$", ""))
            }
          );
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `Query DeliveryRates Exception: ${err}`);
      });
      if (rateList) {
        this.shippingMethodsOpt = [];
        for (const rate of rateList) {
          if (rate.status === true) {
            const deliveryProduct = this.deliveryProducts[rate.zone_rate];
            this.shippingMethodsOpt.push({ text: `${rate.display_text} - ${rate.zone_rate}`,
                                          value: {value: rate.display_text, id: deliveryProduct.id, sku: deliveryProduct.sku,
                                          price: rate.zone_rate} });
          }
        }
      }
    },
    selectBouquet() {
      this.form.bouquetSize = "";
      this.bouquetSizesOpt = this.bouquetSizeObj[this.form.bouquetName] || [];
    },
    selectAddon() {
      if (this.addons) {
        console.log("selectAddon this.addonsOpt:", this.addonsOpt);
        const orderAddonsObj = this.addonsOpt.find(item => item.value.value === this.addons.value);
        if (orderAddonsObj) {
          if (orderAddonsObj.value.quantity < this.addons.quantityAvailable) {
            orderAddonsObj.value.quantity++;
            orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                  (x${orderAddonsObj.value.quantity})`;
          }
        } else {
          this.addonsOpt.push({ text: `${this.addons.value} - $${this.addons.price} (x1)`,
                                value: {value: this.addons.value, id: this.addons.id,
                                        price: this.addons.price, quantity: 1,
                                        quantityAvailable: this.addons.quantityAvailable,
                                        variantShopifyProd: this.addons.variantShopifyProd, parentProd: this.addons.parentProd
                                        } });
        }
      } else {
        this.displayMessage("warning", `Please select Addons to add`);
      }
    },
    unselectAddon() {
      if (this.form.addons) {
        const orderAddonsObj = this.addonsOpt.find(item => item.value.value === this.form.addons.value);
        if (this.form.addons.quantity > 1) {
          this.form.addons.quantity--;
          orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                (x${orderAddonsObj.value.quantity})`;
        } else {
          this.addonsOpt = this.addonsOpt.filter(item => item.value.value !== this.form.addons.value);
        }
      } else {
        this.displayMessage("warning", `Please select Addons to remove`);
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      const dateSelected = dayjs.tz(ymd,  'America/Winnipeg');
      const currentTimeStamp = dayjs().tz('America/Winnipeg');
      const today = currentTimeStamp.startOf('day');
      const formatedDate = dateSelected.format("YYYY-MM-DD");
      if (dateSelected.isBefore(today)) {
        return true;
      }
      if (this.availableDates && this.availableDates.length > 0) {
        for (const availableDates of this.availableDates) {
          if (availableDates.date.includes(formatedDate)) {
            const cutOffTimeStamp = dateSelected
              .subtract(parseInt(availableDates.cutoff_effect), "d")
              .startOf("d")
              .add(availableDates.cutoff_time.split(/:|\./)[0], "h")
              .add(availableDates.cutoff_time.split(/:|\./)[1], "m");

            if (cutOffTimeStamp.isAfter(currentTimeStamp)) {
              return false;
            }
          }
        }
      }
      if (this.unavailableDates.includes(formatedDate)) {
        return true;
      }
      if (this.closedDays.includes(weekday)) {
        return true;
      }

      const cutoff = this.cutoffTime
        .find((df) => +df.day === dateSelected.day());
      //Calculate the cutoff time (d - cutoff_effect)
      if (cutoff) {
        const cutOffTimeStamp = dateSelected
          .subtract(parseInt(cutoff.cutoff_effect), "d")
          .startOf("d")
          .add(cutoff.cutoff_time.split(/:|\./)[0], "h")
          .add(cutoff.cutoff_time.split(/:|\./)[1], "m");

        return cutOffTimeStamp.isBefore(currentTimeStamp);
      } else {
        console.log("no cutoff", dateSelected.day());
        return true;
      }
    },
    removeCart(index) {
      if (index === 0 && this.carts.length > 1) {
        if (this.carts[1].sameAddress === "Yes") {
          this.carts[1].sameAddress === "No";
          this.carts[1].deliveryRate = this.form.deliveryRateBackup;
        }
      }
      this.carts.splice(index, 1);
    },
    async editCart(index) {
      this.indexEdit = index;

      this.processing = true;
      try {
        const cart = this.carts[index];
        this.order = {
          orderId: cart.orderId,
        };
        this.clearOptions();
        this.clearForm();
        // this.result = "";
        this.form.province = cart.province;
        this.selectProvince();
        const city = cart.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        if (this.cityRegionObj[city]) {
          this.form.city = city;
          this.$set(this.form, "city", city);
          await this.selectCity();
        } else {
          this.displayMessage("warning", `Region does not exist for city ${city}. Please select a new city!`);
        }

        this.form.orderId = cart.orderId;
        this.$set(this.form, "postalCode", cart.postalCode?.toUpperCase());
        this.form.lang = this.text2EmailLangObj[cart.lang?.toLowerCase()] || "en-ca";
        const deliveryDate = dayjs.tz(cart.deliveryDate,  'America/Winnipeg');
        const currentTimeStamp = dayjs().tz('America/Winnipeg');
        const today = currentTimeStamp.startOf('day');
        if (!deliveryDate.isBefore(today)) {
          if (this.form.region) {
            // this.form.deliveryDateNew = deliveryDate.format("YYYY-MM-DD");
            const deliveryDateNew = deliveryDate.format("YYYY-MM-DD");
            this.$set(this.form, "deliveryDateNew", deliveryDateNew);
            await this.queryProducts();
          }
        } else {
          this.displayMessage("warning", `Please select delivery date`);
        }

        this.form.bouquetName = "";
        this.form.bouquetSize = "";
        console.log(`cart.bouquetName: ${cart.bouquetName}`);
        const bouquetNameObj = this.bouquetFlatOpt.find(item => item.value === cart.bouquetName);
        if (bouquetNameObj) {
          this.form.bouquetName = cart.bouquetName;
          this.selectBouquet();
          const bouquetSizeObj = this.bouquetSizesOpt.find(item => item.value.value === cart.bouquetSize.value);
          if (bouquetSizeObj) {
            this.form.bouquetSize = bouquetSizeObj.value;
          }
        }
        this.addonsOpt = [];
        console.log(`cart.addons: ${JSON.stringify(cart.addons, null, 2)}`);
        if (cart.addons?.value && this.addonsInventoryOpt) {
          for (const addon of cart.addons) {
            const addonStr = addon.value.value;
            const addonQuantity = addon.value.quantity;
            const orderAddonsObj = this.addonsInventoryOpt.find(item => item.value.value.toLowerCase() === addonStr.toLowerCase());
            if (orderAddonsObj) {
              this.addonsOpt.push({ text: `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price} (x${addonQuantity})`,
                                    value: {value: orderAddonsObj.value.value, id: orderAddonsObj.value.id,
                                            price: orderAddonsObj.value.price, quantity: addonQuantity,
                                            quantityAvailable: orderAddonsObj.value.quantityAvailable,
                                            variantShopifyProd: orderAddonsObj.value.variantShopifyProd, parentProd: orderAddonsObj.value.parentProd
                                            } });
            }
          }
          console.log("this.addonsOpt:", this.addonsOpt);
        }
        this.form.vase = "";
        if (cart.vase?.value && this.vaseOpt) {
          if (this.vaseObj[cart.vase]) {
            cart.vase = this.vaseObj[cart.vase];
          }
          const vaseObj = this.vaseOpt.find(item => item.value.value === cart.vase.value);
          if (vaseObj) {
            this.form.vase = vaseObj.value;
          }
        }

        this.form.deliveryRate = "";
        console.log("this.shippingMethodsOpt:", this.shippingMethodsOpt);
        if (this.shippingMethodsOpt && cart.deliveryRate?.value) {
          const deliveryRateObj = this.shippingMethodsOpt.find(item => item.value.value === cart.deliveryRate.value);
          if (deliveryRateObj) {
            this.form.deliveryRate = deliveryRateObj.value;
          }
        }
        this.form.cardType = "";
        if (this.cardOpt && cart.cardType?.value) {
          const cardTypeObj = this.cardOpt.find(item => item.value.value === cart.cardType.value);
          if (cardTypeObj) {
            this.form.cardType = cardTypeObj.value;
          }
        }
        this.form.addressType = cart.addressType;
        this.form.address = cart.address;
        this.form.buzzerCode = cart.buzzerCode;
        this.form.apartmentNumber = cart.apartmentNumber;
        this.form.consent = cart.consent;
        this.form.phoneNumber = cart.phoneNumber;
        this.form.firstName = cart.firstName;
        this.form.lastName = cart.lastName;
        this.form.cName = cart.cName;
        this.form.relationship = cart.relationship;
        this.form.occasion = cart.occasion;
        this.form.deliveryNotes = cart.deliveryNotes;
        this.form.message = cart.message;
      } catch(e) {
        console.error("edit cart error:", e);
      }

      this.processing = false;
    },
    async addCart() {
      this.result = "";
      if (!this.checkItems()) {
        return;
      }
      if (this.carts.length < 10) {
        const deliveryDate = dayjs.tz(this.form.deliveryDateNew,  'America/Winnipeg');
        this.form.deliveryDate = deliveryDate.format("MM/DD/YYYY");
        const cart = JSON.parse(JSON.stringify(this.form));
        const addons = [];
        if (this.addonsOpt && this.addonsOpt.length > 0) {
          for (const addon of this.addonsOpt) {
            if (addon && addon.value) {
              const addonStr = `${addon.value.value} (x${addon.value.quantity})`;
              addons.push(addonStr);
            }
          }
        }
        cart.addons = JSON.parse(JSON.stringify(this.addonsOpt));
        cart.addonsStr = addons.join(",");
        this.carts.push(cart);
        this.sameAddress = "No";
        // this.isReadOnly = false;
      } else {
        this.displayMessage("warning", `Maximum order count is 10`);
      }
    },
    async saveCart() {
      this.result = "";
      if (!this.checkItems()) {
        return;
      }
      if (this.carts.length > this.indexEdit) {
        const deliveryDate = dayjs.tz(this.form.deliveryDateNew,  'America/Winnipeg');
        this.form.deliveryDate = deliveryDate.format("MM/DD/YYYY");
        const cart = JSON.parse(JSON.stringify(this.form));
        const addons = [];
        if (this.addonsOpt && this.addonsOpt.length > 0) {
          for (const addon of this.addonsOpt) {
            if (addon && addon.value) {
              const addonStr = `${addon.value.value} (x${addon.value.quantity})`;
              addons.push(addonStr);
            }
          }
        }
        cart.addons = JSON.parse(JSON.stringify(this.addonsOpt));
        cart.addonsStr = addons.join(",");
        this.carts[this.indexEdit] = cart;
        this.sameAddress = "No";
        this.indexEdit = -1;
      } else {
        this.displayMessage("warning", `Cart does not exist`);
      }
    },
    async createCheckout() {
      if (this.carts.length === 0) {
        this.displayMessage("warning", `Please add cart`);
        return;
      }
      let products = [];
      for (const cart of this.carts) {
        const param = await this.handleCheckoutParam(cart);
        products = products.concat(param);
      }
      const lineItemsRequest = this.generateCheckOutItemList(products);
      if(this.checkoutDestination === "Callia.com Basket"){
        const url = "https://callia.com/cart?cart_id=" + this.generateRandomString(7);
        const shortURL = await this.getShortURL(url);
        this.result = shortURL;
        await this.saveURL(lineItemsRequest, shortURL, url, this.carts);
        console.log(shortURL);
      }
      else{
        const {lineItemsRequest, checkoutPage} = await this.handleCheckout();
        if (checkoutPage) {
          const shortURL = await this.getShortURL(checkoutPage);
          this.result = shortURL ?? checkoutPage;
          await this.saveURL(lineItemsRequest, shortURL, checkoutPage, this.carts);
        }
      }
      this.carts = [];
    },
    async updateCheckout() {
            let products = [];
      for (const cart of this.carts) {
        const param = await this.handleCheckoutParam(cart);
        products = products.concat(param);
      }
      const lineItemsRequest = this.generateCheckOutItemList(products);
      if(this.checkoutDestination === "Callia.com Basket"){
        const url = "https://callia.com/?cart_id=" + this.generateRandomString(7);
        const shortURL = this.queryURL;
        this.result = shortURL;
        await this.updateURL(lineItemsRequest, shortURL, url, this.carts);
        console.log(shortURL);
      }
      else{
        const {lineItemsRequest, checkoutPage} = await this.handleCheckout();
          if (checkoutPage) {
            const shortURL = this.queryURL;
            this.result = shortURL ?? checkoutPage;
            await this.updateURL(lineItemsRequest, shortURL, checkoutPage, this.carts);
          }
      }
      this.carts = [];
    },
    async handleCheckout() {
      this.result = "";
      if (this.carts.length === 0) {
        this.displayMessage("warning", `Please add cart`);
        return;
      }
      let products = [];
      for (const cart of this.carts) {
        const param = await this.handleCheckoutParam(cart);
        products = products.concat(param);
      }
      console.log("products:", products);
      const lineItemsRequest = this.generateCheckOutItemList(products);

      const checkoutResponse = await invokeShopifyCheckoutCreate(
        lineItemsRequest,
        "CAD"
      );
      console.log("checkout response:", JSON.stringify(checkoutResponse, null, 2));
      const checkout = checkoutResponse?.data?.checkoutCreate?.checkout;
      let checkoutPage;
      if (checkout) {
        checkoutPage = checkout.webUrl;
        console.log('checkoutPage, "_self"', checkoutPage);
        if (this.carts[0].lang?.toLowerCase() == "fr-ca") {
          checkoutPage += "&locale=fr";
        }
        checkoutPage += "&utm=checkoutauto";
        this.user = this.$store.getters.currentUser;
        if (this.user) {
          const initials = this.user.name.split(" ").map((n, index) => {
            if (index == 0) {
              return n[0];
            } else {
              return n.substring(0, 2);
            }
          }).join("");
          checkoutPage += `&initials=${initials}`;
        }
      }
      return {lineItemsRequest, checkoutPage};
    },
    encodeCarts(obj) {
      const params = new URLSearchParams();

      function addParam(key, value) {
          if (Array.isArray(value)) {
              value.forEach((val, index) => {
                  addParam(`${key}[${index}]`, val);
              });
          } else if (typeof value === 'object' && value !== null) {
              for (const subKey in value) {
                  addParam(`${key}[${subKey}]`, value[subKey]);
              }
          } else {
              params.append(key, value);
          }
      }

      for (const key in obj) {
          addParam(key, obj[key]);
      }

      return params.toString();
    },
    async getShortURL(url) {
      const SHORT_URL_GEN_ENDPOINT = `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/short/url?isCheckout=true`;
      const res = await this.axios.post(SHORT_URL_GEN_ENDPOINT, {url});
      return res.data.shortURL;
    },
    async handleCheckoutParam(cart) {
      const botBuilderId = nanoid();
      const products = [];

      const bouquetObj = cart.bouquetSize;
      const deliveryRateObj = cart.deliveryRate;
      const productBouquet = {
        variantId: bouquetObj.id,
        quantity: 1,
      };
      productBouquet.customAttributes = [
        { key: CustomAttributs.SKU, value: bouquetObj.sku },
        { key: CustomAttributs._COLLECTIONID, value: bouquetObj.value },
        {key: CustomAttributs.DELIVERY_DATE, value: cart.deliveryDate },
        { key: CustomAttributs._BOLDBUILDERID, value: botBuilderId },
        { key: CustomAttributs._VASE, value: cart.vase.value || "" },
        { key: CustomAttributs._ADDONS, value: cart.addonsStr },
        // { key: CustomAttributs._DONATION, value: _donations },
        { key: CustomAttributs.FIRST_NAME, value: cart.firstName },
        { key: CustomAttributs.LAST_NAME, value: cart.lastName },
        { key: CustomAttributs.ADDRESS, value: cart.address },
        { key: CustomAttributs.CITY, value: cart.city },
        { key: CustomAttributs.PROVINCE, value: cart.province || "" },
        { key: CustomAttributs.POSTAL_CODE, value: cart.postalCode },
        { key: CustomAttributs.UNIT_NUMBER, value: cart.apartmentNumber || "" },
        { key: CustomAttributs.ADDRESS_TYPE, value: cart.addressType },
        { key: CustomAttributs.DELIVERY_NOTES, value: cart.deliveryNotes || "" },
        { key: CustomAttributs.BUZZER_CODE, value: cart.buzzerCode || "" },
        { key: CustomAttributs._DELIVERY_CITY, value: cart.city },
        { key: CustomAttributs._DELIVERY_REGION, value: cart.region },
        // { key: CustomAttributs._LATITUDE, value: recipient.latitude || "" },
        // { key: CustomAttributs._LONGITUDE, value: recipient.longitude || "" },
        // { key: CustomAttributs._DATADOG_SESSION_ID, value: this.getatadogSessionID() },
        { key: CustomAttributs.SHIPPING_METHOD, value: cart.deliveryRate.value },
        { key: CustomAttributs._SHIPPING_COST, value: deliveryRateObj.price },
        { key: CustomAttributs._REGION, value: cart.region },
        // TODO ID of shopify product variant. should be come from deliveryWindow
        { key: CustomAttributs._DELIVERY_METHOD, value: deliveryRateObj.id || "" },
        { key: CustomAttributs._CONSENT, value: cart.consent },
        { key: CustomAttributs.RECIPIENT_PHONE_NUMBER, value: cart.phoneNumber },
        { key: CustomAttributs.CONTACT_NAME, value: cart.cName },
        { key: CustomAttributs._REGION_CODE, value: cart.regionCode },
        { key: CustomAttributs._EMAIL_LANGUAGE, value: cart.lang },
        { key: CustomAttributs._TEXTLANGUAGE, value: this.email2TextLangObj[cart.lang] },
        { key: CustomAttributs.CARD_TYPE, value: cart.cardType.value },
        { key: CustomAttributs._OCCASION, value: cart.occasion },
        { key: CustomAttributs._RELATIONSHIP, value: cart.relationship },
        { key: CustomAttributs._UTM, value: "checkoutauto" },

        // { key: CustomAttributs._WEATHER, value: weather.weatherType },
        // { key: CustomAttributs._TEMPERATURE, value: weather.temperature },
        // TODO add analytics
        { key: CustomAttributs._DIAGNOSTICS_SCREEN, value: "" },
        { key: CustomAttributs._DIAGNOSTICS_MOBILE, value: "" },
        { key: CustomAttributs._DIAGNOSTICS_OS, value: "" },
        { key: CustomAttributs._DIAGNOSTICS_BROWSER, value: "" },
        { key: CustomAttributs._DIAGNOSTICS_UA, value: "" },
        { key: CustomAttributs._WEBSITE, value: "" },
        // ------------------
        { key: CustomAttributs._WEBSITE_VERSION, value: "callia 2.0" },
        { key: CustomAttributs.MESSAGE, value: cart.message },
      ];
      products.push(productBouquet);

      const cardObj = cart.cardType;
      const productCard = {
        variantId: cardObj.id,
        quantity: 1,
      };
      productCard.customAttributes = [
        { key: CustomAttributs.SKU, value: cardObj.sku },
        { key: CustomAttributs._BOLDBUILDERID, value: botBuilderId },
      ]
      products.push(productCard);

      const productDeliveryCharge = {
        variantId: deliveryRateObj.id,
        quantity: 1,
      };
      productDeliveryCharge.customAttributes = [
        { key: CustomAttributs.SKU, value: cart.deliveryRate.sku },
        { key: CustomAttributs._BOLDBUILDERID, value: botBuilderId },
        { key: CustomAttributs.DELIVERY_TYPE, value: cart.deliveryRate.value },
      ]
      products.push(productDeliveryCharge);

      if (cart.vase && cart.vase.id) {
        const vaseObj = cart.vase;
        const productVase = {
          variantId: vaseObj.id,
          quantity: 1,
        };
        productVase.customAttributes = [
        { key: CustomAttributs.SKU, value: vaseObj.sku },
          { key: CustomAttributs._BOLDBUILDERID, value: botBuilderId },
        ]
        products.push(productVase);
      }
      if (cart.addons && cart.addons.length > 0) {
        for (const addon of cart.addons) {
          if (addon && addon.value && addon.value.id) {
            const productAddon = {
              variantId: addon.value.id,
              quantity: addon.value.quantity,
            };
            productAddon.customAttributes = [
              { key: CustomAttributs.SKU, value: addon.value.sku },
              { key: CustomAttributs._BOLDBUILDERID, value: botBuilderId },
            ]
            products.push(productAddon);
          }
        }
      }
      return products;
    },
    async saveURL(lineItemsRequest, shortURL, checkoutURL, carts) {
      const user = this.$store.getters.currentUser;
      if (user) {
        const userEmail = user.email;
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/checkout-automation";
        const request = {
          "checkoutURL": checkoutURL,
          "shortURL": shortURL,
          "carts": carts,
          "checkoutInfo": lineItemsRequest,
          "referenceOrder": this.order.orderId,
          "userEmail": userEmail
        };
        await this.axios.post(url, request).then(
          res => {
            console.log("saveURL response:", res.data);
          }
        ).catch(err => {
          console.log("error:", err);
          // this.displayMessage("warning", `saveURL Exception: ${err}`);
        });
      } else {
        this.displayMessage("warning", `Please relogin`);
      }
    },
    async updateURL(lineItemsRequest, shortURL, checkoutURL, carts) {
      const user = this.$store.getters.currentUser;
      if (user) {
        const userEmail = user.email;
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/checkout-automation/" + this.id;
        const request = {
          "checkoutURL": checkoutURL,
          "shortURL": shortURL,
          "carts": carts,
          "checkoutInfo": lineItemsRequest,
          "referenceOrder": this.order.orderId,
          "userEmail": userEmail
        };
        await this.axios.put(url, request).then(
          res => {
            console.log("saveURL response:", res.data);
          }
        ).catch(err => {
          console.log("error:", err);
          // this.displayMessage("warning", `updateURL Exception: ${err}`);
        });
      } else {
        this.displayMessage("warning", `Please relogin`);
      }
    },
    copyURL() {
      if (this.result) {
        navigator.clipboard.writeText(this.result);
      }
    },
    jump() {
      if (this.result) {
        window.open(this.result, "_blank");
      }
    },
    generateCheckOutItemList(lineItems) {
      let payload = "";

      for (const item of lineItems) {
        payload =
          payload +
          ', { variantId: "' +
          item.variantId +
          '", quantity: ' +
          item.quantity;
        if (item.customAttributes.length > 0) {
          let attPayload = "";
          for (const attribute of item.customAttributes) {
            let value = attribute.value;
            if (value) {
              value = value.replace(/"/g, '\\"');
            }
            attPayload =
              attPayload +
              '\t\t, { key : "' +
              attribute.key +
              '" , value : "' +
              value +
              '"}\n';
          }
          payload =
            payload +
            ", \n\t customAttributes:[ " +
            attPayload.substring(3) +
            " ]";
        }
        payload = payload + " } \n";
      }
      if (payload != "") {
        payload = payload.substring(1);
      }
      payload = "lineItems: [" + payload + " ]";
      console.log("payload:\n", payload);
      return payload;
    },

    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
    checkItems() {
      if (!this.form.province) {
        this.displayMessage("warning", `Province can't be empty`);
        return false;
      }
      if (!this.form.city) {
        this.displayMessage("warning", `City can't be empty`);
        return false;
      }
      if (!this.form.deliveryDateNew) {
        this.displayMessage("warning", `Delivery Date can't be empty`);
        return false;
      }
      if (!this.form.postalCode) {
        this.displayMessage("warning", `Postal Code can't be empty`);
        return false;
      }
      if (!this.form.lang) {
        this.displayMessage("warning", `Lang can't be empty`);
        return false;
      }
      if (!this.form.firstName) {
        this.displayMessage("warning", `First Name can't be empty`);
        return false;
      }
      if (!this.form.lastName) {
        this.displayMessage("warning", `Last Name can't be empty`);
        return false;
      }
      if (!this.form.cName) {
        this.displayMessage("warning", `Contact Name can't be empty`);
        return false;
      }
      if (!this.form.phoneNumber) {
        this.displayMessage("warning", `Phone Number can't be empty`);
        return false;
      }
      if (!this.form.address) {
        this.displayMessage("warning", `Address can't be empty`);
        return false;
      }
      if (!this.form.addressType) {
        this.displayMessage("warning", `Address Type can't be empty`);
        return false;
      }
      if (!this.addressTypesOpt.find(item => item.value === this.form.addressType)) {
        this.displayMessage("warning", `Address Type can't be empty`);
        return false;
      }
      if (!this.form.deliveryRate) {
        this.displayMessage("warning", `Delivery Window can't be empty`);
        return false;
      }
      if (!this.form.bouquetName) {
        this.displayMessage("warning", `Bouquet Name can't be empty`);
        return false;
      }
      if (!this.bouquetFlatOpt.find(item => item.value === this.form.bouquetName)) {
        this.displayMessage("warning", `Bouquet Name can't be empty`);
        return false;
      }
      if (!this.form.bouquetSize) {
        this.displayMessage("warning", `Bouquet Size can't be empty`);
        return false;
      }
      if (!this.form.occasion) {
        this.displayMessage("warning", `Occasion can't be empty`);
        return false;
      }
      if (!this.form.relationship) {
        this.displayMessage("warning", `Relationship can't be empty`);
        return false;
      }
      if (!this.form.cardType) {
        this.displayMessage("warning", `Card Type can't be empty`);
        return false;
      }
      if (!this.form.region) {
        this.displayMessage("warning", `Region can't be empty`);
        return false;
      }
      return true;
    },
    generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result;
    }



  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
</style>
