<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="SMS Template" subTopic="Settings"/>
          <CLButton
              format="primary-btn"
              v-on:func="showAddModal"
          >
            <plus-icon
                size="1.5x"
                class="text-white"
            ></plus-icon>
            Add New
          </CLButton>
          <CLButton
              class="ml-1"
              format="info-btn"
              v-on:func="changeSMSTemplateStatus"
          >
            Enable All Selected
          </CLButton>
          <b-button class="ml-1" variant="light" @click="refreshPage">
            <refresh-cw-icon
                size="1.5x"
                class="text-dark"
            ></refresh-cw-icon>
          </b-button>
        </div>
      </div>
      <div class="row mb-0">
        <deliveryNotificationGrid
            :items="rowData"
            :processing="processing"
            :totalRows="rowData.length"
            @showUpdateModal="showUpdateModal"
            @deleteSMSTemplate="deleteSMSTemplate"
            @refreshPage="refreshPage"
            @enableTemplates="enableTemplateUpdate"

        ></deliveryNotificationGrid>
        <addUpdatePopupModal
            @refreshPage="refreshPage"
            :entry="entry"
            ref="addUpdatePopup"
        ></addUpdatePopupModal>
      </div>
    </div>
  </div>
</template>
<script>
import {PlusIcon, RefreshCwIcon} from "vue-feather-icons";
import deliveryNotificationGrid from "@/components/grids/delivery-notification-grid";
import {CLHeader, CLButton} from "callia-ui-box";
import addUpdatePopupModal from "@/components/deliverynotification/add-update-popup";
import axios from "axios";

export default {
  components: {
    addUpdatePopupModal,
    PlusIcon,
    RefreshCwIcon,
    deliveryNotificationGrid,
    CLHeader,
    CLButton,
  },
  data() {
    return {
      allEnableTemplates: [],
      pgCurrentPage: 1,
      pgTotalRows: 100,
      pgPerPage: 20,
      rowData: [],
      processing: false,
      columnDefs: [],
      entry: {templateId: "", templateType: null},
      templateSuccess: true,
      error: {}
    };
  },
  async mounted() {
    this.processing = true;
    await this.getAllTemplate()
    await this.initializeEditableTable()
    this.processing = false
  },
  methods: {
    async enableTemplateUpdate(newValue) {
      this.allEnableTemplates = newValue;
    },
    changePgCurrentPage(currentPage) {
      this.pgCurrentPage = currentPage
    },
    updatePgPerPageChanges(perPage) {
      this.processing = true
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(perPage)
      this.getAllTemplate()
      this.processing = false
    },
    async refreshPage() {
      this.processing = true;
      this.pgCurrentPage = 1
      this.pgPerPage = parseInt(this.pgPerPage)
      await this.getAllTemplate()
      this.processing = false
    },
    async initializeEditableTable() {
      /** Initialize DataTable */
      try {
        const displayActionButton = event => {
          return 'Update'
        }

        this.columnDefs.push(
            {sortable: true, filter: true, field: 'templateType', headerName: 'Template Type', editable: false},
            {sortable: true, filter: true, field: 'template', headerName: 'Template', editable: false},
            {sortable: true, filter: true, field: 'path', headerName: 'Path', editable: false},
            {
              sortable: false,
              filter: false,
              field: 'action',
              headerName: 'Action',
              editable: false,
              type: 'link',
              formatter: displayActionButton,
              className: 'bg-secondary'
            },
        )
      } catch (errorResponse) {
        this.displayMessage("warning", "Error on table data initialization");
      }
    },
    async deleteSMSTemplate(event) {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete this template path: ${event.path}.`, {
          title: 'Delete Template',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await this.axios({
              method: "DELETE",
              url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/template/delete/${event.templateType}/${event.templateId}/all`,
              headers: {
                Authorization: `Bearer ${this.$store.getters.idToken}`
              }
            });
            this.displayMessage("success", response.data['success']);
            await this.getAllTemplate();
          }
        })
      } catch (errorResponse) {
        this.errorDeleteSMSTemplate();
      }
    },
    async changeSMSTemplateStatus() {
      try {
        this.$bvModal.msgBoxConfirm(`Please confirm that you want to enable all selected templates.`, {
          title: 'Enable Templates',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
        }).then(async (status) => {
          if (status) {
            const response = await axios.post(`${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/template/changestatus`, {
              templateInfo: this.allEnableTemplates
            });
            this.displayMessage("success", response.data['success']);
            await this.getAllTemplate();
          }
        })
      } catch (errorResponse) {
        this.errorDeleteSMSTemplate();
      }
    },
    async getAllTemplate() {
      const response = await this.axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/onfleet/delivery/sms/template/all`,
        headers: {
          Authorization: `Bearer ${this.$store.getters.idToken}`
        }
      });
      this.pgTotalRows = parseInt(response.data.length);
      this.$store.commit("setDeliverySMSTemplate", response.data);
      this.rowData = this.$store.getters.deliverySMSTemplate;
    },

    showAddModal() {
      this.entry.templateId = "";
      this.entry.templateType = "ETA";
      this.entry.path = "lang_type/consent/location_type/temp_type/eta_type/day_of_week";
      this.entry.template = "";
      this.entry.templatePurpose = null;
      this.entry.templateLang = null;
      this.entry.templateVersion = null;
      this.entry.optout = "";
      this.$refs.addUpdatePopup.show();
    },
    showUpdateModal(event) {
      this.entry = event;
      this.$refs.addUpdatePopup.show();
    },
    async refreshGrid(message) {
      await this.getAllTemplate();
      this.displayMessage("success", message);
    },
    errorDeleteSMSTemplate() {
      this.displayMessage("warning", "Template process execution failed.");
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    }
  }
}
</script>