<template>
  <div>
    <div>
      <label>Template:</label>
      &nbsp;&nbsp;&nbsp;<b-button href="https://callia.s3.ca-central-1.amazonaws.com/pre-pulls/pre+pulls+template.xlsx">Download</b-button>
    </div>
    <div>
      <label>Pulls prefix:</label>
      <b-input v-model="prefix"></b-input>
    </div>
    <div>
      <div>
        <label>Upload File:</label>
        <b-form-file v-model="file" accept=".xlsx"> </b-form-file>
      </div>
      <br />
      <br />
      <b-row>
        <b-col>
          <b-button @click="handleSubmit()"> Submit </b-button>
        </b-col>
        <b-col>
          <a style="color: green" v-if="show === true && success === true">
            Pulls Succeeded!
          </a>
          <a style="color: red" v-if="show === true && success === false">
            Pulls Failed!
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const prefix = "";
const show = false;
const file = {};
const success = false;
export default {
  name: "bulkPrePulls",
  methods: {
    async handleSubmit() {
      const request = { prefix: this.prefix, xlsxBuffer: null, filename: this.file.name };
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      reader.onload = async function (e) {
        const data = new Uint8Array(reader.result);
        request.xlsxBuffer = data;
        await axios
          .post(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/order-pull/bulk",
            request,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              self.show = true;
              self.success = true;
            } else {
              self.show = true;
              self.success = false;
            }
          });
      };

      await reader.readAsArrayBuffer(this.file);
    },
  },
  data() {
    return {
      prefix: prefix,
      show: show,
      file: file,
      success: success,
    };
  },
};
</script>

