<template>
  <div class="container-fluid p-0 h-100">
    <h3><strong>Latest</strong> OrderPulls</h3>
    <latest-order-pulls-grid></latest-order-pulls-grid>
  </div>
</template>

<script>
import LatestOrderPull from "@/components/grids/latest-order-pulls-grid.vue";
export default {
  components: {
   'latest-order-pulls-grid': LatestOrderPull
  }
};
</script>

<style></style>