var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto mt-5 wrapper"},[_c('div',{staticClass:"card card0 border-0"},[_c('div',{staticClass:"row d-flex"},[_c('div',[_c('div',{staticClass:"card1 pb-5"},[_c('div',{staticClass:"card2 card border-0"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"row px-3 mb-4 mobile-view"},[_vm._m(2),_c('div',{staticClass:"mt-6 mobile-signing"},[_c('label',{staticClass:"font-weight-bold mt-2 mb-2 text-center"},[_vm._v("Already have a callia account?, Login here")]),_c('a',{staticClass:"btn btn-block btn-social btn-google",on:{"click":function($event){return _vm.authenticate()}}},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/images/login/google.svg")}}),_vm._v(" Sign in with Google ")])])])])])])])]),_c('div',{staticClass:"bg-green py-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-6"},[_c('ul',{staticClass:"list-inline"},[_vm._m(4),_c('li',{staticClass:"list-inline-item"},[_c('a',{ref:"#"},[_vm._v("Help Center")])]),_vm._m(5),_vm._m(6)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/login/callia-primary-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-tittle justify-content-center d-flex"},[_c('h4',{staticClass:"pt-1 pr-1"},[_vm._v("Welcome to")]),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("Admin Portal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-content-center"},[_c('img',{staticClass:"image login-icon",attrs:{"src":require("@/assets/images/login/login-page-icon.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6 text-left"},[_c('p',{staticClass:"mb-0"},[_c('a',{attrs:{"href":"#"}},[_c('strong',[_vm._v("Callia Admin")])]),_vm._v(" © ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Support")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Privacy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-inline-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Terms")])])
}]

export { render, staticRenderFns }