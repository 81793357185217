<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Search"
                    @keyup.enter="filterDataTable"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="3" sm="6" class="left pr-0 mr-0">
          <h6 class="pagination-details">
            Show
            <b-form-select
              v-model="perPageCMB"
              :options="perPageCMBOptions"
              class="col-3 ml-1 mr-2"
              size="sm"
            ></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge variant="secondary" class="mr-2"
              >{{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">{{ totalRows }} </b-badge>
          </h6>
        </b-col>
        <b-col lg="4" sm="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="left"
            size="sm"
          >
          </b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-table
                  hover
                  responsive
                  :sticky-header="'58vh'"
                  small
                  id="customerListGrid"
                  class="table-height"
                  :items="rowData"
                  :fields="fields"
                  :filter-included-fields="filterOn"
                  :filter="filter"
                  @filtered="onFiltered"
                  :current-page="currentPage"
                  :per-page="perPage"
          >
            <template #cell(action)="row">
              <edit-icon
                      size="1.8x"
                      style="color: green"
                      class="custom-class"
                      @click="editItem(row.item)"
              ></edit-icon>
              <trash2-icon
                      size="1.8x"
                      style="color: red"
                      class="custom-class"
                      @click="deleteItem(row.item)"
              ></trash2-icon>
              <bell-icon
                  size="1.8x"
                  style="color: #0a9ea5"
                  class="custom-class"
                  @click="editSubscription(row.item)"
              ></bell-icon>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import "vue-editable-grid/dist/VueEditableGrid.css";
import {EditIcon, Trash2Icon,BellIcon} from "vue-feather-icons";

export default {
  components: {
    EditIcon,
    Trash2Icon,
    BellIcon
  },
  props: [
    "processing",
    "rowData",
    "pageCount",
    "pgCurrentPage",
    "pgTotalRows",
    "pgPerPage"
  ],
  name: "customerListGrid",
  data() {
    return {
      fields: [
        {
          sortable: true,
          filter: true,
          key: "email",
          label: "Email/Phone"
        },
        {
          sortable: false,
          filter: false,
          key: "reviewList",
          label: "Review List"
        },
        {
          sortable: false,
          filter: false,
          key: "VIPList",
          label: "VIP List"
        },
        {
          key: "action",
          sortable: false,
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center"
        }
      ],
      filter: null,
      filterOn: [],
      currentPage: this.pgCurrentPage,
      totalRows: this.pgTotalRows,
      perPage: this.pgPerPage,
      numOfPages: 0,
      newRowData: [],
      perPageCMBOptions: [],
      perPageCMB: "20"
    };
  },
  watch: {
    pgTotalRows: function() {
      this.totalRows = this.pgTotalRows;
      this.updateNumberOfPages();
    },
    pgPerPage: function() {
      this.perPage = this.pgPerPage;
      this.updateNumberOfPages();
    },
    currentPage: function() {
      this.$emit("changePaginationPageEvent", this.currentPage);
    },
    pgCurrentPage: function() {
      this.currentPage = this.pgCurrentPage;
    },
    perPageCMB: function(selected) {
      this.$emit("gridPaginationPerPageChange", selected);
    },
    putRequestArray: function(changedArrayValues) {
      if (changedArrayValues.length === 0) {
        this.updateArray = changedArrayValues;
      }
    }
  },
  mounted() {
    this.updateNumberOfPages();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterDataTable(event) {
      let filterOpt = this.filterOn;
      if (this.filterOn.length === 0) {
        filterOpt = ["email", "reviewList", "VIPList"];
      }
      this.$emit("filterDataTableEvent", {
        searchString: event.target.value,
        filterOn: filterOpt
      });
    },
    updateNumberOfPages() {
      this.numOfPages = Math.ceil(this.totalRows / this.perPage);
      this.perPageCMBOptions = [
        "20",
        "50",
        "100",
        "150",
        "200",
        "250",
        { value: this.pgTotalRows, text: "All" }
      ];
    },
    editItem(row) {
      this.$emit("editCustomer", row);
    },
    editSubscription(row) {
      this.$emit("editSubscription", row);
    },
    deleteItem(row) {
      this.$emit("deleteCustomer", row);
    }
  }
};
</script>
<style>
/*vue-editable-table styles*/
.my-grid-class {
  height: 450px;
}

tr.filters-row {
  background-color: #343a40;
}

thead {
  background-color: #343a40;
}

.header-content {
  color: white;
  font-weight: bold;
}

th.filter input[data-v-af99a9fe] {
  background: #0000004d;
  font-size: 0.9rem;
  color: #15cfd8;
}

th[data-v-af99a9fe]:after {
  background-color: white;
}

th.filter input[data-v-af99a9fe] {
  padding-left: 27px;
}

.cell-content > a {
  text-decoration: none;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  color: #fff;
  background-color: #f75050;
}

/*pagination*/
.pagination .spinner-grow {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  background-color: #f8f9fa;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.grid-tools {
  display: none !important;
}

.cell .selected-top,
.selected-right,
.selected-bottom,
.selected-left {
  border-color: #17a2b8 !important;
}

.pagination-sm .page-link {
  padding: 0.25rem 1rem;
}

.primary-btn {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.primary-btn:hover {
  color: white;
  background-color: #18b2cb;
  border-color: #18b2cb;
}

.primary-btn:focus {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(177 179 179 / 23%);
}

.page-item.active .page-link {
  color: #fff;
  background-color: #18a2b8;
  border-color: #18a2b8;
}

.pagination-details {
  font-size: 0.97rem;
}

table.grid-table {
  border-radius: 3px;
}
</style>
