<template>
  <b-modal
      id="modal-update-giftSetsAlert"
      ref="modal"
      :title="heading"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Update GiftSet"
          label-for="GiftSet"
      >
        <b-form-input
            id="id"
            v-model="GiftSetEditModel"
            :clearable="true"
            :state="validateEditGiftSet"

        ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  /** initialize props */
  props: ["updateArray"],
  name: "edit-giftSetsAlert-popup",
  data() {
    return {
      validateEditGiftSet: "",
      GiftSetEditModel: "",
      GiftSetEditOptions: [],
      heading: "",
      selectedGiftSet: "",
      giftSetsAlertId: ""
    };
  },
  watch: {
    /** realtime props update */
    $props: {
      async handler(newVal, oldVal) {
        if('updateArray' in newVal && newVal.updateArray.id) {
          this.id = `Update Gift Sets: ${newVal.updateArray["id"]}`;
          this.giftSetsAlertId = newVal.updateArray["id"];
          this.GiftSetEditModel = newVal.updateArray["id"];
          await this.getGiftSetEditDetails(newVal.updateArray);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async show() {
      this.$refs.modal.show();
    },
    validateEditGiftSetCMB() {
      if (this.GiftSetEditModel !== null) {
        if (this.GiftSetEditModel.value == null) {
          this.validateEditGiftSet = "is-invalid";
        } else {
          this.validateEditGiftSet = "";
        }
      } else {
        this.validateEditGiftSet = "is-invalid";
      }
    },

    async getGiftSetEditDetails(row) {
      this.GiftSetEditOptions = [];
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });

        const giftSetsArray = response.data;
        giftSetsArray.forEach(qData => {
          const GiftSetName = qData.id;
          if (qData.id === row.id) {
            this.GiftSetEditModel = qData.id;
          }

          this.GiftSetEditOptions.push({
            id: this.id,
            label: GiftSetName
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },

    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();
      // this.validateEditGiftSetCMB();
      return fullFormValidity;
    },
    resetModal() {
      this.validateEditGiftSet = "";
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const giftSetsAlertPostArray = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          GiftSet_id: this.GiftSetEditModel.value
        };

        // Borrar el item
        const response = await this.axios({
          method: "DELETE",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: {
            id: this.giftSetsAlertId
          }
        });


        const responseCrear = await this.axios({
          method: "PUT",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: {
            id: this.GiftSetEditModel
          }
        });


        this.$nextTick(() => {
          this.$bvModal.hide("modal-update-giftSetsAlert");
        });
        this.$emit("success", responseCrear.data["message"]);
      } catch (errorResponse) {
        this.$emit("error");
        console.log("Error: ", errorResponse);
      }
    }
  }
};
</script>
