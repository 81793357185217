import { render, staticRenderFns } from "./postalcode-grid.vue?vue&type=template&id=a25c2696"
import script from "./postalcode-grid.vue?vue&type=script&lang=js"
export * from "./postalcode-grid.vue?vue&type=script&lang=js"
import style0 from "./postalcode-grid.vue?vue&type=style&index=0&id=a25c2696&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports