<template>
<div>
  <b-input v-model="failedOrderJSON"></b-input>
  <b-button :disabled="wait === true" @click="submit"> Submit </b-button>
  <p>{{error}}</p>
</div>
</template>

<script>
import axios from "axios";

const failedOrderJSON = "";
const error = "";
const wait = false;
export default {
  name: "b2bfailedordersubmission",
  methods:{
    async submit(){
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      try{
        const x = JSON.parse(self.failedOrderJSON)
        if (!(x && typeof x === "object")) {
          throw `Invalid JSON, <br/> should look like this`
        }

        this.wait = true
        await axios.post(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/orders/requeue", x,
            {
            }).then((response) =>{
          console.log(response)
            if(response.status === 200){
              self.error = "Order Success"
              self.failedOrderJSON = ""
            }
        })

        console.log(x)
        this.wait = false

      } catch (e) {
        console.log(e)
        self.error = e;
        this.wait = false;
      }
    }
  },
  data(){
    return{
      failedOrderJSON: failedOrderJSON,
      error: error,
      wait: wait
    }
  }
}
</script>

<style scoped>

</style>
