<template>
  <b-modal
      id="modal-add-delivery-rate"
      ref="modal"
      title="Add New Delivery Rate"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-variant="primary primary-btn"
      ok-title="Add"
      cancel-title="Close"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Name"
          label-for="name"
          invalid-feedback="Name is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="name"
            v-model="name"
            readonly
            :class="validateName"
            @input="nameValueChange($event)"
            required
        ></b-form-input>
      </b-form-group>

      <!--      <b-form-group-->
      <!--          label="Period"-->
      <!--          label-for="period"-->
      <!--          invalid-feedback="Period is required"-->
      <!--          valid-feedback="Look Good"-->
      <!--      >-->
      <!--        <b-form-input-->
      <!--            id="period"-->
      <!--            v-model="period"-->
      <!--            :class="validatePeriod"-->
      <!--            @input="periodValueChange($event)"-->
      <!--            required-->
      <!--        ></b-form-input>-->
      <!--      </b-form-group>-->

      <b-form-group
          label="Period"
          label-for="periodPrefix"
          invalid-feedback="Choose a Period"
      >
        <v-select
            id="periodPrefix"
            :options="periodOptions"
            placeholder="choose..."
            v-model="periodPrefix"
            :clearable="true"
            :class="validatePeriodPrefix"
            @input="periodPrefixValueChange($event)"
        >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!periodPrefix"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group
          label="Applicable Start Time"
          label-for="applicableStartTime"
          invalid-feedback=""
      >
        <b-form-timepicker
            id="applicableStartTime"
            reset-button
            v-model="applicableStartTime"
            locale="en"
            @input="applicableStartTimeValueChange($event)"
            :class="validateApplicableStartTime"
        ></b-form-timepicker>
        {{ applicableStartTime }}
      </b-form-group>
      <b-form-group
          label="Applicable End Time"
          label-for="applicableEndTime"
          invalid-feedback=""
      >
        <b-form-timepicker
            id="applicableEndTime"
            reset-button
            v-model="applicableEndTime"
            @input="applicableEndTimeValueChange($event)"
            locale="en"
            :class="validateApplicableEndTime">
        </b-form-timepicker>
        {{ applicableEndTime }}
      </b-form-group>

      <b-form-group
          label="Rate ( $ )"
          label-for="rate"
          invalid-feedback="Rate is required"
          valid-feedback="Look Good"
      >
        <b-form-input
            id="rate"
            v-model="rate"
            :class="validateRate"
            @input="rateValueChange($event)"
            type="number"
            min="1"
            required
        ></b-form-input>
      </b-form-group>

    </form>
  </b-modal>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

const IS_INVALID = "is-invalid";
const PERIOD_OPTIONS = [
  'Anytime',
  'Early Morning Rush',
  'Morning Rush',
  'Afternoon',
  'Early Afternoon',
  'Late Afternoon',
]
export default {
  props: ["zoneOptions", "deliveryRateOptions", "provinceOptions", "regionOptions"],
  name: "add-region-popup",
  data() {
    return {
      name: "",

      period: "",
      periodPrefix: "",
      applicableStartTime: null,
      applicableEndTime: null,

      rate: "",

      validateName: "",
      validatePeriod: "",
      validatePeriodPrefix: "",
      validateApplicableStartTime: "",
      validateApplicableEndTime: "",
      validateRate: "",

      periodOptions: PERIOD_OPTIONS.map(p => ({value: p, label: p})),

      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  methods: {
    clearName() {
      this.name = "";
      this.validateName = "";
    },
    clearPeriod() {
      this.period = "";
      this.validatePeriod = "";
    },
    clearPeriodPrefix() {
      this.periodPrefix = "";
      this.validatePeriodPrefix = "";
    },
    clearRate() {
      this.rate = "";
      this.validateRate = "";
    },

    show() {
      this.$refs.modal.show();
    },

    resetModal() {
      this.name = "";
      this.validateName = "";

      this.period = "";
      this.validatePeriod = "";

      this.rate = "";
      this.validateRate = "";
    },

    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },

    nameValueChange(event) {
      if (event !== null) {
        this.validateName = "";
      } else {
        this.validateName = IS_INVALID;
      }
      this.$emit('name', event.value)
    },

    periodValueChange(event) {
      if (event !== null) {
        this.validatePeriod = "";
      } else {
        this.validatePeriod = IS_INVALID;
      }
      this.$emit('period', event.value)
    },

    setName(period, rate) {
      if (period && rate) {
        this.name = `${period} - $${rate}`;
      }
    },

    formatApplicableTime(timeStr) {
      let hours = +timeStr.split(":")[0];
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12
      return `${hours}${ampm}`;
    },

    setPeriod(periodPrefix, applicableStartTime = '', applicableEndTime = '') {
      if (periodPrefix && applicableStartTime && applicableEndTime) {
        console.log(periodPrefix);

        this.period = `${periodPrefix} (${this.formatApplicableTime(applicableStartTime)} - ${this.formatApplicableTime(applicableEndTime)})`;
        this.setName(this.period, this.rate);
      }
    },

    periodPrefixValueChange(event) {
      const {value} = event;
      if (value !== null) {
        this.validatePeriodPrefix = "";
      } else {
        this.validatePeriodPrefix = IS_INVALID;
      }
      this.periodPrefix = value;
      this.setPeriod(value, this.applicableStartTime, this.applicableEndTime);
      this.setName(this.period, this.rate);
    },

    applicableStartTimeValueChange(value) {
      if (value !== null) {
        this.validateApplicableStartTime = "";
      } else {
        this.validateApplicableStartTime = IS_INVALID;
      }
      this.setPeriod(this.periodPrefix, value, this.applicableEndTime);
      this.setName(this.period, this.rate);
    },

    applicableEndTimeValueChange(value) {
      if (value !== null) {
        this.validateApplicableEndTime = "";
      } else {
        this.validateApplicableEndTime = IS_INVALID;
      }
      this.setPeriod(this.periodPrefix, this.applicableStartTime, value);
      this.setName(this.period, this.rate);
    },

    rateValueChange(value) {
      if (value !== null) {
        this.validateRate = "";
      } else {
        this.validateRate = IS_INVALID;
      }
      this.rate = value;
      this.setName(this.period, value);
    },

    checkValidateName() {
      this.validateName = this.name ? "" : IS_INVALID;
    },

    checkValidatePeriod() {
      this.validatePeriod = this.period ? "" : IS_INVALID;
    },

    checkValidatePeriodPrefix() {
      this.validatePeriodPrefix = this.periodPrefix ? "" : IS_INVALID;
    },

    checkValidateApplicableStartTime() {
      this.validateApplicableStartTime = this.applicableStartTime ? "" : IS_INVALID;
    },

    checkValidateApplicableEndTime() {
      this.validateApplicableEndTime = this.applicableEndTime ? "" : IS_INVALID;
    },

    checkValidateRate() {
      this.validateRate = this.rate ? "" : IS_INVALID;
    },

    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      this.checkValidateName();
      this.checkValidatePeriod();
      this.checkValidatePeriodPrefix();
      this.checkValidateApplicableStartTime();
      this.checkValidateApplicableEndTime();
      this.checkValidateRate();

      return fullFormValidity;
    },

    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const deliveryRatePostArray = {
          name: this.name,
          period: this.period,
          rate: `$${this.rate}`
        };

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates`,
          data: deliveryRatePostArray
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-delivery-rate");
        });
        this.$emit("success", response.data['success']);
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>
