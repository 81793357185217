<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
            v-model="filterOn"
            class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="processId">Process ID</b-form-checkbox>
            <b-form-checkbox value="exportedFilesPath">Exported File</b-form-checkbox>
            <b-form-checkbox value="type">Export Type</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light">Leave all unchecked to filter on all data</b-badge>
        </b-col>
      </b-row>
      <b-row class="py-2">
        <b-col offset-lg="10" offset-md="9" md="3" lg="2" sm="12">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="7"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-table
            hover
            responsive
            :sticky-header="'58vh'"
            small
            id="exporter-process-table"
            class="table-height"
            :items="items"
            :fields="fields"
            :filter-included-fields="filterOn"
            :filter="filter"
            @filtered="onFiltered"
            head-variant="dark"
            :current-page="currentPage"
            :per-page="perPage"
          ></b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-lg="9" offset-md="8" offset-sm="4" md="4" lg="3" sm="8" class="text-right">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>

export default {
  data() {
    return {
      processingMessage: "Please wait...",
      fields: [
        {
          key: "processId",
          sortable: true,
          label: "Process ID"
        },
        {
          key: "type",
          sortable: true,
          label: "Export Type",
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: "percentage",
          sortable: true,
          label: "Progress",
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: "exportedFilesPath",
          sortable: false,
          label: "Exported File",
          thClass: 'text-center',
          tdClass: 'text-right',
        }
      ],
      items: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      commentText: [],
      sortDirection: "asc",
      showNewLinkModal: true,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 20, { value: 100, text: "Show a lot" }]
    };
  },
  async created() {
    await this.listAllExportProcesses();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    /** export process table handler */
    async listAllExportProcesses() {
      try {
        this.processing = true;
        await this.axios
          .get("https://excel2pdf.callia.com/labels/list")
          .then(response => {
            response.data.forEach((qData) => {
              let type = "";
              let validatedFiilePath = "";

              switch (qData.type) {
                case "Ivie":
                  type = "Ivie | Excel to PDF";
                  validatedFiilePath = this.getExportedFile("/Ivie/Orders/", qData);
                  break;
                case "Callia":
                  type = "Callia | Excel to PDF";
                  validatedFiilePath = this.getExportedFile("/Orders/", qData);
                  break;
                case "word to pdf":
                  type = "Word to PDF";
                  validatedFiilePath = this.getExportedFile("/Orders/", qData);
                  break;
              }
              this.items.push({
                "processId": qData.processId,
                "type": type,
                "percentage": (parseInt(qData.percentage) == 100) ? "Completed" : qData.percentage + "%",
                "exportedFilesPath": validatedFiilePath
              });
            });
            this.totalRows = this.items.length;
            this.processing = false;
          });
      } catch (errorResponse) {
        this.displayMessage("Export process info does not exits");
      }
    },
    /** exported file path validation */
    getExportedFile(pattern, qData) {
      let exportedFilesPath = "";
      let exportPathStringValidate = "";
      if (qData.excelRemote !== null) {
        exportPathStringValidate = qData.excelRemote.replace(pattern, "");
        exportedFilesPath += "| " + exportPathStringValidate + " \n ";
      }
      if (qData.wordRemote !== null) {
        exportPathStringValidate = qData.wordRemote.replace(pattern, "");
        exportedFilesPath += "| " + exportPathStringValidate + " \n ";
      }
      if (qData.pdfRemote !== null) {
        exportPathStringValidate = qData.pdfRemote.replace(pattern, "");
        exportedFilesPath += "| " + exportPathStringValidate + " \n ";
      }
      return exportedFilesPath.slice(2);
    },

    displayMessage(message, modal) {
      this.notificationMessage = message;
      if (modal === "new") {
        this.newLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      } else {
        this.updateLinkNotification = {
          showErrorCountDown: 5,
          type: "warning"
        };
      }
    }
  }
};
</script>
<style>
.input-group {
  margin-left: 0 !important;
  text-align: center;
}

/*checkbox filter*/
.custom-filter-bg {
  background: radial-gradient(circle, #e9f1f966 44%, rgba(255, 255, 255, 1) 100%);
  border-radius: 20px;
}

/*filter search*/
input#filter-input {
  border-color: #343a405c;
  padding: 10px 20px;
  border-radius: 28px;
}
/*filter icon*/
.icon {
  right: 10px !important;
  top: 6px !important;
  margin-left: -23px;
  margin-top: 7px;
  z-index: 99;
}

/*custom pagination*/
.page-item.disabled .page-link {
  color: #bcc1c6;
  background-color: #222e3d;
  border-color: #ffffff;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #0a9ea5;
  border-color: #0a9ea5;
}

.page-link {
  font-weight: 600;
  color: #bbbfc5;
  background-color: #222e3d;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 0px;
}

.page-link:hover {
  background-color: #edf0f3;
  color: black;
  font-weight: 600;
}
</style>