<template>
  <b-modal
    id="modal-update-region"
    ref="modal"
    :title="heading"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Warehouse"
        label-for="warehouse"
        invalid-feedback="Choose a warehouse"
      >
        <v-select
          id="warehouse"
          :selected="selectedWarehouse"
          :options="warehouseEditOptions"
          placeholder="choose..."
          v-model="warehouseEditModel"
          :clearable="true"
          :class="validateEditWarehouse"
          @input="warehouseSelectedValue($event)"
        >
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!warehouseEditModel"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  /** initialize props */
  props: ["title", "updateArray"],
  name: "edit-region-popup",
  data() {
    return {
      validateEditWarehouse: "",
      warehouseEditModel: "",
      warehouseEditOptions: [],
      heading: "",
      selectedWarehouse: "",
      regionId: ""
    };
  },
  watch: {
    /** realtime props update */
    $props: {
      async handler(newVal, oldVal) {
        this.heading = `Update Region: ${newVal.title}`;
        this.regionId = newVal.updateArray["region_id"];
        await this.getWarehouseEditDetails(newVal.updateArray["warehouse_id"]);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async show() {
      this.$refs.modal.show();
    },
    validateEditWarehouseCMB() {
      if (this.warehouseEditModel !== null) {
        if (this.warehouseEditModel.value == null) {
          this.validateEditWarehouse = "is-invalid";
        } else {
          this.validateEditWarehouse = "";
        }
      } else {
        this.validateEditWarehouse = "is-invalid";
      }
    },
    warehouseSelectedValue(event) {
      if (event !== null) {
        this.validateEditWarehouse = "";
      } else {
        this.validateEditWarehouse = "is-invalid";
      }
    },
    async getWarehouseEditDetails(warehouseID) {
      this.warehouseEditOptions = [];
      try {
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/warehouse`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          }
        });
        const provinceArray = response.data;
        provinceArray.forEach(qData => {
          const warehouseName = `${qData.name} - ${qData.sku}`;
          if (qData.id === warehouseID) {
            this.warehouseEditModel = { value: qData.id, label: warehouseName };
          }

          this.warehouseEditOptions.push({
            value: qData.id,
            label: warehouseName
          });
        });
      } catch (errorResponse) {
        console.log("Error: ", errorResponse);
      }
    },

    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();
      this.validateEditWarehouseCMB();
      return fullFormValidity;
    },
    resetModal() {
      this.validateEditWarehouse = "";
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const shortCode = this.warehouseEditModel.label.split('-')[1].trim().toUpperCase();

        const regionPostArray = {
          "warehouse_id": this.warehouseEditModel.value,
          "short_code": shortCode
        };

        const response = await this.axios({
          method: "PUT",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/places/region/${this.regionId}`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: regionPostArray
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-update-region");
        });
        this.$emit("success", response.data["success"]);

      } catch (errorResponse) {
        this.$emit("error");
        console.log("Error: ", errorResponse);
      }
    }
  }
};
</script>
