import Vue from "vue";
import App from "./App.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import VueAuthenticate from "callia-vue-authenticate";
import "./registerServiceWorker";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import routes from "./routes";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { store } from "./store";
import VueEditableGrid from "vue-editable-grid";
import "vue-editable-grid/dist/VueEditableGrid.css";
import OktaAuthenticate from "./callia-okta-authenticate";
import CalliaAxio from "./callia-axios";
import TooltipModal from './components/tooltip-modal.vue'

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                default:
                    console.log(`connection error ${error.response.status}`);
            }
        } else {
            console.log('error: ', error);
        }
        return Promise.resolve(error.response);
    }
);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueEditableGrid);
Vue.use(VueAxios, axios);
Vue.use(OktaAuthenticate, {
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    issuer: process.env.VUE_APP_AUTH_ISSUER,
    redirectUri: `${window.location.origin}`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    tokenManager: {
        storage: 'sessionStorage'
    },
    originalUri: '/',
    idp: process.env.VUE_APP_AUTH_GOOGLE_IDP,
    logoutRedirectUri: `${window.location.origin}`
});
Vue.component("tooltip-modal", TooltipModal)
Vue.use(CalliaAxio, axios, true);
Vue.config.productionTip = false;
// library.add(faUserSecret);
const router = routes;
export const eventBus = new Vue({
    methods: {
        hideLeftMenu(isHide) {
            this.$emit("hideLeftMenuEvent", isHide);
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
