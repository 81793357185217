<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto">
          <cl-header mainTopic="Giftset Alert" subTopic="" />
          <CLButton
              format="primary-btn"
              v-on:func="showAddModal"
          >
            <plus-icon
                size="1.5x"
                class="text-white"
            ></plus-icon>
            Add New
          </CLButton>
          <!--          <b-button variant="dark" @click="showAddModal">-->
          <!--            <plus-icon size="1.5x" class="text-success"></plus-icon>-->
          <!--            Add New-->
          <!--          </b-button>-->
          <add-giftsetsalert-popup
              ref="AddGiftSetsAlertModal"
              @success="refreshGrid($event)"
              @error="errorCreateGiftSetsAlert"
          />
          <edit-giftsetsalert-popup
              ref="EditGiftSetsAlertModal"
              :update-array="updateArray"
              @success="refreshGrid($event)"
              @error="errorUpdateGiftSetsAlert"
          />
        </div>
      </div>
      <div class="row mt-3 mb-0">
        <giftsetsalert-grid
            :items="gridItems"
            :processing="processing"
            :totalRows="totalRows"
            @showEditModal="showEditGiftSetsAlertModal($event)"
            @deleteSuccess="refreshGrid($event)"
        ></giftsetsalert-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import GiftSetsAlertGrid from "@/components/grids/giftsetsalert-grid";
import {CLButton, CLHeader} from "callia-ui-box";
import AddGiftSetsAlertModal from "@/components/giftsetsalert/add-giftsetsalert-popup";
import EditGiftSetsAlertModal from "@/components/giftsetsalert/edit-giftsetsalert-popup";

export default {
  components: {
    PlusIcon,
    "giftsetsalert-grid": GiftSetsAlertGrid,
    "add-giftsetsalert-popup": AddGiftSetsAlertModal,
    "edit-giftsetsalert-popup": EditGiftSetsAlertModal,
      "cl-header": CLHeader,
    CLButton
  },
  data() {
    return {
      id: this.id,
      updateArray: [],
      gridItems: this.$store.getters.giftsetsalert,
      processing: false,
      totalRows: 0
    };
  },
  async mounted() {
    await this.getAllGiftSetsAlert();
  },
  methods: {
    showAddModal() {
      this.$refs.AddGiftSetsAlertModal.show();
    },
    showEditGiftSetsAlertModal(editInfo) {
      this.id = editInfo["id"];
      this.updateArray = editInfo;
      this.$refs.EditGiftSetsAlertModal.show();
    },
    async refreshGrid(message) {
      await this.getAllGiftSetsAlert();
      this.displayMessage("success", message);
    },
    errorCreateGiftSetsAlert() {
      this.displayMessage("warning", "GiftSetsAlert saving process failed.");
    },
    errorUpdateGiftSetsAlert() {
      this.displayMessage("warning", "GiftSetsAlert update process failed.");
    },
    displayMessage(messageType, messageBody) {

      this.$bvToast.toast(messageBody, {
        id: messageBody,
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true
      });
    },
    async getAllGiftSetsAlert() {
      try {
        this.items = [];
        const response = await this.axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/ops/giftset-alert/`
        });
        this.processing = true;
        this.$store.commit("setGiftSetsAlert", response.data);
        this.gridItems = this.$store.getters.giftsetsalert;
        this.totalRows = this.gridItems.length;
        this.processing = false;
      } catch (errorResponse) {
        this.displayMessage("warning", "Error fetching GiftSetsAlert.");
        console.log("Error: ", errorResponse);
      }
    }
  },

};
</script>
